import React from 'react';

const NotFound = () => {
  return (
    <div className="contentview-wrapper container-md">
        <article className="content-sec detail-txt" id="error-page">
            <h1>Hups - 404</h1>
            <h2 id="404-heading">{"Die gewünschte Seite wurde nicht gefunden."}</h2>
            <section id="404-txt">
                <p>Der Link auf diese Seite war offenbar ein Reinfall ... </p>
                <p>Hier geht's <a href="/">zurück zur Übersicht</a></p>
            </section>
        </article>
    </div>
  )
}

export default NotFound