import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { ReactComponent as WimmelbildSvg } from '../../../images/wimmelbild/wimmelbild.svg'
import styles from './WimmelbildImage.module.scss'
import { BsZoomOut, BsZoomIn } from 'react-icons/bs';
import NextButton from '../../../components/NextButton';

const Wimmelbild = (props) => {
    const [sizeX, setSizeX] = useState(1214);
    const [sizeY, setSizeY] = useState(866);
    const sizeXMin = window.innerWidth;
    const sizeYMin = window.innerHeight;
    const sizeXMax = 1920;
    const sizeYMax = 1370;
    // const sizeYMax = 1357;
    const zoomStepX = 60;
    const zoomStepY = zoomStepX * 0.714;

    const [scrollX, setScrollX] = useState(sizeX/2)
    const [scrollXMax, setScrollXMax] = useState(0)
    const [scrollY, setScrollY] = useState(sizeY/2)
    const [scrollYMax, setScrollYMax] = useState(0)
    const scrollStep = 100;

    const [bottomBarHeight, setBottomBarHeight] = useState(0);

    const sliderRef = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    useEffect(() => {
        handleResize()
    }, [props.gameEnded])

    const handleResize = () => {
        setScrollXMax(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)
        setScrollYMax(sliderRef.current.scrollHeight - sliderRef.current.offsetHeight)

        let bottomHeight = document.getElementById("searchedElementRow")?.offsetHeight
        if(bottomHeight !== undefined) setBottomBarHeight(bottomHeight)
    }

    useEffect(() => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            let maxWidth = container.scrollWidth - container.offsetWidth
            let maxHeight = container.scrollHeight - container.offsetHeight
            if (sliderRef.current) {
                let container = sliderRef.current;
                container.scrollTo(maxWidth/2, maxHeight/2, "smooth")
            
            }
            setScrollXMax(maxWidth)
            setScrollYMax(maxHeight)

            setTimeout(() => {
                let bottomHeight = document.getElementById("searchedElementRow")?.offsetHeight
                if(bottomHeight !== undefined) setBottomBarHeight(bottomHeight)
            }, 100);
        }
    }, [sliderRef])

    const scrollTop = () => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            if((container.scrollTop-2*scrollStep) >= 0){
                // setScrollY(container.scrollTop-scrollStep)
                container.scrollTo(container.scrollLeft, container.scrollTop-scrollStep, "smooth")
            }
            else{
                // setScrollY(0)
                container.scrollTo(container.scrollLeft, 0, "smooth")
            }
        }
    }

    const scrollLeft = () => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            if((container.scrollLeft-2*scrollStep) >= 0){
                // setScrollX(container.scrollLeft-scrollStep)
                container.scrollTo(container.scrollLeft-scrollStep, container.scrollTop, "smooth")
            }
            else{
                // setScrollX(0)
                container.scrollTo(0, container.scrollTop, "smooth")
            }
        }
    }

    const scrollBottom = () => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            let maxScroll = sliderRef.current.scrollHeight - sliderRef.current.offsetHeight;
            if((container.scrollTop+(2*scrollStep)) <= maxScroll){
                // setScrollY(container.scrollTop+scrollStep)
                container.scrollTo(container.scrollLeft, container.scrollTop+scrollStep, "smooth")
            }
            else{
                // setScrollY(maxScroll)
                container.scrollTo(container.scrollLeft, maxScroll, "smooth")
            }
        }
    }

    const scrollRight = () => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            let maxScroll = sliderRef.current.scrollWidth - sliderRef.current.offsetWidth;
            if((container.scrollLeft+(2*scrollStep)) <= maxScroll){
                // setScrollX(container.scrollLeft+scrollStep)
                container.scrollTo(container.scrollLeft+scrollStep, container.scrollTop, "smooth")
            }
            else{
                // setScrollX(maxScroll)
                container.scrollTo(maxScroll, container.scrollTop, "smooth")
            }
        }
    }

    const setScroll = (e) => {    
        setScrollX(e.target.scrollLeft)
        setScrollY(e.target.scrollTop)
    }

    const zoomOut = () => {
        setSizeX(sx => (sx-zoomStepX))
        setSizeY(sy => (sy-zoomStepY))
        handleResize()
    }

    const zoomIn = () => {
        if(sizeX+zoomStepX >= sizeXMax || sizeY+zoomStepY >= sizeYMax){
            setSizeX(sizeXMax)
            setSizeY(sizeYMax) 
        }
        else{
            setSizeX(sx => (sx+zoomStepX))
            setSizeY(sy => (sy+zoomStepY))
        }
        handleResize()
    }

    useEffect(()=> {
        setScrollXMax(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)
        setScrollYMax(sliderRef.current.scrollHeight - sliderRef.current.offsetHeight)
    }, [sizeX, sizeY])

    const isZoomOutMax = ((sizeX <= sizeXMin) || (sizeY <= sizeYMin))
    const isZoomInMax = ((sizeX === sizeXMax) || (sizeY === sizeYMax))

    const showBtnTop = (scrollY > 0);
    const showBtnLeft = (scrollX > 0);
    const showBtnBottom = (scrollY < scrollYMax-1);
    const showBtnRight = (scrollX < scrollXMax-1);

    let scrollAreaClasses = styles.scrollArea
    if(!showBtnTop) scrollAreaClasses+=" "+styles.borderTop
    if(!showBtnLeft) scrollAreaClasses+=" "+styles.borderLeft
    if(!showBtnRight) scrollAreaClasses+=" "+styles.borderRight
    if(!showBtnBottom) scrollAreaClasses+=" "+styles.borderBottom

    const bottomPos = {
        "--bottomPos": bottomBarHeight+"px"
    }

    return (
        <Row className={styles.wimmelbild}>
            <Col className={styles.wimmelCol} id="wimmelRow">
                <div className={scrollAreaClasses} onScroll={setScroll} ref={sliderRef} style={bottomPos}>
                    <WimmelbildSvg style={{width: sizeX+"px", height: sizeY+"px"}} onClick={props.gameHandler}/>
                    <NextButton direction='up' className={(showBtnTop) ? styles.slideBtn+" "+styles.top+" "+styles.active : styles.slideBtn+" "+styles.top} onClick={scrollTop} />
                    <NextButton direction='left' className={(showBtnLeft) ? styles.slideBtn+" "+styles.left+" "+styles.active : styles.slideBtn+" "+styles.left} style={(bottomBarHeight > 0) ? {top: "calc(50% - ("+bottomBarHeight+"px / 2) - 44px)"} : {}} onClick={scrollLeft} />
                    <NextButton direction='down' className={(showBtnBottom) ? styles.slideBtn+" "+styles.bottom+" "+styles.active : styles.slideBtn+" "+styles.bottom} style={(bottomBarHeight > 0) ? {bottom: "calc("+bottomBarHeight+"px + 1rem)"} : {}} onClick={scrollBottom} />
                    <NextButton direction='right' className={(showBtnRight) ? styles.slideBtn+" "+styles.right+" "+styles.active : styles.slideBtn+" "+styles.right} style={(bottomBarHeight > 0) ? {top: "calc(50% - ("+bottomBarHeight+"px / 2) - 44px)"} : {}} onClick={scrollRight} />
                    <div className={styles.zoomBtn}>
                        <Button className={(isZoomInMax) ? styles.zoomIn+" "+styles.muted : styles.zoomIn} onClick={(isZoomInMax) ? null:  zoomIn}><BsZoomIn/></Button>
                        <Button className={(isZoomOutMax) ? styles.zoomOut+" "+styles.muted : styles.zoomOut} onClick={(isZoomOutMax) ? null : zoomOut}><BsZoomOut/></Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Wimmelbild