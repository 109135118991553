import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import SubNaviParents from '../Parents/SubNaviParents';
import SubNaviFooter from './SubNaviFooter';
import BurgerButton from './BurgerButton';
import styles from './Burgermenu.module.scss';

const Burgermenu = (props) => {
    const burgermenu = true;
    const [status, setStatus] = useState(false);

    // const toggleBurger = event => {
    //     console.log("Toggle");
    //     var parent = el.closest("li");
    //     parent.classList.toggle("offen");
    // }


  return (
    <div className={styles.abstand}>
       <BurgerButton onClick={() => setStatus(!status)} klasse={(status) ? "open" : "closed"} section={props.section} farbe="weiss" />
        <div id={styles.burgermenu} className={(status) ? styles.show : "" }>
            
            <ul className={styles.aussen}>
                <li className={styles.start} >
                    <Link className='nav-link' to="/home">Startseite</Link>
                    <BurgerButton onClick={() => setStatus(!status)} status={status} klasse={(status) ? "open" : "closed"} section={props.section} farbe="blau" />
                </li>
                <li>
                    <div><Link className={(props.section.includes("/eltern")) ? 'aktiv nav-link '+ styles.blue : 'nav-link '+ styles.blue} to="/eltern" onClick={() => setStatus(!status)}>Für Eltern</Link></div>
                    <SubNaviParents burgermenu={burgermenu} onClick={() => setStatus(!status)} section={props.section} />            
                </li>
                <li className={styles.ohneborder}>
                    <div><Link className={(props.section.includes("/spiele")) ? 'aktiv nav-link '+ styles.magenta : 'nav-link '+ styles.magenta} to="/spiele" onClick={() => setStatus(!status)}>Für Kinder</Link></div>
                    <Navbar className="nopadding" aria-label="Unternavigation Bereich Kinder">
                        <div className="w-100">
                            <Nav className={styles.ganze_breite} >                    
                                <ul>
                                    <li>
                                        <Link className={(props.section.includes("/spiele/richtig-falsch")) ? 'aktiv nav-link' : 'nav-link'}  to="/spiele/richtig-falsch" onClick={() => setStatus(!status)}>Was ist richtig?</Link>
                                    </li>
                                    <li>
                                        <Link className={(props.section.includes("/spiele/wimmelbild")) ? 'aktiv nav-link' : 'nav-link'} to="/spiele/wimmelbild" onClick={() => setStatus(!status)}>Wimmelbild</Link>
                                    </li>
                                    <li>
                                        <Link className={(props.section.includes("/spiele/avatar")) ? 'aktiv nav-link' : 'nav-link'} to="spiele/avatar" onClick={() => setStatus(!status)}>Wie ziehe ich mich an?</Link>
                                    </li>
                                </ul>
                            </Nav>
                        </div>
                    </Navbar>
                </li>   
                <li id={styles.footernav} className={styles.offen}>
                    <SubNaviFooter burgermenu={burgermenu} onClick={() => setStatus(!status)} section={props.section} />
                </li>         
            </ul>
        </div>
    </div>

  );
}

export default Burgermenu;