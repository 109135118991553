import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import PageHeader from './PageHeader';

const Versicherungsschutz = (props) => {
  useEffect(() => {
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();
    }, 100);
  }, []);

  return (
    <div>
      <PageHeader path={props.path} />
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>Versicherungsschutz</h1>
            <h2>Eine Versicherung für alle Schulwege</h2>
            <p className="intro">
              Alle Schülerinnen und Schüler von der Einschulung bis zum Schulabschluss sind gesetzlich unfallversichert. Der umfassende Versicherungsschutz besteht automatisch und kostenfrei in der Schule, bei allen schulischen Veranstaltungen und auf dem Schulweg. Sie müssen dafür keine besondere Versicherung abschließen.
            </p>
            <p>
              Die Aufwendungen werden von den Kommunen und dem Land getragen.
            </p>
            <p className="vorliste">
              <strong>
                Umfassender Unfallversicherungsschutz bei der UKBW besteht bei allen Tätigkeiten, die mit dem Schulbesuch in Zusammenhang stehen, insbesondere
              </strong>
            </p>
            <ul>
              <li>
                während des Unterrichts und in den Pausen
              </li>
              <li>
                bei sonstigen offiziellen Schulveranstaltungen außerhalb der Schule, wie zum Beispiel Ausflügen, Klassenfahrten, Schullandheim, Radfahrausbildung
              </li>
              <li>
                bei der Teilnahme an unmittelbar vor oder nach dem Unterricht von der Schule oder im Zusammenwirken mit ihr durchgeführten Betreuungsmaßnahmen
              </li>
              <li>
                bei schulisch organisierten Praktika
              </li>
              <li>
                auf den Wegen zur und von der Schule unabhängig davon, wie diese zurückgelegt werden (zu Fuß, mit dem Fahrrad, Auto oder öffentlichen Verkehrsmitteln)
              </li>

            </ul>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <p className="vorliste">
              <strong>
                Die Leistungen der Unfallkasse für den Fall der Fälle
              </strong>
            </p>
            <ul>
              <li>
                Erstversorgung im Rahmen der Ersten Hilfe
              </li>
              <li>
                ärztliche und zahnärztliche Behandlung
              </li>
              <li>
                Versorgung mit Medikamenten, Hilfs- und Heilmitteln
              </li>
              <li>
                Krankengymnastik und andere ärztlich verordnete Therapieformen
              </li>
              <li>
                ambulante und stationäre Pflege
              </li>
              <li>
                Verletztenrente bei bleibenden Unfallschäden
              </li>

            </ul>
            <p>
              Diese Aufzählung ist nur beispielhaft. Ihr Kind ist nach einem Unfall optimal bei uns versorgt.
            </p>
          </Col>
        </Row>

      </Container>


    </div>

  );
}

export default Versicherungsschutz;