import React, { useState } from 'react'
import { AiOutlineLoading } from 'react-icons/ai';

const ImageToLoad = (props) => {
    
    const [loaded, setLoaded] = useState(false);
    const alt = (props.alt !== undefined) ? props.alt : "";
    const width = (props.width !== undefined) ? props.width : "100%";
    const height = (props.height !== undefined) ? props.height : "auto";
    const style = (props.style !== undefined) ? props.style : null;
    const onClick = (props.onClick !== undefined) ? props.onClick : null
    const className = (props.className !== undefined) ? props.className : ""
    const classNameWrapper = (props.classNameWrapper !== undefined) ? props.classNameWrapper : ""

    return (
        <div className={classNameWrapper} onClick={onClick}>
            {(!loaded) && 
            <div className='loading-animation'>
                <AiOutlineLoading className='spinner-icon'/>
                <span className='sr-only'>Bild lädt</span>
            </div>}
            <img className={className} src={(props.src)} alt={alt} onLoad={()=>{setLoaded(true)}} style={style} width={width} height={height} />
        </div>
    )
}

export default ImageToLoad