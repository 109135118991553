import React from 'react';
import NavbarMain from './NavbarMain';
import SubNaviParents from '../Parents/SubNaviParents';

const Header = (props) => {
  // console.log("Neu: "+props.section);
  return (
    <header>
      <NavbarMain games={props.games} section={props.section} />
      { (props.section.includes('/eltern')) && 
      <SubNaviParents burgermenu={false} section={props.section}/> 
      }
    </header>
    
  );
}

export default Header;