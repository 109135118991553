import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const Verhalten = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Kinder haben einen großen Bewegungsdrang oder lassen sich schnell und gern ablenken – im Verkehr kann das zum Risiko werden. Wer sich aufmerksam durch den Straßenverkehr bewegt, kann viele Gefahren vermeiden.
            </p>

          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Eltern sind für Kinder dabei die wichtigsten Vorbilder bei jeder Autofahrt, auf dem Fußweg zum Spielplatz oder als Fahrradfahrende. Sie können viele Wege nutzen, um Kinder auf ihren späteren Schulweg vorzubereiten. Entscheidend ist, dass Erwachsene Kindern durch das eigene Verhalten deutlich machen, wie wichtig Aufmerksamkeit im Straßenverkehr ist.
            </p>

          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt=""/>

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                Diese Tipps brauchen Eltern und Kinder:
              </h2>
              <ul className='green_circle'>
                <li>Seien Sie Vorbild: Lassen Sie sich im Straßenverkehr nicht von anderen Tätigkeiten ablenken.</li>
                <li>Sprechen Sie mit Ihrem Kind über gefährliches Verhalten auf dem Schulweg.</li>
                <li>Zeigen Sie Ihrem Kind sicheres Verhalten im Straßenverkehr: Aufmerksam bleiben, nicht rennen und auf andere Rücksicht nehmen.</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>

  )
}

export default Verhalten