import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'

const Gehweginnenseite = (props) => {
  const bild = require('../../../images/' + props.foto);
  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Die meisten Kinder lernen, dass der Gehweg eine sichere Zone im Straßenverkehr ist. Dies trifft nur teilweise zu: Rennen, Springen und Spielen können neben anderen Gefahren auch ein Unfallrisiko darstellen.
            </p>

          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Wenn Kinder später allein unterwegs sein sollen, müssen sie wissen, wie sie sich auch auf dem Gehweg vor Gefahren schützen können.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                So verhalten sich Kinder sicher auf dem Gehweg:
              </h2>
              <ul className='green_circle'>
                <li>
                  Achte auf den Verkehr.
                </li>
                <li>
                  Halte Abstand vom Bordstein.
                </li>
                <li>
                  Gehe immer auf der Kinderseite.
                </li>
                <li>
                  Achte auf dem Gehweg auch auf andere.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>



  )
}

export default Gehweginnenseite