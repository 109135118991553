import React, { useEffect, useRef, useState } from 'react'
import NextButton from '../../../components/NextButton';
import styles from './SlideAreaY.module.scss'

const SlideAreaY = (props) => {
    const className = (props.className !== undefined) ? props.className : "";

    const [scrollY, setScrollY] = useState(0);
    const [scrollYMax, setScrollYMax] = useState(0);
    const containerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleResize = () => {
        if(containerRef.current){
            let container = containerRef.current;
            setScrollYMax(container.scrollHeight - container.offsetHeight)
        }
    }

    useEffect(() => {
        if(containerRef.current){
            let container = containerRef.current;
            setScrollYMax(container.scrollHeight - container.offsetHeight)
        }
    }, [containerRef])

    useEffect(() => {
        if(props.reset){
            if(containerRef.current){
                let container = containerRef.current;
                container.scrollTo(0, 0, "smooth")
                props.setReset(false)
            }
        }
    }, [props, props.reset])

    const scrollTop = () => {
        if(containerRef.current){
            let container = containerRef.current;
            let scrollStep = container.children[0].offsetHeight;
            if(scrollY-scrollStep <= 0){
                container.scrollTo(0, 0, "smooth")
            }
            else{
                container.scrollTo(0, scrollY-scrollStep, "smooth")
            }
        }
    }

    const scrollBottom = () => {       
        if(containerRef.current){
            let container = containerRef.current;
            let scrollStep = container.children[0].offsetHeight;
            if(scrollY+scrollStep >= container.scrollHeight-container.offsetHeight-1){
                container.scrollTo(0, container.scrollHeight-container.offsetHeight, "smooth")
            }
            else{
                container.scrollTo(0, scrollY+scrollStep, "smooth")
            }
        }
    }
    
    const handleScroll = (e) => {

        // let container = containerRef.current;
        // let middle = container.clientHeight / 2 * 1.2;
        // let element = Array.from(container.children).find(el => {
        //     let rect = el.getBoundingClientRect();
        //     return rect.top < middle && rect.bottom > middle;
        // });

        // if(container.scrollTop <= 0){
        //     setActiveElement(0)
        // }
        // else if(container.scrollTop >= container.scrollHeight-container.offsetHeight-1){
        //     setActiveElement(elementNumber); 
        // }
        // else{
        //     setActiveElement(Array.from(container.children).indexOf(element)); 
        // }
        setScrollY(e.target.scrollTop)
        if(containerRef.current){
            let container = containerRef.current;
            setScrollYMax(container.scrollHeight - container.offsetHeight)
        }
        // console.log(elementNumber)
        // console.log(Array.from(container.children).indexOf(element))
    };

    return (
        // <div
        //     className={
        //         ((activeElement > 0) && (activeElement < elementNumber)) ? styles.slideArea+" "+styles.scrollBottom+" "+styles.scrollTop : 
        //         (activeElement > 0) ? styles.slideArea+" "+styles.scrollTop :
        //         (activeElement < elementNumber) ? styles.slideArea+" "+styles.scrollBottom : styles.slideArea
        //     }>
            <div
                className={
                    (scrollYMax === 0) ? styles.slideArea :
                    (scrollY <= 20 && scrollYMax !== 0) ? styles.slideArea+" "+styles.scrollBottom : 
                    (scrollY >= scrollYMax-20) ? styles.slideArea+" "+styles.scrollTop : styles.slideArea+" "+styles.scrollBottom+" "+styles.scrollTop
                }
            >
            <NextButton direction='up' className={styles.slideBtn+" "+styles.top} onClick={scrollTop} />
                <div 
                    id="slidearea-y" 
                    className={(scrollY > 0) ? styles.slideAreaRow+" "+className+" "+styles.top : (scrollY < 500) ? styles.slideAreaRow+" "+className+" "+styles.bottom : styles.slideAreaRow+" "+className} 
                    ref={containerRef} 
                    onScroll={handleScroll}
                >
                    {props.children}
                </div>
            <NextButton direction='down' className={styles.slideBtn+" "+styles.bottom} onClick={scrollBottom} />
        </div>
    )
}

export default SlideAreaY