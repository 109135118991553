import React from 'react';
import {Image} from 'react-bootstrap';
import styles from './Daumenhoch.module.scss';
import icon from '../images/icon_thumb_up.svg';

const Daumenhoch = () => {
  return (
    <div className={styles.icondaumen}>
        <Image src={icon} alt=""/>
    </div>

  );
}


export default Daumenhoch;