import React, { useEffect, useState } from 'react';
import styles from './VideoKarussell.module.scss';
import { VideoData } from '../../data/data.js';
import { Link } from 'react-router-dom';
import {useSwipeable} from "react-swipeable";


const VideoKarussell = (props) => { 

    const SortOrder = VideoData.sort((a,b) => (a.sort > b.sort) ? 1 : -1);
    const currentSort = SortOrder.filter(i => i.id === props.videoid);
    const sortId = currentSort[0].sort;
    const [nextVid, setNextVid] = useState();
    const [prevVid, setPrevVid] = useState();

    const handlers = useSwipeable({
        onSwiped: (eventData) => console.info("User Swiped!", eventData),
    });    

    useEffect(()=> {

        if (sortId >= 1 && sortId < SortOrder.length-1) {
            setNextVid(SortOrder[sortId+1].uri);
            setPrevVid(SortOrder[sortId-1].uri);            

        } else if (sortId < 1) {
            setNextVid(SortOrder[sortId+1].uri);
            setPrevVid(SortOrder[SortOrder.length-1].uri);  
        } else {
            setNextVid(SortOrder[0].uri);
            setPrevVid(SortOrder[sortId-1].uri);                
        }

    }, [props.videoid] );

    return (
        <div className={styles.karussell} {...handlers}>
            <Link to={"../"+prevVid} id="nachlinks"><div className={styles.links} title="Zum vorherigen Video"></div></Link>
            <div className={styles.punktleiste}>
                { SortOrder.map((video, index) => (
                    <Link to={"../"+video.uri} key={index} title={"Zum Video: "+video.title}><div key={index} className={(props.videoid === video.id) ? styles.punktaktiv : styles.punkt} id={'vid'+video.sort}></div></Link>           
                
                  ))
                
                }

            </div>
            <Link to={"../"+nextVid} id="nachrechts" title="Zum nächsten Video"><div className={styles.rechts}></div></Link>

        </div>

    )

}

export default VideoKarussell