import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'



const Radfahrer = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Es gibt auch Gehwege, die nicht ausschließlich nur für Fußgängerinnen und Fußgänger gedacht sind. Auf diesen geteilten Wegen können auch Fahrradfahrende unterwegs sein.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Wenn Erwachsene mit Kindern den Schulweg planen, sollten sie darauf achten, ob ein Fußweg auch für andere freigegeben ist.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                Sicheres Verhalten auf geteilten Gehwegen:
              </h2>
              <ul className='green_circle'>
                <li>
                  Auf dem Gehweg auch auf andere achten, z. B. auf Fahrradfahrende.
                </li>
                <li>
                  Gehe nur dort, wo Fußgängerinnen und Fußgänger gehen dürfen.
                </li>
                <li>
                  Wenn du unsicher bist, bleibe stehen und lass die fahrenden Personen vorbei.
                </li>
              </ul>

            </Col>
          </Row>
        </Container>
      </Container>
    </div>


  )
}

export default Radfahrer