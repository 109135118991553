import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const Kleidung = (props) => {
  const bild = require('../../../images/' + props.foto);
  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Vor allem in Herbst und Winter ist der morgendliche Schulweg in der Dämmerung ein Unfallrisiko für Schülerinnen und Schüler. Eltern sollten daher besonders darauf achten, dass ihre Kinder auf der Straße sichtbar bleiben.
            </p>


          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Laut Statistischem Bundesamt verunglücken von November bis Januar ein Drittel mehr Fußgängerinnen und Fußgänger als im Jahresdurchschnitt. Autofahrende können aufgrund der schlechteren Sichtverhältnisse erst aus kurzer Entfernung Fußgänger oder Radfahrende erkennen. Dabei verbessert helle Kleidung die Sichtbarkeit einer Person im Straßenverkehr enorm.
            </p>
            <p>
              Noch besser schneiden Kleidungsstücke aus reflektierendem Material ab. Diese sind auf eine Entfernung von bis zu 140 Meter sichtbar, wenn sie den Standard nach DIN EN ISO 20471:2017 erfüllen. Eltern und Kinder können aus einer Vielzahl an Schulranzen und Jacken mit teils reflektierendem Material wählen. Zusätzliche reflektierende Bänder oder Anhänger, die beispielsweise an der Schultasche angebracht werden, sorgen ebenfalls für mehr Sichtbarkeit auf dem Schulweg.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                So bleiben Kinder sichtbar auf dem Schulweg:
              </h2>
              <ul className='green_circle'>
                <li>
                  Lassen Sie Ihre Kinder möglichst helle Kleidung oder auffällige Farben tragen! Auch eine helle Mütze oder helle Schuhsohlen erhöhen die Sichtbarkeit.
                </li>
                <li>
                  Geben Sie Ihren Kindern Reflektoren an die Hand. Den größten Effekt haben diese an Oberarmen, Händen, der Taille und den Knöcheln. Denn an bewegten Körperteilen werden die Reflektoren besonders gut wahrgenommen.
                </li>
                <li>
                  Bringen Sie Reflektoren auch am Schulranzen an. Oder statten Sie Ihr Kind mit einem Model nach DIN 58124 aus. Dieses besteht aus 10 Prozent reflektierendem Material.
                </li>
                <li>
                  Lassen Sie Ihr Kind rechtzeitig losgehen. Vor allem in der Dämmerung kann Hektik ein zusätzliches Unfallrisiko sein.
                </li>
                <li>
                  Zeigen Sie Ihren Kindern gut beleuchtete und somit sichere Schulwege.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>



  )
}

export default Kleidung