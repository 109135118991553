import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsEmojiSmileFill, BsEmojiFrownFill } from 'react-icons/bs'
import ImageToLoad from '../../components/ImageToLoad'
import { Overview } from './RichtigFalsch/Overview'
import styles from './RichtigFalsch.module.scss'
import party from "party-js";
import { RichtigFalschData } from '../../data/data.js';
import InfoOverlay from './InfoOverlay'
import { ReactComponent as SuccessImg } from '../../images/celebrate.svg'
import GamesPreview from './GamesPreview'
import NextButton from '../../components/NextButton'
import PlayAudio from '../../components/PlayAudio'
import audioIntroSrc from '../../audio/rf_0_was-ist-richtig.mp3'
import audioRichtigSrc from '../../audio/rf_0_richtig.mp3'
import audioFalschSrc from '../../audio/rf_0_leider-falsch.mp3'

const RichtigFalsch = () => {
  const [data, setData] = useState(shuffle(addAnswer(RichtigFalschData)))
  const [currentImg, setCurrentImg] = useState(0)
  const [chosenImg, setChosenImg] = useState(-1)
  const [randBinary, setRandBinary] = useState(false)
  
  const [gameEnded, setGameEnded] = useState(false)

  const [showAnswer, setShowAnswer] = useState(false)
  const [overlay, setOverlay] = useState(false)
  const [showNextNav, setShowNextNav] = useState(false)

  const [triggerAudio, setTriggerAudio] = useState(false)
  const [audioSrc, setAudioSrc] = useState(audioIntroSrc)

  // const data = props.userData.games.rf.data;
  const currImgData = data[currentImg];

  // success image reference
  const successImgRef = useRef(null);

  // helper animation reference
  const helperRef = useRef(null);

  // console.log(currImgData)
  // console.log(currentImg)
  // console.log(data.length)
  // console.log("gamemode: "+gameMode)
  // console.log("shownext: "+showNextNav)
  // console.log(gameEnded)

  // init
  useEffect(() => {
    setRandBinary((Math.random() > 0.5) ? false : true)

    // trigger audio
    setTimeout(() => {
      setTriggerAudio(true)
    }, 1000);
  }, [])

  const getCurrentAudio = () => {
    let audio = require('../../audio/'+data[currentImg].audio)
    return audio
  }

  const handleImgChosen = (img) => {
    setChosenImg(img)
    setShowNextNav(false)
    setTriggerAudio(false)
    
    // check if game ended with this img
    let nextImgs = getNextImg(currentImg, data);

    // audio 1) trigger overlay audio
    setAudioSrc((img === 0) ? audioFalschSrc : audioRichtigSrc)
    setTimeout(() => {
        setTriggerAudio(true)
    },50)

    // animations
    // 1) show overlay
    setOverlay(true)
    document.body.style.overflow = "hidden"
    setTimeout(() => {
      setOverlay(false)
      document.body.style.overflow = "auto"
      setShowAnswer(true)
    }, 2500);

    // 2) show general response
    setTimeout(() => {
      if(img === 1){
        party.confetti(document.querySelector("."+styles.headRow), {
          count: party.variation.range(80, 120),
          size: party.variation.range(0.4, 1),
        });
      }
    }, 500);

    // audio 3) trigger explanation audio
    setTimeout(() => {
      let currentAudio = getCurrentAudio()
      setAudioSrc(currentAudio)
      setTimeout(() => {
          setTriggerAudio(true)
      }, 50)
    }, 3000)
    
    // 4) wait and go to final end screen if every question is answered correctly
    if(((nextImgs.length === 1) && (nextImgs[0] === currentImg) && (img === 1)) || (nextImgs.length === 0)){
      setTimeout(() => {
        setShowNextNav(true)
      }, 3000); 
      setTimeout(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        setGameEnded(true)
        setTimeout(() => {
          if (successImgRef.current) {
            party.sparkles(successImgRef.current, {
              count: party.variation.range(40, 60),
              speed: party.variation.range(100, 300)
            });  
          }
        }, 500);
      }, 12000);
    }
    else if((nextImgs.length !== 0) && !(nextImgs.length === 1 && nextImgs[0] === currentImg && img === 1) && !gameEnded){
      // 3) show button to navigate to next img
      setTimeout(() => {
        setShowNextNav(true)
      }, 3000); 
    }

    // 5) update user data
    currImgData.correctAnswer = (img === 1) ? "correct" : (img === 0) ? "false" : "none";
    
    // update data
    let newData = data;
    for (let i = 0; i < newData.length; i++) {
      let elem = newData[i];
      if(elem.img === currImgData.img) {
        elem.correctAnswer = currImgData.correctAnswer;   
        break;
      }
    }
    setData(newData)
  }  

  const handleNewImg = (img) => {
    setCurrentImg(img)
    setChosenImg(-1)
    setShowAnswer(false)
    setShowNextNav(false)
    setTriggerAudio(false)

    setTimeout(() => {
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, 10);

    setRandBinary((Math.random() > 0.5) ? false : true)
  }

  const handleImgChange = (newImg) => {
    handleNewImg(newImg)
  }

  const handleNextImg = () => {
    let nextImgs = getNextImg(currentImg, data);
    if(nextImgs.length > 0){
      if(nextImgs.length > 1){
        if(nextImgs[0] === currentImg){
          handleNewImg(nextImgs[1])
        }
        else{
          handleNewImg(nextImgs[0])
        }
      }
      else{
        handleNewImg(nextImgs[0])
      }
    }
    else{
      setTriggerAudio(false)
      setShowNextNav(false)
      setGameEnded(true)
      window.scrollTo({top: 0, left: 0, behavior: "smooth"});
      setTimeout(() => {
        if (successImgRef.current) {
          party.sparkles(successImgRef.current, {
            count: party.variation.range(40, 60),
            speed: party.variation.range(100, 300)
          });  
        }
      }, 500);
    }
  }
  
  return (
    <div className={(gameEnded) ? styles.wrapper+" "+styles.heightAuto : styles.wrapper}>
    {(gameEnded) ?
        <Container className={(chosenImg !== -1) ? styles.container+" "+styles.solution : styles.container}>
          <Row className='mt-4 mb-3 text-center'>
            <Col className={styles.endingScreen}>
              <h1>Super!</h1>
              <p>Du hast alle Fragen richtig beantwortet.</p>
              <div ref={successImgRef} className={styles.successImg}><SuccessImg /></div>
            </Col>
          </Row>
        </Container>
        :
        <Container className={(chosenImg !== -1) ? styles.container+" "+styles.solution : styles.container}>
          <Row>
            <Col>
              <Row className={(overlay) ? styles.headRow+" "+styles.overlayActive : styles.headRow}>
                <Col className='d-flex justify-content-center px-sm-0 p-0'>
                  <div className={(chosenImg === 1) ? styles.feedbackWrap+" "+styles.rotate+" "+styles.correct : (chosenImg === 0) ? styles.feedbackWrap+" "+styles.rotate+" "+styles.false : styles.feedbackWrap}>
                      {(chosenImg === 0) && <BsEmojiFrownFill />}
                      {(chosenImg === 1) && <BsEmojiSmileFill />}
                    <h1>{(chosenImg === 1) ? "Richtig!" : (chosenImg === 0) ? "Leider falsch" : "Was ist richtig?"}</h1>
                    {/* {(chosenImg === -1) && <PlayAudio className={styles.audioBtn} />} */}
                  </div>
                </Col>
              </Row>
              <Row className={(showAnswer) ? styles.imgRow+" "+styles.active : styles.imgRow}  ref={helperRef}>
                <Col className={(chosenImg === 0) ? styles.imgCol+" "+styles.chosenImg : (chosenImg !== -1) ? styles.imgCol+" "+styles.unchosenImg : styles.imgCol} style={{order: ((randBinary) ? 1 : 2), alignItems: ((!showAnswer) ? "center" : "flex-start")}} sm={(showAnswer) ? 4 : 6}>
                  <div className={styles.imgWrap+" "+styles.falseImg} onClick={(chosenImg === -1) ? ()=>handleImgChosen(0) : null}>
                    {(showAnswer) && <BsEmojiFrownFill/>}
                    <ImageToLoad src={require('../../images/richtig-falsch/'+currImgData.img.false)} alt="" className={styles.img} classNameWrapper={styles.imgWrapper} />
                  </div>
                </Col>
                <Col className={(chosenImg === 1) ? styles.imgCol+" "+styles.chosenImg : (chosenImg !== -1) ? styles.imgCol+" "+styles.unchosenImg : styles.imgCol} style={{order: ((randBinary) ? 2 : 1), alignItems: ((!showAnswer) ? "center" : "flex-start")}} sm={(showAnswer) ? 4 : 6}>
                  <div className={styles.imgWrap+" "+styles.correctImg} onClick={(chosenImg === -1) ? ()=>handleImgChosen(1) : null}>
                    {(showAnswer) && <BsEmojiSmileFill />}
                    <ImageToLoad src={require('../../images/richtig-falsch/'+currImgData.img.correct)} alt="" className={styles.img} classNameWrapper={styles.imgWrapper} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {(showAnswer) &&
            <Row className='my-xs-1 my-md-3'>
              <Col lg={10} sm={8}>
                <div className={(chosenImg === 1) ? styles.info+" "+styles.correct : styles.info+" "+styles.false}>
                  <h2>{currImgData.explanation.label}</h2>
                  <p className='my-0'>{currImgData.explanation.text}</p>
                </div>
              </Col>
              <Col className={styles.navBtn} id="navBtnRow" lg={2} sm={4}>
                <NextButton onClick={(showNextNav) ? handleNextImg : null} title="Nächstes Bild" className={(showNextNav) ? styles.wobble : styles.muted} />
              </Col>
            </Row>
          }
          <Row className={styles.overviewRow+' my-xs-1 my-md-3'}>
            <Col>    
              <Overview userData={data} currentImg={currentImg} goToImg={handleImgChange} />
            </Col>
          </Row>
          <InfoOverlay active={overlay} true={(chosenImg === 1)} false={(chosenImg === 0)} />
          <PlayAudio src={audioSrc} active={triggerAudio} setActive={setTriggerAudio} className={styles.audioBtn} />
        </Container>
        }
        {(gameEnded) && 
          <Row className='m-5'>
            <Col className='d-flex flex-column align-items-center'>
              <div className={styles.flipBoxFront}>
                  <h2>Weitere Spiele</h2>
              </div>
              <GamesPreview richtigfalsch={false} />
            </Col>
          </Row>
        }
    </div>
  )
}

function getNextImg(currentImg, images){
  let openToPlay = []
  for (let i = currentImg; i < images.length; i++) {
    const image = images[i];
    if(image.correctAnswer !== "correct"){
      openToPlay.push(i)
    }
  }
  for (let i = 0; i < currentImg; i++) {
    const image = images[i];
    if(image.correctAnswer !== "correct"){
      openToPlay.push(i)
    }
  }
  return openToPlay
}

function shuffle(array) {
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

function addAnswer(array){
  let newArray = [];
  for (let i = 0; i < array.length; i++) {
    let element = array[i];
    newArray.push({...element, "correctAnswer": "none"})    
  }
  return newArray;
}

export default RichtigFalsch
