import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './Videos.module.scss';
import { Themenbereiche } from '../../data/data.js';
import Daumenhoch from '../../components/Daumenhoch';
import CardSliderGroup from '../../components/CardSliderGroup';
import Video from '../../components/Video';

const Themenber = Themenbereiche.sort((a, b) => a.id - b.id);

const Themen = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  // Handle resize and reset scrolled Areas
  function handleResize(e) {

    if (
      (window.innerWidth > dimensions.width &&
        window.innerWidth >= 768 &&
        dimensions.width < 768) ||
      (window.innerWidth < dimensions.width &&
        window.innerWidth <= 768 &&
        dimensions.width > 768) ||
      (window.innerWidth > dimensions.width &&
        window.innerWidth >= 992 &&
        dimensions.width < 992) ||
      (window.innerWidth < dimensions.width &&
        window.innerWidth <= 992 &&
        dimensions.width > 992)
    ) {
      // Set Margin-Left to 0 to start from scratch
      for (let i = 1; i <= Themenbereiche.length; i++) {
        let themenblock = document.getElementById("thema" + i);
        themenblock.style.marginLeft = "0px";
        let arrow = themenblock.parentElement.querySelector(".al");
        arrow.style.opacity = "0";
      }
    }

    // Check if left/right arrows need to be shown
    for (let i = 1; i <= Themenbereiche.length; i++) {
      let themenblock = document.getElementById("thema" + i);
      var anzahlCards = themenblock.children.length;
      var check = window.innerWidth;
      var elementbreite = themenblock.children[0].offsetWidth;
      var test = check / elementbreite;
      var arr = themenblock.parentElement.querySelectorAll(".arr");

      if (test >= anzahlCards) {
        for (let j = 0; j < arr.length; j++) {
          arr[j].style.opacity = 0;
        }
      } else {
        if (themenblock.style.marginLeft !== "0px" && themenblock.style.marginLeft !== "") {
          arr[0].style.opacity = 0.8;
          arr[1].style.opacity = 0.8;
        } else {
          arr[0].style.opacity = 0;
          arr[1].style.opacity = 0.8;
        }


      }


    }

    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    // Check if scroll arrows need to be visible
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  });


  useEffect(() => {
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();
    }, 100);
  }, []);


  return (
    <div>
      <Container fluid className={styles.videohead}>
        <Row>
          <Col lg="8" className={styles.videocol}>
            <Video src="/video/collage.mp4" className='video-player embed-responsive-item' id="videoPlayer" poster={require('../../images/poster/poster_collage.jpg')} autoPlay={true} muted={true} loop={true}/>
          </Col>
          <Col lg="4" className={styles.infocol}>
            <p className={styles.absatz}>
              Der Schulweg zu Fuß ist für Kinder nach wie vor die sicherste Variante - vorausgesetzt sie kennen die wichtigsten Regeln im Straßenverkehr.
            </p>
            <p className={styles.absatz}>
              Dabei helfen unsere Clips und Tipps in allen Verkehrssituationen.
            </p>

          </Col>
        </Row>
      </Container>
      <Container className='abstand_ou'>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>So geht's: Schulweg planen und üben</h1>
            <p className='intro'>
              Kinder sollten den Schulweg zu Fuß gehen. Dann machen sie wichtige Erfahrungen im Straßenverkehr und lernen früh, sich sicher durch unterschiedliche Verkehrssituationen zu bewegen. Die Regeln für den Straßenverkehr können Eltern und Erziehungspersonen schon mit sehr jungen Kindern üben.
            </p>

          </Col>

        </Row>

      </Container>
      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />

            </Col>
            <Col md="7">
              <ol className={styles.testliste}>
                <li>Erklären und zeigen Sie das sichere Verhalten im Straßenverkehr.        </li>
                <li>Verwenden Sie einfache Worte und lassen Sie Ihr Kind die Regeln wiederholen.</li>
                <li>Üben Sie gemeinsam mit Ihrem Kind.</li>
              </ol>
              <p>
                Der Schulwegtrainer hilft dabei, die wichtigsten Verhaltensregeln spielend in den Alltag zu integrieren. Alle Videos geben Eltern oder Kindern einfache Tipps an die Hand. Vertiefende Informationen unterstützen beim praktischen Üben des Schulwegs!
              </p>
            </Col>
          </Row>
        </Container>


      </Container>



      <Container fluid className={'bg_lightgray ' + styles.themenbereich} onResize={handleResize}>

        {
          Themenber.map((id, index) => (
            <CardSliderGroup thema={Themenber[index].id} videos={Themenber[index].videos} title={Themenber[index].title} key={index} />
          ))
        }


      </Container>


    </div>
  );
}

export default Themen;