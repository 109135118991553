import React from 'react';
import { Container, Image, Row } from 'react-bootstrap'
import styles from './Footer.module.scss'
import SubNaviFooter from './SubNaviFooter';
import Logoleiste from './Logoleiste';

const Footer = (props) => {
  return (
    <footer className={styles.footer}>
        <Container fluid className={styles.strasse}>
            <Container>
                <Row className={styles.rownowrap}>
                  <Image src={require('../../images/footer_wegweiser.svg').default} alt=""/>
                  <p id={styles.footertitle} className={styles.footertitle}>Sicher zur Schule? Geht&nbsp;doch!</p>
                </Row>
            </Container>
        </Container>
        <Logoleiste />
        <SubNaviFooter section={props.section} />
    </footer>
    
  );
}

export default Footer;