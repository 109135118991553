import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const Ausfahrt = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <p className='intro'>
              In vielen Wohngebieten liegen Gehwege direkt an Garagenausfahrten oder Parkplätzen. Für kleine Schülerinnen und Schüler kann das gefährlich werden.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Oft setzen Fahrzeuge rückwärts zurück. Kleinere Kinder können in solchen Situationen schnell übersehen werden. Daher sollten Schulkinder frühzeitig lernen, wie sie sich in der Nähe von Ausfahrten verhalten sollten.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt=""/>

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                Richtiges Verhalten bei Ausfahrten und Garagen:
              </h2>
              <ul className="green_circle">
                <li>
                  Sei aufmerksam bei Ausfahrten, Parkplätzen oder Garagen.
                </li>
                <li>
                  Ausfahrten erkennst du beispielsweise an abgesenkten Bordsteinen.
                </li>
                <li>
                  Bleibe vor Ausfahrten, Garagen oder Parkplätzen stehen.
                </li>
                <li>
                  Achte darauf, ob Autos oder andere Fahrzeuge heraus- oder hereinfahren wollen.
                </li>
                <li>
                  Gehe erst, wenn die Autos durchgefahren sind.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>

  )
}

export default Ausfahrt