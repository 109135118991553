import styles from './Logoleiste.module.scss'
import { Container, Image } from 'react-bootstrap';

const Logoleiste = () => {
  return (
      <Container id={styles.container_logoleiste}>
        <div id={styles.logoleiste}>
            <div className={styles.lvw}>
                <div className={styles.ohneumbruch}>
                    <div className={styles.col_logo}>
                    <a href="https://www.verkehrswacht-bw.de/" target="_blank" rel="noreferrer"><Image src={require('../../images/logo_lvw.svg').default} width="625" height="352" alt="Zur Webseite der Landesverkehrswacht"/></a>
                    </div>

                </div>

            </div>
            <div className={styles.gefoerdert}>
                <div>
                    <div>
                        <p>
                            Gefördert von:
                        </p>
                    </div>

                </div>
                <div className={styles.ohneumbruch}>
                    <div>
                        <a href="https://www.ukbw.de" target="_blank" rel="noreferrer"><Image src={require('../../images/logo_ukbw.svg').default} alt="Zur Webseite der UKBW" width="163" height="60" /></a>
                    </div>
                    <div className={styles.box_im}>
                        {/* <a href="https://im.baden-wuerttemberg.de/de/startseite" target="_blank" rel="noreferrer"><Image src={require('../../images/logo_innenministerium_bw.jpg')} width="210" height="60" alt="Zur Webseite des Ministerium des Inneren, für Digitalisierung und Kommunen Baden-Württemberg"/></a> */}
                        <a href="https://im.baden-wuerttemberg.de/de/startseite" target="_blank" rel="noreferrer">
                            <Image src={require('../../images/logo_innenministerium_plain.png')} width="210" height="60" alt="Zur Webseite des Ministerium des Inneren, für Digitalisierung und Kommunen Baden-Württemberg"/><br />
                            <span className={styles.logo_im}>Ministerium des Inneren, für Digitalisierung und Kommunen Baden&#8209;Württemberg</span>
                        </a>
                    </div>                    
                </div> 
                               

            </div>
            <div className={styles.gefoerdert}>
                <div>
                    <div>
                        <p>
                            Im Rahmen von:
                        </p>
                    </div>

                </div>
                <div className={styles.ohneumbruch + " " + styles.gibacht}>
                    <div className={styles.col_logo}>
                    <a href="https://www.gib-acht-im-verkehr.de/" target="_blank" rel="noreferrer"><Image src={require('../../images/logo_gibacht.png')} width="200" height="142" alt="Zur Webseite der Verkehrssicherheitsaktion Gib Acht im Verkehr"/></a>
                    </div>

                </div>

            </div>            

        </div>
      </Container>
  );
}

export default Logoleiste;