import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageHeader from '../Parents/PageHeader';

const Barrierefreiheit = (props) => {
  return (
    <div>
      <PageHeader path={props.path} headerart="service" />      
      <Container>
        <Row>
          <Col lg="9">      
            <h1>Erklärung zur Barrierefreiheit</h1>
            <p>
              Die Landesverkehrswacht Baden-Württemberg e. V. ist bemüht, ihre Webseites und mobilen Anwendungen im Einklang mit den Bestimmungen nach § 10 Absatz 1 des Landesbehindertengleichstellungsgesetzes (L-BGG) sowie der Barrierefreien-Informationstechnik-Verordnung (BITV 2.0) zur Umsetzung der Richtlinie (EU) 2016/2102 barrierefrei zugänglich zu machen.
            </p>
            <p>
              Diese Erklärung zur Barrierefreiheit gilt die aktuell im Internet erreichbare Version dieser Website <Link to="/">www.schulwegtrainer.de</Link>. 
            </p>
            <p>
              Die auf der Seite verfügbaren Videos haben keinen zusätzlichen informativen Charakter, alle darin enthaltenen informationstragenden Inhalte stehen auch an anderer Stelle auf der Seite als barrierefrei zugänglicher Text zur Verfügung. Daher sind die Videos zwar untertitelt, haben aber keine zusätzlichen Transkripte oder Audiodeskription.
            </p>
            <p>
              Ausgenommen von der hier aufgeführten Erklärung ist der Bereich "Für Kinder". Dieser enthält Spiele, die nicht nur informationsvermittelnden Charakter haben und denen hauptsächlich das Prinzip des spielerischem Lernens durch Bilder zu Grunde liegt. Diese Bilder erfüllen dann zum Beispiel nicht die Mindestanforderungen für Kontrastverhältnisse.
            </p>
            <h2>Stand der Vereinbarkeit mit den Anforderungen</h2>
            <p>
              Die Überprüfung der Einhaltung der Anforderungen beruht auf einer am 20.03.2023 durchgeführten Selbstbewertung. Wir arbeiten an den notwendigen Prozessen, die Informationen der Landesverkehrswacht Baden-Württemberg e. V. in Bezug auf die digitale Barrierefreiheit fortlaufend weiter zu verbessern. Wir sind bemüht, alle Hürden für Menschen mit Behinderung zeitnah zu beseitigen.
            </p>
            <p>
              Diese Webseite ist wegen der folgenden Unvereinbarkeiten teilweise mit § 10 Absatz 1 L-BGG vereinbar.              
            </p>
            <h2>Nicht barrierefreie Inhalte</h2>
            <p className='vorliste'>
              Die Webseite weist folgende Unvereinbarkeiten mit § 10 Absatz 1 L-BGG auf:
            </p>
            <ul>
              <li>
                Die Erklärung zur Barrierefreiheit, sowie Hinweise zur Navigation sind noch nicht in Leichter Sprache verfügbar. 
              </li>
              <li>
                Es sind keine Informationen in Deutscher Gebärdensprache verfügbar. 
              </li>  
              <li>
                Es gibt keine Anpassungsmöglichkeiten für Position, Textgröße, Farbe, Stil oder Schriftart der zuschaltbaren Untertitel. 
              </li>                          
            </ul>
            <h2>Erstellung dieser Erklärung zur Barrierefreiheit</h2>
            <p>
              Diese Erklärung wurde am 20.03.2023 erstellt. 
            </p>
            <h2>Rückmeldung und Kontaktangaben</h2>
            <p>
              Etwaige Mängel in Bezug auf die Einhaltung der Barrierefreiheitsanforderungen können Sie uns per E-Mail an <a href="mailto:landesverkehrswacht@lvw-bw.de">landesverkehrswacht@lvw-bw.de</a> mitteilen. 
            </p>
            <p className='vorliste'>
              Sie können uns auch per Post oder telefonisch kontaktieren:
            </p>
            <p>
              Landesverkehrswacht Baden-Württemberg e. V.<br />
              Kesselstraße 38<br />
              70327 Stuttgart<br />
              Telefon: 0711 407030-0
            </p>

       
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Barrierefreiheit;