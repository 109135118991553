import React from 'react'
import ImageToLoad from './ImageToLoad'
import styles from './PlayButton.module.scss'
import bild from '../images/icon_play_blau.svg'
import { Link } from 'react-router-dom'

const PlayButton = (props) => {
  return (
    <div className={styles.playbutton}>
        <Link to={props.ziel} ><ImageToLoad src={bild} alt="Zum Video" /></Link>
    </div>

  )
}

export default PlayButton