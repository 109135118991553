import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import PageHeader from './PageHeader';

const Grundlagen = (props) => {
  useEffect(() => {
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();
    }, 100);
  }, []);

  return (
    <div>
      <PageHeader path={props.path} />
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>Kind und Verkehr</h1>
            <h2>Kinder nehmen Gefahren im Straßenverkehr anders wahr</h2>
            <p>
              Mit Beginn des schulfähigen Alters erkennen Kinder eine gefährliche Situation im Straßenverkehr. Allerdings erst in dem Moment, in dem die Gefahr bereits eingetreten ist. Dann ist es oft zu spät, um noch sicherheitsbewusst zu reagieren. Eine riskante Situation vermeiden, können Kinder allmählich ab dem 8 Lebensjahr. Sie können sich dann vorstellen, welche Folgen eine Verkehrssituation haben kann. Die Gefahr bewusst vermeiden, lernen Kinder erst mit 9 oder 10 Jahren. Dann können sie im Sinne der Sicherheit handeln und einer möglichen Gefahr aus dem Weg gehen.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <h2>Kinder sehen anders</h2>
            <p>
              Die Sehfähigkeit von Kindern in der ersten Klasse ist noch nicht vollständig entwickelt. Dabei ist die Sehfähigkeit im Straßenverkehr besonders entscheidend, da über 90 Prozent der Situationen über das Auge wahrgenommen werden. Kinder sehen nur aus ihrer Perspektive, was sie nicht wahrnehmen, existiert für sie in dem Moment nicht. Umgekehrt gehen sie auch davon aus, dass sie gesehen werden, wenn sie beispielsweise einen Fahrradfahrer wahrgenommen haben.
            </p>
            <p className="vorliste">
              Das können Kinder im schulfähigen Alter über das Auge wahrnehmen:
            </p>
            <ul>
              <li>
                Das Sichtfeld von Kindern ist enger als bei Erwachsenen. Sie sehen nicht aus dem Augenwinkel.
              </li>
              <li>
                Sie erkennen Grün oder Rot bei Ampeln.
              </li>
              <li>
                Kinder sehen „langsamer“ als Erwachsene, sie haben eine längere Verarbeitung von Sinneseindrücken.
              </li>
              <li>
                In diesem Alter fehlt noch die Entfernungs- und Geschwindigkeitsbeurteilung.
              </li>
              <li>
                Ungefähr ab 6 Jahren können Kinder zwischen stehenden und fahrenden Fahrzeugen unterscheiden.
              </li>
              <li>
                Ab 7 Jahren können Kinder zwischen Links und Rechts unterscheiden.
              </li>
              <li>
                Ein Bewusstsein für Positionen und Perspektiven entwickelt sich erst ab ca. 9 Jahren.
              </li>
            </ul>
          </Col>

        </Row>
        <Row>
          <Col lg="9">
            <h2>Kinder hören anders</h2>
            <p>
              Was für das kindliche Auge gilt, trifft auch auf das Hörvermögen von Schulanfängerinnen und Schulanfängern zu. Ihre Hörfähigkeit ist noch nicht vollständig entwickelt, so dass sie beispielsweise nicht zwischen wichtigen und unwichtigen Geräuschen, z. B. Fahrzeug-Geräusche gegen Stimmen, unterscheiden können.
            </p>
            <p>
              Erschwerend kommt hinzu, dass Kinder noch nicht hören können, aus welcher Richtung ein Fahrzeug angefahren kommt. Auch deswegen können sie oft nicht rechtzeitig auf den fahrenden Verkehr reagieren. Zudem benötigt das kindliche Ohr noch etwas mehr Zeit, um das Gehörte zu verarbeiten. Auch das trägt dazu bei, dass Kinder sich im Straßenverkehr scheinbar unüberlegt und riskant verhalten.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="9">
            <h2>Unfälle auf dem Schulweg</h2>
            <p>
              Die Zahl der Schulwegunfälle geht seit Jahren zurück. Auch vor den Schulschließungen aufgrund der Corona-Pandemie wurden weniger verunglückte Schülerinnen und Schüler registriert. Die Statistik erfasst in der Regel alle Unfälle, auch die mit dem Kinderrad oder zu Fuß und ohne Fremdbeteiligung. Die meisten gemeldeten Unfälle auf dem Schulweg passieren ohne Beteiligung anderer: Hinfallen, Abrutschen, Stolpern sind die häufigsten Unfallursachen auf dem Schulweg. Einen Grund dafür sehen Fachleute in der mangelnden Bewegungssicherheit von Kindern.
            </p>
            <p>
              Dies spricht umso mehr dafür, dass Kinder sich so früh wie möglich zu Fuß durch den Straßenverkehr bewegen. Das hat positive Auswirkungen auf die Schulwegsicherheit. Sollte es doch zu einem Unfall kommen, sollte dieser gemeldet werden.
            </p>
            <p className="vorliste">
              Im Falle eines Schulwegunfalls sollten Eltern umgehend reagieren:
            </p>
            <ul>
              <li>
                Kümmern Sie sich um das verletzte Kind.
              </li>
              <li>
                Benachrichtigen Sie schnellstmöglich die schulische Einrichtung Ihres Kindes.
              </li>
              <li>
                Wenn eine ärztliche Behandlung Ihres Kindes notwendig ist, informieren Sie auch den Kinderarzt darüber. Dann kann der Unfall gleich dem richtigen Versicherungsträger gemeldet werden.
              </li>
            </ul>
            <p>
              In der Regel werden Schulwegunfälle von der Schule selbst an den Versicherungsträger gemeldet. Näheres dazu erfahren Sie unter dem Punkt <Link to="/eltern/gut-zu-wissen/versicherungsschutz">„Versicherungsschutz“</Link>.
            </p>
          </Col>
        </Row>

      </Container>


    </div>

  );
}

export default Grundlagen;