import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import ImageToLoad from '../../components/ImageToLoad'
import NextButton from '../../components/NextButton'
import styles from './Wimmelbild.module.scss'
import { WimmelData } from '../../data/data.js';
import party from "party-js";
import WimmelbildImage from './Wimmelbild/WimmelbildImage'
import { ReactComponent as SuccessImg } from '../../images/celebrate.svg'
import GamesPreview from './GamesPreview';
import { BsFillArrowRightCircleFill, BsQuestionLg, BsEmojiSmileFill, BsEmojiFrownFill } from 'react-icons/bs'
import InfoOverlay from './InfoOverlay'
import PlayAudio from '../../components/PlayAudio'

const Wimmelbild = () => {

  // current group of elements to search
  const [searchedElement, setSearchedElement] = useState(0)

  // data
  const [data, setData] = useState(WimmelData)

  // game states
  const [gameEnded, setGameEnded] = useState(false)
  const [gameMode, setGameMode] = useState(true)
  
  // array containing all solutions found over all groups, init as empty array
  const [foundSolutions, setFoundSolutions] = useState([ ...Array(data.length).keys() ].map(()=>[]))

  // message states
  const [showMessage, setShowMessage] = useState(false)
  const [alert, setAlert] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [allFound, setAllFound] = useState(false)

  // audio states
  const [triggerAudio, setTriggerAudio] = useState(false)
  const [audioSrc, setAudioSrc] = useState(null)

  // reference to image container
  const containerRef = useRef(null);
  const gameEnd = useRef(null)

  // init game - shuffle data & show first message
  useEffect(() => {
    let shuffledData = shuffle(WimmelData)
    // init data in randomn order
    setData(shuffledData)

    // trigger first audio
    setAudioSrc(require('../../audio/wb_finde-'+shuffledData[0].resPathID+".mp3"))
    setTimeout(() => {
      setTriggerAudio(true)
    },50)

    // show message
    setShowMessage(true)
    setTimeout(() => {
      setShowMessage(false)
    }, 3000);
  }, [])
 
  // watch resizes of page
  useEffect(() => {
    updateSize()
    window.addEventListener('resize', updateSize);
  }, [containerRef])
  
  // if resize update header height to calc container height
  const updateSize = () => {
    if (containerRef.current) {
      let container = containerRef.current;
      let header = document.querySelector("header");
      container.style.height = (window.innerHeight - header.offsetHeight)+"px";
    } 
  }

  // get the next group of elements where not all elements are found
  const getNextElement = () => {
    // get all available elements
    let elements = []
    for (let i = 0; i < data.length; i++) {
      if(foundSolutions[i].length !== data[i].solutions.length){
        elements.push(i)
      }
    }
    if(elements.length > 0){
      for (let i = 0; i < elements.length; i++) {
        if(searchedElement >= elements[i] && searchedElement < elements[i+1]){
          return elements[i+1]
        }
        else if(i === elements.length-1 && searchedElement >= elements[elements.length-1]){
          return elements[0]
        }
        else if(i === 0 && searchedElement < elements[0]){
          return elements[0]
        }
      }
    }
    return false
  }

  // jump to the next group of elements to search
  const handleNextSearchElement = () => {
    setTriggerAudio(false)
    // reset found elements of previous group
    for (let i = 0; i < foundSolutions[searchedElement].length; i++) {
      let solution = foundSolutions[searchedElement][i];
      setColorStroke(document.getElementById(solution), "reset");
    }

    let nextSearchElement = getNextElement()
    setSearchedElement(nextSearchElement)
    
    if(nextSearchElement !== false){
      // show change message
      setShowMessage(true)
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);

      // trigger audio
      setAudioSrc(require('../../audio/wb_finde-'+data[nextSearchElement].resPathID+".mp3"))
      setTimeout(() => {
        setTriggerAudio(true)
      },50)
      
      // set all previously found elements active
      if(foundSolutions[nextSearchElement].length > 0){
        for (let i = 0; i < foundSolutions[nextSearchElement].length; i++) {
          let solution = foundSolutions[nextSearchElement][i];
          setColorStroke(document.getElementById(solution), "correct");
        }
      }
    }
  }

  // on click on Wimmel-img
  function gameHandler(event){
    setTriggerAudio(false)
    if(!showMessage){
      // if element found & element has not already been found, highlight it & add to solution list
      if(event.target.id.includes(data[searchedElement].resPathID) && !(document.getElementById(event.target.id).classList.contains("found"))) {
        setColorStroke(document.getElementById(event.target.id), "correct");
        document.getElementById(event.target.id).classList.add("found")
        let newSolutions = foundSolutions;
        newSolutions[searchedElement].push(event.target.id)
        setFoundSolutions(newSolutions)

        // all elements of this round found
        if(newSolutions[searchedElement].length === data[searchedElement].solutions.length){
          
          // show all found message
          setShowMessage(true)
          setAllFound(true)
          setTimeout(() => {
            setShowMessage(false);
            setTimeout(() => {
              setAllFound(false)
            }, 500);
          }, 2000);

          // trigger audio
          setAudioSrc(require('../../audio/wb_gefunden-'+data[searchedElement].resPathID+".mp3"))
          setTimeout(() => {
            setTriggerAudio(true)
          },50)

          // check if all other rounds are finished too
          let allElementsAllRounds = () => {
            for (let i = 0; i < newSolutions.length; i++) {
              let elem = newSolutions[i];
              if(elem.length < WimmelData[0].solutions.length){
                return false
              }
            }
            return true
          }
          // if all elements of all round found
          if(allElementsAllRounds()){
            setTimeout(() => {
              setGameEnded(true)
              setTimeout(() => {
              if(gameEnd.current){
                party.sparkles(gameEnd.current.parentNode, {
                  count: party.variation.range(10, 60),
                  speed: party.variation.range(50, 300)
                }); 
              }
              }, 500);
            }, 2000);

            // trigger audio
            setAudioSrc(require('../../audio/wb_ende.mp3'))
            setTimeout(() => {
              setTriggerAudio(true)
            },50)
            
            // show wimmelbild only
            setTimeout(() => {
              setGameMode(false)
              for (let i = 0; i < newSolutions[searchedElement].length; i++) {
                let solution = newSolutions[searchedElement][i];
                setColorStroke(document.getElementById(solution), "reset");
              }
            }, 10000);
          }
          else{ // else reset image and go to next round
            setTimeout(() => {
              handleNextSearchElement()
          }, 3000);
          }          
        }
        else{
          // show success message
          setShowMessage(true)
          setSuccessMsg(true);
          setTimeout(() => {
            setShowMessage(false);
            setTimeout(() => {
              setSuccessMsg(false);
            }, 500);
          }, 1500);
        }

        // start confetti
        setTimeout(() => {
          party.confetti(document.getElementById("searched_"+foundSolutions[searchedElement][foundSolutions[searchedElement].length-1]), {
            count: party.variation.range(40, 80),
            size: party.variation.range(0.4, 1),
          });
        }, 500);
      }
      else{
        setShowMessage(true)
        setAlert(true);
        setTimeout(() => {
          setShowMessage(false); 
          setTimeout(() => {
            setAlert(false);
          }, 500);
        }, 1500);
      }
    }
  }

  // console.log(foundSolutions[searchedElement])
  // console.log(searchedElement)
  // console.log(data)
  // console.log(data[searchedElement])

  return (
    <div>
      <Container fluid="lg" className={styles.wrapper} ref={containerRef}>
          <WimmelbildImage gameHandler={(gameMode) ? gameHandler : null} gameEnded={gameEnded && !gameMode} />
          {(audioSrc !== null) && <PlayAudio src={audioSrc} active={triggerAudio} setActive={setTriggerAudio} className={styles.audioBtn} />}
          <InfoOverlay active={showMessage} true={successMsg || allFound} false={alert}>
            {(!successMsg && !allFound && !alert) ? 
            <div className={styles.msgBox}>
                <h1>Finde die {data[searchedElement].label}!</h1>
            </div>
            :
            <div className={(successMsg || allFound) ? styles.msgBox+" "+styles.correct : alert ? styles.msgBox+" "+styles.false : styles.msgBox}>
              {alert &&<BsEmojiFrownFill />}
              {(successMsg || allFound) && <BsEmojiSmileFill />}
              <h1>{(allFound) ? "Alle "+data[searchedElement].label+" gefunden!" : (successMsg) ? data[searchedElement].labelSingular+" gefunden!" : "Nichts gefunden"}</h1>
            </div>
            }
          </InfoOverlay>
          <div className={styles.searchedElementRow} id="searchedElementRow">
            <Container fluid="lg">
              {(gameMode) ? 
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <span>Finde die {data[searchedElement].label} - {(foundSolutions[searchedElement].length)} / {data[searchedElement].solutions.length} gefunden</span>
                    </Col>
                  </Row>
                  <Row className={styles.searchedElementsWrap}>
                    {data[searchedElement].solutions.map((item, index)=>{
                      return (
                        <Col key={index} className={styles.searchedElementCol} id={"searched_"+item.replace("wimmelbild_", "").replace(".png", "")}>
                          <div className={(foundSolutions[searchedElement].some((elem)=>item.includes(elem))) ? styles.searchedElementWrapper+" "+styles.found : styles.searchedElementWrapper}>
                            <ImageToLoad src={require('../../images/wimmelbild/'+item)} alt="" className={styles.searchedElement} classNameWrapper={styles.searchedElementImgWrapper} />
                            {(foundSolutions[searchedElement].some((elem)=>item.includes(elem))) ? <BsEmojiSmileFill /> : <BsQuestionLg />}
                          </div>
                        </Col>
                      )
                    })}
                    <Col className="d-flex align-items-center" style={{overflow: "visible"}}>
                      <NextButton onClick={handleNextSearchElement} inactive={gameEnded || getNextElement() === searchedElement} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              <Row>
                <Col className='d-flex justify-content-end'>
                  <Button variant='primary' onClick={()=>document.querySelector("."+styles.moreGames).scrollIntoView()}>Mehr Spiele <BsFillArrowRightCircleFill/></Button>
                </Col>
              </Row>
              }
            </Container>
          </div>
          {(gameEnded && gameMode) && 
            <div className={styles.gameEnd} style={{opacity: (gameEnded && gameMode) ? 1 : 0}} ref={gameEnd}>
              <h1>Super!</h1>
              <p>Du hast alles gefunden.</p>
              <div id="celebrate" className={styles.successImg}><SuccessImg /></div>
            </div>
          }
      </Container>
      {(!gameMode) && 
        <Container fluid="lg" style={{opacity: (!gameMode) ? 1 : 0}}>
        <Row>
          <Col className={styles.moreGames}>
            <h2>Weitere Spiele</h2>
            <GamesPreview wimmelbild={false} />
          </Col>
        </Row>
        </Container>
      }
    </div>
  )
}

function setColorStroke(elem, type){
  switch (type) {
      case "correct":
          elem.style.fill = "rgb(174, 201, 11)";
          elem.style.stroke = "rgb(174, 201, 11)";
          // elem.style.stroke = "rgb(0, 126, 182)";
          elem.style.strokeOpacity = 100;
          elem.style.fillOpacity = 0.2;
          elem.style.strokeWidth = "0.25rem";
          elem.style.cursor = "default";
          break;
      case "highlight-correct":
          elem.style.fill = "rgb(174, 201, 11)";
          elem.style.stroke = "rgb(174, 201, 11)";
          // elem.style.stroke = "rgb(0, 126, 182)";
          elem.style.strokeOpacity = 100;
          elem.style.fillOpacity = 0.2;
          elem.style.strokeWidth = "0.25rem";
          elem.style.cursor = "pointer";
          break;
        case "reset":
          elem.style.strokeOpacity = 0;
          elem.style.fillOpacity = 0;
          break;
      default:
          break;
  }
}

function shuffle(array) {
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export default Wimmelbild