import React from "react";
import { Col, Container, Row, Card, CardGroup } from "react-bootstrap";
import styles from "../pages/Parents/Videos.module.scss";
import { VideoData } from "../data/data.js";
import { Themenbereiche } from "../data/data.js";
import PlayButton from "./PlayButton";
import { useSwipeable } from "react-swipeable";

const VData = VideoData.sort((a, b) => a.sort - b.sort);

const CardSliderGroup = (props) => {
  const Aktuell = Themenbereiche.filter((i) => i.id === props.thema);
  const Videos = Aktuell[0].videos;

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => swipeLeft(eventData),
    onSwipedRight: (eventData) => swipeRight(eventData),
    trackMouse: true,
  });

  function handleClick(e) {
    // console.log("Last: "+e.currentTarget.lastChild.lastChild.children[0]);
    e.currentTarget.lastChild.lastChild.children[0].click();
  }

  function scrollLeft(e) {
    var MarginCard = 32;
    var numberChildren =
      e.currentTarget.parentElement.children[1].children.length;
    var breiteChildren =
      e.currentTarget.parentElement.children[1].children[0].offsetWidth +
      MarginCard;
    var stopp = -1 * breiteChildren * (numberChildren - 1);

    var MarginLeft = 0;
    if (e.currentTarget.parentElement.children[1].style.marginLeft === "") {
      MarginLeft = 0;
    } else {
      MarginLeft = parseInt(
        e.currentTarget.parentElement.children[1].style.marginLeft
      );
    }

    if (MarginLeft < 0) {
      MarginLeft += breiteChildren;
      e.currentTarget.parentElement.children[1].style = "margin-left: " + MarginLeft + "px;";

      if (MarginLeft >= 0) {
        e.currentTarget.style.opacity = "0";
      }

      if (MarginLeft > stopp) {
        e.currentTarget.nextElementSibling.style.opacity = "0.8";
      }
    }
  }

  function scrollRight(e) {
    var MarginCard = 32;
    var numberChildren =
      e.currentTarget.parentElement.children[1].children.length;
    var breiteChildren =
      e.currentTarget.parentElement.children[1].children[0].offsetWidth +
      MarginCard;
    var stopp = -1 * breiteChildren * (numberChildren - 1);
    // console.log("Stopp: " + stopp);

    var MarginLeft = 0;
    if (e.currentTarget.parentElement.children[1].style.marginLeft === "") {
      MarginLeft = 0;
    } else {
      MarginLeft = parseInt(
        e.currentTarget.parentElement.children[1].style.marginLeft
      );
    }

    if (MarginLeft > stopp) {
      MarginLeft -= breiteChildren;
      e.currentTarget.parentElement.children[1].style = "margin-left: " + MarginLeft + "px;";

      if (MarginLeft < 0) {
        // e.currentTarget.previousElementSibling.style.display = "block";
        e.currentTarget.previousElementSibling.style.opacity = "0.8";
      }

      if (MarginLeft <= stopp) {
        // e.currentTarget.style.display = "none";
        e.currentTarget.style.opacity = "0";
      }
    }
  }

  function swipeLeft(eventData) {
    eventData.event.target
      .closest("div.card-group")
      .parentElement.children[3].click();
  }

  function swipeRight(eventData) {
    eventData.event.target
      .closest("div.card-group")
      .parentElement.children[2].click();
  }


  return (
    <Container className={styles.relativ} >
      <Row>
        <Col>
          <h2 className={"blue " + styles.zwischenueberschrift}>
            {props.title}
          </h2>
        </Col>
      </Row>

      <CardGroup
        id={"thema" + props.thema}
        className={styles.kartengruppe}
        {...handlers}
      >
        {Videos.map((sort, index) => (
          <Card
            className={styles.karte}
            tag="a"
            onClick={handleClick}
            key={sort}
          >
            <Card.Img
              variant="top"
              src={require("../images/poster/" + VData[sort].poster)}
              alt=""
              className={styles.headerbild}
            />
            <Card.Body>
              <Card.Title>{VData[sort].title}</Card.Title>
              <Card.Text>{VData[sort].subtitle}</Card.Text>
            </Card.Body>
            <Card.Footer className={styles.kartenfuss}>
              <PlayButton ziel={VData[sort].uri} />
            </Card.Footer>
          </Card>
        ))}
      </CardGroup>
      <div className={styles.arrowleft + " al arr"} onClick={scrollLeft} tabIndex="0"></div>
      <div className={styles.arrowright + " ar arr"} onClick={scrollRight} tabIndex="0"></div>
    </Container>
  );
};

export default CardSliderGroup;
