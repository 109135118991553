import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import styles from './ButtonNew.module.scss';

const ButtonNew = (props) => {
  return (
    <div>
         <Link to={props.pfad} >
            <div className={props.art + ' ' + styles.button}>
                <div className={styles.icon}>
                    <Image src="" alt="" />
                </div>
                <div>
                    <p className={styles.head + " ohneabstand"}>{props.haupttext}</p>
                    <p className='ohneabstand'>
                        {props.subtext}
                    </p>
                </div>
            </div>
        </Link>
    </div>

  )
}

export default ButtonNew