import React, { useState, useEffect} from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useLocation } from "react-router-dom";

const Loading = () => {
    var currPath = useLocation().pathname;
    const [appLoading, setappLoading] = useState(false);

    useEffect(() => {
        setappLoading(false);
        setTimeout(() => {
            setappLoading(true);
        }, 1000);
    }, [currPath]);

    if(appLoading){
        return (
            <div className='loading-animation' style={{width: "300px", height: "300px", margin: "2rem auto"}}>
                <AiOutlineLoading className='spinner-icon'/>
                <span className='sr-only'>Website wird geladen ...</span>
            </div> 
        )
    }
    else{
        return null;
    }
}

export default Loading