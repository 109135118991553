import React from 'react'
import { BsFillArrowRightCircleFill, BsFillArrowLeftCircleFill, BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs';
import styles from './NextButton.module.scss'

const NextButton = (props) => {
    const title = (props.title !== undefined) ? props.title : "Nächstes Bild";
    const onClick = (props.onClick !== undefined) ? props.onClick : null;
    const className = (props.className !== undefined) ? props.className : "";
    const style = (props.style !== undefined) ? props.style : {};
    const inactive = (props.inactive !== undefined) ? props.inactive : false;

    return (
        <button onClick={(inactive) ? null : onClick} className={(inactive) ? styles.nextBtn+" "+className+" "+styles.inactive : styles.nextBtn+" "+className} title={title} style={style}>
            {
                (props.direction === "up") ? <BsFillArrowUpCircleFill /> : 
                (props.direction === "down") ? <BsFillArrowDownCircleFill /> :                 
                (props.direction === "left") ? <BsFillArrowLeftCircleFill /> : <BsFillArrowRightCircleFill />}
        </button>
    )
}

export default NextButton