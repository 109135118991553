import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const ParkendeAutos = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Vor allem in Städten sind am Straßenrand parkende Autos eher die Regel als die Ausnahme. Kinder haben oft keine andere Möglichkeit, über die Straße zu gehen als zwischen eng parkenden Fahrzeugen.
            </p>

          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Diese Situation birgt ein doppeltes Risiko für kleine Schülerinnen und Schüler. Sie können aufgrund ihrer Körpergröße zwischen den Fahrzeugen den Verkehr meist gar nicht oder nur schlecht überblicken und sie selbst werden vom fahrenden Verkehr oft erst spät gesehen. Das gilt auch für andere Sichtbehinderungen wie Hecken oder Litfaß-Säulen.
            </p>

            <p>
              Grundsätzlich sollten Erwachsene Kindern andere, sicherere Möglichkeiten zeigen, auf die andere Straßenseite zu kommen. Das ist aber in vielen Situationen nicht möglich. Deswegen sollten Erwachsene das Überqueren der Straße zwischen Sichtbehinderungen besonders gut üben.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">
              <h2 className='blue'>
                Sicherheit zwischen parkenden Fahrzeugen:
              </h2>
              <ul className='green_circle'>
                <li>
                  Halte immer einen Schritt vor dem Bordsteinrand, um die Situation zu überblicken.
                </li>
                <li>
                  Bevor du zwischen zwei Autos auf die Fahrbahn gehst, schau genau nach, ob eines der parkenden Fahrzeuge wegfahren will. Achte z.B. auf die Rücklichter.
                </li>
                <li>
                  Gehe langsam an die Straße vor, bis du alles sehen kannst.
                </li>
                <li>
                  Schaue nach links, rechts, links.
                </li>
                <li>
                  Gehe nur über die Straße, wenn alles frei ist.
                </li>
                <li>
                  Nimm einen anderen Weg, wenn du dich unsicher fühlst.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>


  )
}

export default ParkendeAutos