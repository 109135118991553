import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const Elterntaxi = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Es ist zwar gut gemeint, wenn Eltern ihre Kinder mit dem Auto in die Schule bringen. Aber: Elterntaxis gefährden Schülerinnen und Schüler mehr als der Schulweg zu Fuß.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Denn in der Regel sind die Parkmöglichkeiten vor Schulen gering, das Verkehrschaos vorprogrammiert und die Unfallgefahren für die eigenen und anderen Kinder besonders groß.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>Tipps</h2>

              <ul className='green_circle'>
                <li>
                  Bringen Sie Ihr Kind möglichst selten mit dem Auto in die Schule. Der Schulweg zu Fuß ist in der Regel am sichersten.
                </li>
                <li>
                  Achten Sie als Autofahrende/r in Schulnähe besonders auf andere Schulkinder.
                </li>
                <li>
                  Halten Sie nur an ausgewiesenen Elterntaxi-Parkplätzen.
                </li>
                <li>
                  Besser: Lassen Sie Ihr Kind in einer Nebenstraße aussteigen und den Rest des Schulwegs zu Fuß gehen.
                </li>
              </ul>

            </Col>
          </Row>
        </Container>
      </Container>
    </div>


  )
}

export default Elterntaxi