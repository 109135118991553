import React from 'react';
import { Container, Image } from 'react-bootstrap';
import styles from './PageHeader.module.scss';
import { HeaderData } from '../../data/data.js';
import logo from '../../images/logo_swt.svg';


const PageHeader = (props) => {
  const last = props.path.substring(props.path.lastIndexOf("/")+1, props.path.length);
  const currentHeader = HeaderData.filter(i => i.path === last);

  return (
    <Container fluid id={styles.tafel} className={(props.headerart === "service") ? styles.services : ""}>
      <div id={styles.menschen} className={styles[currentHeader[0].klasse]}>
        <div id={styles.logo} >
          <Image src={logo} width="172" height="121" alt="Logo des Schulwegtrainers"/> 
        </div>
        

        <div id={styles.trapez}>
          <div id={styles.dreieck}></div>
          <div id={styles.trapeztext}>{(props.headerart !== "service") && currentHeader[0].title}</div>
        </div> 
      </div>     

    </Container>

  );
}

export default PageHeader;