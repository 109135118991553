import React from 'react'
import styles from './InfoOverlay.module.scss'

const InfoOverlay = (props) => {

  const fullSize = (props.fullSize !== undefined) ? props.fullSize : true;

  var classNames = styles.overlay
  if(props.active) classNames+=(" "+styles.active)
  if(props.true) classNames+=(" "+styles.true)
  if(props.false) classNames+=(" "+styles.false)
  if(!fullSize) classNames+=(" "+styles.small)

  return (
    <div className={classNames}>
      {props.children}
    </div>
  )
}

export default InfoOverlay