import { Container, Image } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import styles from './NavbarMain.module.scss';
import icon_swt from '../../images/logo_swt_navi.svg';
import icon_leichtesprache from '../../images/icon_leichte-sprache.svg';
import Burgermenu from './Burgermenu';


const NavbarMain = (props) => {
  return (
    <Navbar bg={(props.games) ? "secondary" : "primary"} expand="" variant="dark" className='text-white border-bottom' id={(props.games) ? styles.games : styles.parents} aria-label="Hauptnavigation">
      <Container fluid>
        <div id={styles.apptitel}>
          <Link className={styles.nav_element + ' ' + styles.logo} to="/home">
            <Image src={icon_swt} alt="Zur Startseite"/>
            <div id={styles.titeltext}>
              <span id={styles.haupttitel}>Schulwegtrainer</span>
              <span id={styles.untertitel}>Initiative der Landesverkehrswacht BW und der Unfallkasse BW</span>
            </div>
          </Link>
        </div>
          <Nav
            className={styles.mainnavi} 
          >
            <Link className={(props.section.includes("/eltern")) ? styles.nav_element + ' ' + styles.eltern + ' ' + styles.active + ' aktiv' : styles.nav_element + ' ' + styles.eltern }  to="/eltern"><Image src={require('../../images/icon_eltern_weiss.svg').default} alt="Zur Seite Eltern"/><span>Für Eltern</span></Link>
            <Link className={(props.section.includes("/spiele")) ? styles.nav_element + ' ' + styles.kinder + ' ' + styles.active + ' aktiv' : styles.nav_element + ' ' + styles.kinder }  to="/spiele"><Image src={require('../../images/icon_kinder_weiss.svg').default} alt="Zur Seite Kinder"/><span>Für Kinder</span></Link>
            <Link className={(props.section.includes("/leichte-sprache")) ? styles.nav_element + ' ' + styles.leicht + ' ' + styles.active : styles.nav_element + ' ' + styles.leicht }  to="/leichte-sprache"><Image src={icon_leichtesprache} alt="Zur Seite Leichte Sprache"/></Link>

            <Burgermenu section={props.section}/>
        </Nav>
 
      </Container>
    </Navbar>
  );
}

export default NavbarMain;