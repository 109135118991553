import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import PageHeader from '../Parents/PageHeader';
import styles from './LeichteSprache.module.scss';

const LeichteSprache = (props) => {
  return (
    <div>
      <PageHeader path={props.path} headerart="service"/>
      <Container id={styles.leichtesprache}>
        <Row className={styles.zeile}>
          <Col md="8">
            <h1>Informationen in Leichter Sprache<br />So ist der Schulweg sicher</h1>
          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/leichte_sprache_geprueft.jpg')} alt="" width="108" height="107" />

          </Col>
        </Row>


        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Den Schulweg üben</h2>
            <p>
              Im Straßenverkehr gibt es viele Gefahren.
            </p>
            <p>
              Deswegen kann der Schulweg für Kinder gefährlich sein.
            </p>
            <p>
              Zum Beispiel:
            </p>
            <p>
              Weil an der Straße viele Autos fahren.
            </p>
            <p>
              Deswegen sollen die Kinder den Schulweg üben.
            </p>
            <p>
              Erst dann können die Kinder den Schulweg alleine gehen.
            </p>
            <p>
              Das Üben ist wichtig.
            </p>
            <p>
              Weil die Kinder den Schulweg noch <strong>nicht</strong> kennen.
            </p>
          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/menschengruppe.jpg')} alt="" width="560" height="489" />
          </Col>
        </Row>
        <Row className={styles.zeile}>
          <Col md="8">
            <h2>So klappt das Üben gut:</h2>
            <p>
              Ein Erwachsener übt den Schulweg zusammen mit dem Kind.
            </p>
            <p>Das heißt:</p>
            <p>
              Der Erwachsene und das Kind gehen den Schulweg zusammen.
            </p>
            <p>
              Nach dem Üben sollen die Kinder wissen:
            </p>
            <ul>
              <li>
                So komme ich sicher zur Schule
              </li>
              <li>
                An diesen Stellen muss ich besonders gut aufpassen.
              </li>
            </ul>
            <p>
              Dann können die Kinder bald auch den Schulweg alleine sicher laufen.
            </p>
          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/ueben.jpg')} alt="" width="161" height="250" />

          </Col>

        </Row>




        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Hilfe beim Üben</h2>
            <p>
              Es gibt eine besondere Internet-Seite.
            </p>
            <p>
              Die Internet-Seite heißt: <strong>Schulweg-Trainer</strong>.
            </p>
            <p>
              Auf der Internet-Seite steht zum Beispiel:
            </p>
            <ul>
              <li>So ist der Schulweg sicher für Kinder.</li>
              <li>
                So können Erwachsene den Kindern beim Üben von dem Schulweg helfen.
              </li>
            </ul>
            <p>
              Das ist die Adresse von der Internet-Seite: <a href="https://schulwegtrainer.de">schulwegtrainer.de</a>
            </p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/netzwerk.jpg')} alt="" width="350" height="332" />

          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>So können Sie mit Ihrem Kind den Schulweg üben</h2>
            <p>
              Gehen Sie den Schulweg mit Ihrem Kind zu Fuß.
            </p>
            <p>
              Zeigen Sie Ihrem Kind:
            </p>
            <ul>
              <li>An diesen Stellen kannst Du sicher die Straße überqueren.</li>
              <li>An diesen Stellen musst Du besonders gut aufpassen.</li>
            </ul>
            <p>
              Weil es hier besonders gefährlich ist.
            </p>
            <p>
              Es ist gut, wenn Sie ein Vorbild sind:
            </p>
            <p>
              Das heißt: Sie sind im Straßenverkehr vorsichtig.
            </p>
            <p>
              Dann kann ihr Kind von Ihnen lernen.
            </p>
            <p>
              Diese Sachen sind wichtig:
            </p>
            <ul>
              <li>
                <p>Aufmerksam sein.</p>
                <p>Das heißt: Das Kind soll auf den Straßenverkehr achten.</p>
              </li>
              <li>
                <p><strong>Nicht rennen.</strong></p>
                <p>
                  Lieber langsam laufen.
                </p>
                <p>
                  Dann hat das Kind mehr Zeit auf den Schulweg zu achten.
                </p>
                <p>
                  Auf andere Menschen und Fahrzeuge schauen.
                </p>
              </li>
              <li>
                <p>Die Fahrzeuge sollen das Kind gut sehen können.</p>
                <p>Dann können Sie noch vorsichtiger fahren.</p>
                <p>Damit das so ist, </p>
                <p>sind Reflektoren gut.</p>
                <p>Reflektoren sind leuchtende Teile von der Kleidung.</p>
                <p>Oder leuchtende Teile von einem Schul-Ranzen.</p>
              </li>
            </ul>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/zebrastreifen.jpg')} alt="" width="393" height="355" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn Sie mit dem Auto fahren</h2>
            <p>
              In der Nähe von der Schule ist das wichtig:
            </p>
            <ul>
              <li>
                <p>
                  Achten Sie auf ihr Kind.
                </p>
                <p>
                  Und achten Sie auf die anderen Kinder.
                </p>
              </li>
              <li>
                <p>
                  Lassen Sie ihr Kind nicht an irgendeinem Platz aussteigen.
                </p>
                <p>
                  Halten Sie mit dem Auto nur an Parkplätzen dafür.
                </p>
                <p>Noch besser ist: </p>
                <p>
                  Sie lassen ihr Kind in einer Neben-Straße aussteigen.
                </p>
                <p>
                  Und das Kind läuft den Rest von dem Weg alleine.
                </p>
                <p>
                  Das ist deshalb gut:
                </p>
                <p>
                  In den Nebenstraßen fahren oft weniger Autos.
                </p>
                <p>
                  Das ist dann auch weniger gefährlich für das Kind.
                </p>
              </li>
              <li>
                <p>Machen Sie eine Laufgruppe.</p>
                <p>In der Laufgruppe begleitet ein Erwachsener die Kinder auf dem Schulweg.</p>

              </li>

            </ul>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/auto.jpg')} alt="" width="533" height="324" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Das sind die Schulweg-Regeln für Kinder</h2>
            <p>
              Diese Regeln sind wichtig,
            </p>
            <p>
              wenn das Kind auf dem Gehweg läuft:
            </p>
            <ul>
              <li>
                <p>Achte auf die Autos.</p>
              </li>
              <li>
                <p>Halte Abstand vom Bordstein.</p>
                <p>Dann sind die Autos weiter von Dir entfernt </p>
                <p>und können Dich besser sehen. </p>
              </li>
              <li>
                <p>Gehe immer auf der Kinderseite. </p>
                <p>Die Kinderseite ist die Seite vom Gehweg, </p>
                <p>die weiter weg ist von den Autos. </p>
              </li>
              <li>
                <p>Gehe nur auf Wegen für Fußgänger.</p>
              </li>
              <li>
                <p>Bleibe stehen, </p>
                <p>wenn Du unsicher bist.<br /></p>
                <p>Lasse lieber das Auto vorbeifahren und gehe erst dann.<br /></p>
                <p>
                  Das ist sicherer.
                </p>

              </li>
            </ul>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/achtung.jpg')} alt="" width="428" height="326" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn du über die Straße gehen willst </h2>
            <p>Schaue Dich immer erst um:</p>
            <p>Kommt ein Auto?</p>
            <p>Dann bleibe stehen.</p>
            <p>Schaue immer nach links und nach rechts.</p>
            <p>Erst wenn kein Auto kommt, </p>
            <p>kannst Du sicher über die Straße gehen.</p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/daumen_hoch.jpg')} alt="" width="259" height="412" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn an der Straße viele Autos parken</h2>
            <p>
              Manchmal ist es so:
            </p>
            <p>Du willst vielleicht eine Straße überqueren. </p>
            <p>Aber an der Straße parken viele Autos.</p>
            <p>Und Du kannst deshalb die fahrenden Autos <strong>nicht</strong> sehen. </p>
            <p>Dann ist das wichtig: </p>
            <p>Gehe langsam vor bis an die Straße.</p>
            <p>Bis Du alles siehst. </p>
            <p>Schaue nach links und nach rechts und wieder nach links.</p>
            <p>Erst wenn <strong>kein</strong> Auto kommt, </p>
            <p>kannst Du sicher über die Straße gehen.<br /></p>
            <p>Fühlst Du dich nicht sicher? </p>
            <p>Oder kannst Du die Autos nicht gut sehen?</p>
            <p>Dann nimm einen anderen Weg.</p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/zwischen_autos.jpg')} alt="" width="538" height="364" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn du an einer Ampel stehst</h2>
            <p>An einer Ampel ist das wichtig:</p>
            <p>Gehe nur bei Grün über eine Ampel.</p>
            <p>Auch wenn die Ampel Grün zeigt:</p>
            <p>Schaue immer ob ein Auto kommt.</p>
          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/ampel.jpg')} alt="" width="158" height="221" />
          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn du an einem Zebrastreifen stehst</h2>
            <p>An einem Zebrastreifen ist das wichtig:</p>
            <p>Bleibe immer vor dem Bordstein stehen.</p>
            <p>Warte bis alle Autos stehen.</p>
            <p>Erst dann kannst Du über den Zebrastreifen gehen.</p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/stop.jpg')} alt="" width="386" height="379" />

          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn Du an diesen Stellen vorbeikommst:</h2>
            <ul>
              <li>
                <p>
                  <strong>Garagen</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Parkplätze</strong>
                </p>
              </li>
              <li>
                <p>
                  <strong>Ausfahrten</strong>
                </p>
              </li>
            </ul>
            <p>An Garagen, Parkplätzen und Ausfahrten ist das wichtig: </p>
            <p>Manchmal kannst Du an diesen Stellen nicht alles gut sehen.</p>
            <p>Zum Beispiel: Kommt ein Auto?</p>
            <p>Deswegen musst Du hier besonders gut aufpassen.:</p>
            <p>Will ein Autofahrer hineinfahren?</p>
            <p>Oder will ein Autofahrer herausfahren?</p>
            <p>Dann warte.</p>
            <p>Solange bis das Auto durchgefahren ist.</p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/ausfahrt.jpg')} alt="" width="290" height="223" />

          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Wenn du Blaulicht siehst und eine Sirene hörst</h2>
            <p>Das ist wichtig, wenn Du</p>
            <ul>
              <li>
                <p>Blaulicht siehst </p>
                <p>oder</p>
              </li>
              <li>
                <p>eine Sirene hörst:</p>
                <p>Fahrzeuge mit Blaulicht dürfen immer zuerst fahren.</p>
                <p>Auch wenn Deine Ampel grün ist.</p>
                <p>Fahrzeuge mit Blaulicht sind zum Beispiel: </p>
                <ul>
                  <li>
                    <p>Feuerwehr</p>
                  </li>
                  <li><p>Polizei</p></li>
                  <li>
                    <p>Krankenwagen</p>
                  </li>
                </ul>
              </li>
            </ul>
            <p>Hörst Du eine Sirene? </p>
            <p>Dann bleibe vor dem Bordstein stehen.</p>
            <p>Warte, bis die Fahrzeuge mit Blaulicht vorbeigefahren sind.</p>

          </Col>
          <Col md="4">
            <div className={styles.untereinander}>
              <Image src={require('../../images/leichte-sprache/blaulicht.jpg')} alt="" width="228" height="246" />
              <Image src={require('../../images/leichte-sprache/halt.jpg')} alt="" width="352" height="317" />
            </div>

          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Mehr Informationen</h2>
            <p>Es gibt noch mehr Informationen zu diesem Thema. </p>
            <p>Zum Beispiel in dem Info-Heft von dem </p>
            <p>Deutschen Verkehrs-Sicherheits-Rat.</p>
            <p>Die Broschüre heißt: Kinder zu Fuß unterwegs.</p>
            <p>Das Info-Heft können Sie im Internet anschauen.</p>
            <p>Das ist die Internet-Adresse:</p>
            <p>
              <a href="https://www.dvr.de/kind-und-verkehr-infobild/szenen/szene-14/Fussgaenger_Leichte_Sprache.pdf " target="_blank" rel="noreferrer">https://www.dvr.de/kind-und-verkehr-infobild/szenen/szene-14/Fussgaenger_Leichte_Sprache.pdf </a>
            </p>

          </Col>
          <Col md="4">

          </Col>

        </Row>

        <Row className={styles.zeile}>
          <Col md="8">
            <h2>Über diese Internet-Seite</h2>
            <p>Der Schulweg-Trainer ist eine Internet-Seite.</p>
            <p>Die Internet-Seite ist von </p>
            <ul>
              <li>
                <p>der Landesverkehrswacht Baden-Württemberg </p>
              </li>
              <li>
                <p>der Unfallkasse Baden-Württemberg</p>
              </li>
            </ul>
            <p>Auf der Internet-Seite sind viele Informationen:</p>
            <p>Zum Beispiel Texte und Videos.</p>
            <p>Die Internet-Seite ist für Erwachsene.</p>
            <p>Die Erwachsenen können auf der Internet-Seite sehen:</p>
            <ul>
              <li>
                <p>So kann ich meinem Kind helfen sicher in die Schule zu kommen.</p>
              </li>
              <li>
                <p>So kann ich mit meinem Kind den Schulweg üben.</p>
              </li>
            </ul>
            <p>Das ist besonders dann wichtig, </p>
            <p>wenn das Kind neu in die Schule kommt. </p>
            <p>Haben Sie Fragen zu der Internet-Seite? </p>
            <p>Dann können Sie  sich hier hin wenden:<br /><a href="mailto:landesverkehrswacht@lvw-bw.de">landesverkehrswacht@lvw-bw.de</a></p>

          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/kontakt.jpg')} alt="" width="363" height="454" />
          </Col>

        </Row>

        <Row className={styles.kleiner}>
          <Col md="8">
            <p>Das <strong>Kompetenz-Zentrum Leichte Sprache</strong></p>
            <p>hat den Text im Jahr 2023 in Leichte Sprache übersetzt.</p>
            <p>
              Dudweilerstraße 72<br />
              66 111 Saarbrücken<br />
              0 681 93 62 15 05<br />
              <a href="http://www.leicht-sprechen.de" target="_blank" rel="noreferrer">www.leicht-sprechen.de</a>
            </p>
          </Col>
          <Col md="4">
            <Image src={require('../../images/leichte-sprache/logo_reha.jpg')} alt="" width="614" height="245" />
          </Col>


        </Row>

        <Row className={styles.kleiner}>
          <Col md="9">
            <p>
              <strong>Mitglied im Netzwerk Leichte Sprache</strong>
            </p>
            <Image src={require('../../images/leichte-sprache/netzwerk_leichte-sprache.jpg')} alt="" width="232" height="68" />
            <p>
              Die <strong>Prüfgruppe von der reha gmbh in Saarbrücken</strong> hat den Text geprüft.
            </p>
            <p>
              <strong>Die Zeichnungen sind von:</strong><br />
              &copy; Lebenshilfe für Menschen mit geistiger Behinderung Bremen e.V.<br />
              Der Zeichner ist Stefan Albers, Atelier Fleetinsel, 2013
            </p>
            <div className={styles.nebeneinander}>
              <p>
                <strong>Das Europäische Zeichen für Leichte Sprache:</strong><br />
                &copy; European Easy-to-Read Logo: Inclusion Europe.<br />
                Mehr Informationen unter <a href="http://www.inclusion-europe.eu/easy-to-read" target="_blank" rel="noreferrer">www.inclusion-europe.eu/easy-to-read</a>
              </p>
              <Image src={require('../../images/leichte-sprache/leichte_sprache_geprueft.jpg')} alt="" width="108" height="107" />
            </div>
            <p>
              Wir schreiben in dem Text nur die männliche Form.<br />
              Zum Beispiel: Autofahrer.<br />
              Weil das leichter zu lesen ist.<br />
              Gemeint sind aber immer auch alle Menschen.
            </p>

          </Col>

        </Row>

      </Container>
    </div>
  );
}

export default LeichteSprache;