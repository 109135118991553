import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageHeader from '../Parents/PageHeader';

const imgAuthors = [
  "fefufoto @ stock.adobe.com",
  "Natallia Vintsik @ stock.adobe.com",
  "Brian Jackson @ stock.adobe.com",
  "hanohiki @ stock.adobe.com<br />MicroOne @ stock.adobe.com<br />fafarumba @ stock.adobe.com",
  "nmann77 @ stock.adobe.com",
  "Drobot Dean @ stock.adobe.com",
  "kaliantye @ stock.adobe.com",
  "Pavlo Plakhotia @ stock.adobe.com",
  "s-motive @ stock.adobe.com",
  "Saklakova @ stock.adobe.com",
  "Zelma @ stock.adobe.com",
  "U. J. Alexander @ stock.adobe.com",
  "Konstantin Yuganov @ stock.adobe.com",
  "Rafael Ben-Ari @ stock.adobe.com",
  "Ievgen Skrypko @ stock.adobe.com",
  "luismolinero @ stock.adobe.com",
  "Konstantin Yuganov @ stock.adobe.com",
  "Liaurinko @ stock.adobe.com",
  "Drobot Dean @ stock.adobe.com"
]

const Impressum = (props) => {
  return (
    <div>
      <PageHeader path={props.path} headerart="service" />
      <Container>
        <Row>
          <Col lg="9">
            <h1>Impressum</h1>

            <h2>Angaben gemäß § 5 TMG</h2>
            <p>
              Landesverkehrswacht Baden-Württemberg e. V.<br />
              Kesselstraße 38<br />
              70327 Stuttgart
            </p>
            <p>
              Vereinsregister: VR 500<br />
              Registergericht: Amtsgericht Stuttgart
            </p>

            <h3>Vertreten durch:</h3>
            <p>
              Burkhard Metzger<br />
              Markus Geistler<br />
              Willi Streit<br />
              Karl Müller<br />
              Joachim Arnold<br />
              Steffen Eisenbeiß
            </p>
            <h2>Kontakt</h2>
            <p>
              Telefon: 07114070300<br />
              E-Mail: <a href="mailto:landesverkehrswacht@lvw-bw.de">landesverkehrswacht@lvw-bw.de</a>
            </p>
            <h2>Redaktionell verantwortlich</h2>
            <p>
              Dieter Speiser<br/>
              Viktoria Jerke
            </p>
            <h2>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            <h2>Haftungshinweis</h2>
            <p>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>

            <h2>Copyright</h2>
            <p>
              Inhalt und Design sind urheberrechtlich geschützt. Es ist untersagt, Bildmaterial, Texte und Adressinformationen an Dritte zu vertreiben, vermieten oder in sonstiger Weise zu überlassen.
            </p>



            <h2>Realisierung</h2>
            <p>
              Jedermann-Verlag GmbH<br/>
              Mittelgewannweg 15<br/>
              69123 Heidelberg
            </p>
            <h2>Filmproduktion</h2>
            <p>
              Marvin Bosch MALIX<br />
              Soldnerstraße 1<br />
              68219 Mannheim          
            </p>


            <h2>Bei inhaltlichen und fachlichen Fragen zum Schulwegtrainer wenden Sie sich bitte an:</h2>
            <p>
              <a href="mailto:landesverkehrswacht@lvw-bw.de">landesverkehrswacht@lvw-bw.de</a>
            </p>            
            
            <h2>Bei technischen Fragen zum Schulwegtrainer wenden Sie sich bitte an:</h2>
            <p>
              <a href="mailto:support@jedermann.de">support@jedermann.de</a>
            </p>

            <h2>Bildnachweis</h2>
            {imgAuthors.map((item, index)=>{
              return(
                <Row className='my-3'>
                  <Col><img src={require("../../images/thumbnails/thumbnail"+(index+1)+".jpg")} alt="" className='bildnachweis'/></Col>
                  <Col className='d-flex align-items-center'><p className='my-1' dangerouslySetInnerHTML={{__html:item}}></p></Col>
                </Row>
              )
            })}
            <p>Alle anderen Bilder und Videos: Jedermann-Verlag GmbH</p>
          </Col> 
        </Row>
      </Container>
    </div>
  );
}

export default Impressum;