import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import styles from './SubNaviFooter.module.scss'

const SubNaviFooter = (props) => {
  return (
    <Navbar bg={(props.burgermenu) ? "" : "primary"} className={(props.burgermenu) ? styles.nopadding : ""}>
      <Container className={(props.burgermenu) ? styles.nopadding : ''}>
          <Nav className={styles.footernav}>
            <ul className={(props.burgermenu) ? styles.nomargin : ''}>
            <li>
                <Link className={(props.section.includes("/projektpartner")) ? 'aktiv nav-link' : 'nav-link'} to="/projektpartner"  onClick={(props.burgermenu) ? props.onClick : ""}>Projektpartner</Link>                           
              </li>  
              <li>
                <Link className={(props.section.includes("/impressum")) ? 'aktiv nav-link' : 'nav-link'}  to="/impressum"  onClick={(props.burgermenu) ? props.onClick : ""}>Impressum</Link>                           
              </li>
              <li>
                <Link className={(props.section.includes("/datenschutz")) ? 'aktiv nav-link' : 'nav-link'}  to="/datenschutz"  onClick={(props.burgermenu) ? props.onClick : ""}>Datenschutz</Link>                           
              </li>  
              <li>
                <Link className={(props.section.includes("/barrierefreiheit")) ? 'aktiv nav-link' : 'nav-link'}  to="/barrierefreiheit"  onClick={(props.burgermenu) ? props.onClick : ""}>Erklärung zur Barrierefreiheit</Link>                           
              </li>    
              <li>
                <Link className={(props.section.includes("/leichte-sprache")) ? 'aktiv nav-link' : 'nav-link'}  to="/leichte-sprache"  onClick={(props.burgermenu) ? props.onClick : ""}>Leichte Sprache</Link>                           
              </li>
              <li>
                <Link className={(props.section.includes("/offlineversion")) ? 'aktiv nav-link' : 'nav-link'}  to="/offlineversion"  onClick={(props.burgermenu) ? props.onClick : ""}>Offline Version</Link>                           
              </li>
            </ul>
          </Nav>
      </Container>
    </Navbar>
  );
}

export default SubNaviFooter;