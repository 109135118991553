import React, { useEffect, useRef, useState } from 'react'
import styles from './SlideArea.module.scss'
import NextButton from '../../../components/NextButton'

const SlideArea = (props) => {
    const [scrollX, setScrollX] = useState(0)
    const [scrollMax, setScrollMax] = useState(0)

    const className = (props.className !== undefined) ? props.className : "";
    const id = (props.id !== undefined) ? props.id : "";
    const scrollStep = 100;

    const sliderRef = useRef(null);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleResize = () => {
        setScrollMax(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)
    }

    useEffect(() => {
        if(props.reset){
            setScrollX(0)
            props.setReset(false)
        }
    }, [props, props.reset])

    useEffect(() => {
        if (sliderRef.current) {
            let container = sliderRef.current;
            // if(scrollMax === 0) setScrollX((sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)/2)
            setScrollMax(sliderRef.current.scrollWidth - sliderRef.current.offsetWidth)
            container.scrollTo(scrollX, "smooth")
        }
    }, [sliderRef, scrollX, scrollMax])

    const scrollLeft = () => {
        if((scrollX-2*scrollStep) >= 0){
            setScrollX(scrollX-scrollStep)
        }
        else{
            setScrollX(0)
        }
    }

    const scrollRight = () => {
        let maxScroll = sliderRef.current.scrollWidth - sliderRef.current.offsetWidth;
        if((scrollX+2*scrollStep) <= maxScroll){
            setScrollX(scrollX+scrollStep)
        }
        else{
            setScrollX(maxScroll)
        }
    }

    const setScroll = (e) => {
        setScrollX(e.target.scrollLeft)
    }

    let slideAreaClassNames = () => {
        let classNames = styles.slideArea;
            if(scrollX-scrollStep/4 > 0){
                classNames+=" "+styles.scrollLeft
            }
            if(scrollX+scrollStep/4 < scrollMax) {
                classNames+=" "+styles.scrollRight
            }
            if(props.activeRow){
                classNames+=" "+styles.activeRow
            }
        return classNames
    }

    return (
        <div 
            className={slideAreaClassNames()} id={id}>
            <NextButton direction="left" className={styles.slideBtn+" "+styles.left} onClick={scrollLeft} />
                <div className={(props.activeRow) ? styles.slideAreaRow+" "+className+" "+styles.activeRow : styles.slideAreaRow+" "+className} onScroll={setScroll} ref={sliderRef}>
                    {props.children}
                </div>
            <NextButton direction="right" className={styles.slideBtn+" "+styles.right} onClick={scrollRight} />
        </div>
    )
}

export default SlideArea