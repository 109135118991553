import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsWifi, BsWifiOff } from 'react-icons/bs'

const NetworkBar = (props) => {
    const [netBarActive, setNetBarAtive] = useState(false)
    const [offline, setOffline] = useState(false)

    useEffect(() => {
        if(!props.online) {
            setOffline(true)
            setNetBarAtive(true)
            setTimeout(() => {
                setNetBarAtive(false)
            }, 2000);
        }
        else{
            if(offline){
                setNetBarAtive(true)
                setTimeout(() => {
                    setNetBarAtive(false)
                }, 2000);
            }
        }
    }, [props.online, offline])

    useEffect(() => {
      if(!props.online && props.offlineMode) window.location.reload()
    }, [props.online, props.offlineMode])

    return (
        <div 
            className={(props.online) ? 'fixed-bottom w-100 bg-success' : 'fixed-bottom w-100 bg-danger'} 
            style={{transition: "500ms", zIndex: (netBarActive) ? "100" : "-1", bottom: (netBarActive) ? "0" : "-100%"}}>
            <Container>
                <Row>
                    <Col className='d-flex align-items-center justify-content-center py-1'>
                        {(props.online) ? <BsWifi size={21} className='me-3' /> : <BsWifiOff size={21} className='me-2' />}
                        {(props.online) ? "Online" : "Offline Modus"}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default NetworkBar