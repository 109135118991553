import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import PageHeader from './PageHeader';
import styles from './Eltern.module.scss';
import ButtonNew from '../../components/ButtonNew';
import elternbild from '../../images/eltern.jpg'



const Eltern = (props) => {
  return (
    <div>
      <PageHeader path={props.path} />
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>Unterstützung für Groß und Klein</h1>
            <p className='intro'>
              Die ersten Schritte auf dem Schulweg sind für Kinder und Eltern besonders herausfordernd. Eltern sind oft unsicher, ob sie ihr Kind allein zu Fuß in die Schule schicken sollen.
            </p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Einer der Gründe ist, dass der Straßenverkehr zugenommen hat - insbesondere vor Schulen und anderen Kindertageseinrichtungen. Verstärkt wird diese Entwicklung auch durch sogenannte Elterntaxis, die Kinder auf ihrem Schulweg zusätzlich gefährden.
            </p>
            <p>
              Laut der Verkehrsunfallstatistik verunglücken Kinder zwischen sechs und 15 Jahren am häufigsten auf ihrem Schulweg. Aber über ein Drittel davon saß in einem Auto und nur ein Fünftel der Kinder war zu Fuß unterwegs.
            </p>
            <p>
              Der Schulweg zu Fuß ist für Kinder nach wie vor die sicherste Variante - vorausgesetzt sie kennen die wichtigsten Regeln im Straßenverkehr. Dabei helfen unsere Clips und Tipps in allen Verkehrssituationen.
            </p>

          </Col>
          <Col md="6"  >
            <div className={styles.relativ}>
              <Image fluid src={elternbild} className="mobileabstandunten" alt=""/>
              <div className={styles.buttonbild}>
                <ButtonNew art="buttonplay" haupttext="So geht's" subtext="Direkt zu den Videos" pfad="/eltern/top-themen" />
                <ButtonNew art="buttoninfo" haupttext="Gut zu wissen" subtext="Wichtig und nützlich" pfad="/eltern/gut-zu-wissen" />
              </div>
            </div>


          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default Eltern;