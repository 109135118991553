import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const Einsatzfahrzeuge = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Die meisten Kinder lernen „blind“ auf eine grüne Ampel zu vertrauen. Es gibt jedoch Fälle, in denen Fußgänger auch bei einer grünen Ampel nicht über die Straße gehen dürfen.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Wenn Einsatzfahrzeuge mit Blaulicht und Sirene unterwegs sind, dürfen sie über jede für den Verkehr rote Ampel fahren. Für alle Fußgängerinnen und Fußgänger heißt es dann: stehenbleiben, auch wenn die Ampel grün ist.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                Verhalten bei Blaulicht und Sirene:
              </h2>
              <ul className='green_circle'>
                <li>
                  Lass die Polizei, die Feuerwehr oder einen Krankenwagen mit Blaulicht und Sirene immer vorbeifahren, auch wenn deine Ampel grün ist.
                </li>
                <li>
                  Wenn du eine Sirene hörst, bleib vor dem Bordstein stehen.
                </li>
                <li>
                  Warte, bis alle Einsatzfahrzeuge vorbeigefahren sind.
                </li>
              </ul>

            </Col>
          </Row>
        </Container>
      </Container>
    </div>

  )
}

export default Einsatzfahrzeuge