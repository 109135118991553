import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import PageHeader from '../Parents/PageHeader';
import logo_lvw from '../../images/logo_lvw.svg';
import logo_ukbw from '../../images/logo_ukbw.svg'

const Projektpartner = (props) => {
  return (
    <div>
      <PageHeader path={props.path} headerart="service" />
      <Container>
      <Row>
        <Col lg="9">
          <h1 className='magenta'>Projektpartner</h1>
        </Col>
      </Row>      
      <Row>
        <Col lg="9">
          <h2>Landesverkehrswacht Baden-Württemberg e.V.</h2>
          </Col>
      </Row> 
      <Row>
        <Col lg="9">           

          <p>
            Die Landesverkehrswacht Baden-Württemberg e.V. ist der Interessensvertretung der 55 Verkehrswachten im Bundesland. Das gemeinsame Ziel: Allen Bürgerinnen und Bürgern sichere Wege durch den Straßenverkehr aufzeigen. Unsere Projekte, Kampagnen und Veranstaltungen wirken direkt vor Ort, im heimischen Kindergarten oder in der Grundschule, auf nahe gelegenen Verkehrsübungsplätzen oder bei Infoveranstaltungen in der Region.             
          </p>
          <p className='ohneabstand'>
            Unsere Aufgaben:
          </p>
          <ul>
            <li>
              Wir vertreten die Interessen aller Verkehrsteilnehmenden.
            </li>
            <li>
              Wir klären alle Zielgruppen über Verkehrssicherheit auf: durch Schulwegaktionen, durch die Radausbildung, mit Fahrsicherheitstrainings oder Pedelec-Trainings usw.
            </li>
            <li>
              Wir arbeiten mit Behörden und anderen offiziellen Stellen im Bereich Verkehrssicherheit zusammen.
            </li>
          </ul>
          <p>
            Weitere Informationen unter <a href="https://www.verkehrswacht-bw.de/" target="_blank" rel="noreferrer">www.verkehrswacht-bw.de</a>
          </p>          
        </Col>
        <Col lg="3">
        <a href="https://www.verkehrswacht-bw.de/" target="_blank" rel="noreferrer"><Image src={logo_lvw} width="625" height="352" style={{width: "calc(100% - 2rem)", height: "auto", marginLeft: "2rem"}} alt="Logo der Landesverkehrswacht Baden-Württemberg" /></a>

        </Col>        

      </Row>
      <Row style={{marginTop: "2rem"}}>
        <Col lg="9">
          <h2>Unfallkasse Baden-Württemberg </h2>
          </Col>        
      </Row>          
      <Row>
        <Col lg="9">
          <p>
            Die Unfallkasse Baden-Württemberg ist mit über vier Millionen Versicherten einer der größten Unfallversicherungsträger im Kommunal- und Landesbereich in Deutschland. Beschäftigte einer Kommune oder beim Land Baden-Württemberg sind während ihrer Arbeit und auf dem Weg dorthin bzw. wieder zurück bei der UKBW gesetzlich unfallversichert. Auch Schüler, Kitakinder, Studierende oder Mitglieder der Freiwilligen Feuerwehr sind Versicherte bei der UKBW. Hierzu bedarf es keiner Anmeldung oder Beitragszahlung von Versichertenseite. Die Versicherung erfolgt durch die Tätigkeitsausübung. Darüber hinaus engagiert sich die UKBW im Besonderen für gesunde Schülerinnen und Schüler, einen sicheren Schulweg und eine gesunde Lernumgebung.
          </p>
          <p>
            Weitere Informationen unter <a href="https://www.ukbw.de" target="_blank" rel="noreferrer">www.ukbw.de</a>
          </p>
        </Col>
        <Col lg="3">
        <a href="https://www.ukbw.de" target="_blank" rel="noreferrer"><Image src={logo_ukbw} width="163" height="60" style={{width: "calc(100% - 2rem)", height: "auto", marginLeft: "2rem"}} alt="Logo der Unfallkasse Baden-Württemberg" /></a>

        </Col>        
      </Row>
      </Container>
    </div>
  );
}

export default Projektpartner;