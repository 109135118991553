import React, { useEffect, useState } from 'react';
import styles from './AskForOfflineMode.module.scss';
import { VideoData } from '../data/data'
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { AiOutlineLoading, AiFillCheckCircle, AiOutlineDownload, AiOutlineDelete } from 'react-icons/ai';

const AskForOfflineMode = (props) => {

    const [downloadStarted, setDownloadStarted] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState([])
    const [progress, setProgress] = useState(0)
    const [storagePercentageUsed, setStoragePercentageUsed] = useState(null)
    const [storageRemaining, setStorageRemaining] = useState(null)   
    const [downloaded, setDownloaded] = useState([])
    const [videos, setVideos] = useState(()=>{
        let arr = [];
        for (let i = 0; i < VideoData.length; i++) {
            arr.push("/video/"+VideoData[i].video);
        }
        for (let j = 0; j < VideoData.length; j++) {
            arr.push("/video/film_"+(j+1)+"_cc.vtt")
            
        }
        arr.push("/video/collage.mp4");
        return arr;
    })

    useEffect(()=>{
        async function getCache(){        
            for (let i = 0; i < videos.length; i++) {
                let video = videos[i];
                // open cache
                caches.open('videos')
                .then((cache) => {
                    cache.keys(video)
                    .then((resp)=>{
                        if(resp.length > 0){
                            let url = '/video/'+resp[0].url.split('/video/')[1];
                            if(url === video && !downloaded.includes(video)) setDownloaded((dl)=>[...dl, video]);
                        }
                    })
                    .catch(e => {
                        console.error("error checking cache of "+video+" error: "+e);
                    })
                })
            }
            return true;
        }
        if(downloaded.length === 0){
            getCache();
        }
    },[videos, downloaded])

    useEffect(()=>{
        if(videos.length !== 0 && downloaded.length === videos.length){
            setDownloadStarted(true)
            setSuccess(true)
        }
    }, [downloaded, videos])

    async function getStorageEstimate() {
        if (navigator.storage && navigator.storage.estimate) {
            const quota = await navigator.storage.estimate();
            // quota.usage -> Number of bytes used.
            // quota.quota -> Maximum number of bytes available.
            const percentageUsed = (quota.usage / quota.quota) * 100;
            setStoragePercentageUsed(percentageUsed)
            const remaining = quota.quota - quota.usage;
            setStorageRemaining(remaining)
        }
    }
    
    useEffect(()=>{
        if(videos.length > 0){
            if(videos.length === progress && error.length === 0){
                setSuccess(true)
                props.setOfflineMode(true)
                props.toStats("http://localhost:3000/offline-count/")
                // props.toStats("https://www.schulwegtrainer.de/offline-count/")
            }
            else if(error.length > 0){
                setTimeout(() => {
                    saveVideos(error)
                }, 1000);
            }
        }
    }, [progress, error, videos.length, props])

    const handleSaveVideos = () => {
        if(videos.length > 0){
            saveVideos(videos)
        }
        else{
            console.error("save failed, data empty")
        }
    }

    async function saveVideos(videos) {
        setDownloadStarted(true)
        // for each video to cache
        for (let i = 0; i < videos.length; i++) {
            let video = videos[i];
            // open cache
            caches.open('videos')
            .then((cache) => {
                cache.add(video)
                .then(()=>{
                    setTimeout(() => {
                        setProgress((p)=> p+1)
                    }, 500);
                })
                .catch(e => {
                    console.error(e)
                    setError((err) => [...err, video])
                })
            })
        }
    }

    async function deleteVideos(){
        for (let i = 0; i < videos.length; i++) {
            let video = videos[i];
            // open cache
            caches.open('videos')
            .then((cache) => {
                cache.delete(video)
                .catch(e => {
                    console.error("error deleting cache "+video+" error: "+e)
                })
            })
        }
        setSuccess(false)
        setDownloadStarted(false)
    }

    getStorageEstimate()

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Den Schulwegtrainer auch offline nutzen </h1>
                    <p>Wir haben den Schulwegtrainer als sogenannten Progressive Web App (PWA) programmiert um die Vorteile von Webseiten mit denen nativer Apps zu verbinden. Die Unterstützung von PWAs hängt von dem verwendeten Browser ab. Chromium-basierte Browser, wie zum Beispiel „Chrome“ und „Edge“ decken bereits jetzt einen großen Teil der möglichen Funktionalitäten ab. Bei „Firefox“ und „Safari“ rechnen wir mit rasch zunehmender Funktionalität.</p>
                    <p>Damit Sie die Seiten des Schulwegtrainers auch dann lesen können, wenn Sie gerade keinen Zugriff aufs Internet haben, speichert die App grundlegende Dateien in den temporären Speicher des Browsers. Dies funktioniert nicht, wenn Sie in „privatem“ oder „Inkognito“ Modus surfen. In diesen Fällen können keine Daten gespeichert werden.</p>
                    <p>Größere Inhalte, wie die Videos, werden nur mit Ihrer Zustimmung gespeichert. Wir empfehlen, den Download bei einer bestehenden WLAN-Verbindung vorzunehmen.</p>
                    <p><i>Hinweis: Die Videos sind nur in dem Browser verfügbar, mit dem sie auch gespeichert wurden. Wenn Sie im Browser die temporären Daten löschen, löschen Sie alle Daten des Schulwegtrainers inklusive der Videos.</i></p>
                </Col>
            </Row>
            {(!downloadStarted) ? 
                <Row>
                    <Col>
                        <p className='mb-1'>Download Größe: 105 MB</p>
                        {/* {(storageRemaining !== null && storagePercentageUsed !== null) && <p className='mb-3'>Verfügbarer Speicherplatz auf Ihrem Gerät: ~{formatBytes(storageRemaining)} / {Math.round(storagePercentageUsed * 100)/100}&nbsp;% Browser-Speicher belegt</p>} */}
                        <div className='d-flex justify-content-between'>
                            <Button variant='primary' onClick={handleSaveVideos} className={styles.downloadBtn}><AiOutlineDownload size={24} className='me-2'/>Offline-Inhalte herunterladen</Button>
                        </div>
                    </Col>
                </Row>
            : (downloadStarted && error.length > 0) ? 
                <Row>
                    <Col>
                        <span className='d-flex text-danger'>Ein Fehler ist aufgetreten. Bitte laden Sie die Seite noch einmal und versuchen Sie es erneut.</span>
                    </Col>
                </Row>
            : (success) ?
                <Row className='align-items-center'>
                    <Col>
                        <span className='d-flex align-items-center'><AiFillCheckCircle size={24} className='me-2 text-success'/>Die Daten wurden heruntergeladen.</span>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <Button variant='danger' onClick={deleteVideos} className={styles.downloadBtn}><AiOutlineDelete size={24} className='me-2'/>Offline-Inhalte löschen</Button>
                    </Col>
                </Row>
            : 
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <p className='mb-2'>Download Fortschritt:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ProgressBar now={progress} max={videos.length} />
                                <p className='m-2 text-'><AiOutlineLoading size={24} className='me-2' style={{animation: "spin 2s infinite"}} /><small>Inhalte werden heruntergeladen, bitte warten ...</small></p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </Container>
        // <Toast className={styles.askOffline} bg='light'>
        //   <Toast.Header>
        //     <strong className="me-auto">Inhalte herunterladen</strong>
        //     <small>XXX MB</small>
        //   </Toast.Header>
        //         {(!downloadStarted) ? 
        //         <Toast.Body>
        //             Den Schulwegtrainer auch offline nutzen
        //             <div className='d-flex mt-2 justify-content-between'>
        //                 <Button variant='primary' onClick={saveVideos} className={styles.downloadBtn}>Download</Button>
        //                 <Button variant='muted' data-dismiss="toast">Schließen</Button>
        //             </div>
        //         </Toast.Body>
        //         : (downloadStarted && error) ? 
        //         <Toast.Body>
        //             <span className='d-flex text-danger'>Ein Fehler ist aufgetreten. Bitte laden Sie die Seite noch einmal und versuchen Sie es erneut.</span>
        //         </Toast.Body>
        //         : (success) ?
        //         <Toast.Body>
        //             <span className='d-flex text-success'><AiFillCheckCircle className='me-2'/>Daten wurden heruntergeladen.</span>
        //         </Toast.Body>
        //         : 
        //         <Toast.Body>
        //             <div className='loading-animation' style={{width: "48px", height: "48px", margin: "1rem auto"}}>
        //                 <AiOutlineLoading className='spinner-icon'/>
        //                 <span className='sr-only'>Daten werden geladen ...</span>
        //             </div>
        //         </Toast.Body>
        //         }
        // </Toast>
    )
}

export default AskForOfflineMode

function formatBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return`${parseFloat((a/Math.pow(1024,d)).toFixed(c))} ${["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}`}
