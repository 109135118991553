import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const StrasseUeberqueren = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Wenn Kinder auf ihrem Schulweg eine Straße überqueren müssen, bereitet das den Eltern am meisten Sorgen. Insbesondere an Übergängen, an denen weder eine Ampel noch ein Zebrastreifen den Verkehr regulieren, müssen Schulkinder besonders aufmerksam sein.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Zum einen können sie sich schlechter als Erwachsene orientieren, zum anderen neigen sie dazu, über die Straße zu rennen. Das erhöht die Sturzgefahr. Eine Straße ohne Ampel oder Zebrastreifen zu überqueren, ist für jüngere Schulkinder eine große Herausforderung und sollte mit Erwachsenen entsprechend intensiv geübt werden.
            </p>

          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt=""/>

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                Mit diesen Tipps können Kinder eine Straße sicher überqueren:
              </h2>
              <ul className='green_circle'>
                <li>
                  Wenn es keine Ampel oder keinen Zebrastreifen gibt, kannst du dort über die Straße gehen, wo du möglichst viel von der Straße sehen kannst.
                </li>
                <li>
                  Halte immer am Bordsteinrand, damit du Zeit hast, die Straße und alle Fahrzeuge zu überblicken.
                </li>
                <li>
                  Schaue nach links, rechts, links.
                </li>
                <li>
                  Gehe über die Straße nur, wenn alles frei ist.
                </li>
                <li>
                  Gehe nur über die Ampel, wenn diese grün ist.
                </li>
                <li>
                  Gehe erst über den Zebrastreifen, wenn alle Autos angehalten haben.
                </li>
                <li>
                  Und: Renne nicht über die Straße, sondern geh zügig und geradewegs über die Straße.
                </li>
              </ul>

            </Col>
          </Row>
        </Container>
      </Container>
    </div>


  )
}

export default StrasseUeberqueren