import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import Daumenhoch from '../../../components/Daumenhoch'


const OhneElterntaxi = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Das Elterntaxi ist oft nur für die fahrenden Erwachsenen ein Vorteil. Für Kinder kann das Fahren in die Schule sogar nachteilig sein: Sie lernen auf diese Weise nicht, wie sie sich sicher im Straßenverkehr verhalten können.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Die Angst der Eltern vor dem Schulweg schadet den Kindern dann mehr als viele annehmen. Dabei ist der Schulweg zu Fuß eine sichere und gesunde Alternative zum Auto.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt="" />

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                So vermeiden Sie das Elterntaxi:
              </h2>
              <ul className='green_circle'>
                <li>
                  Üben Sie mit Ihrem Kind die Verhaltensregeln für einen sicheren Schulweg.
                </li>
                <li>
                  Wenn Ihr Kind alle Verkehrsregeln beherrscht, lassen Sie es möglichst oft allein den Schulweg gehen.
                </li>
                <li>
                  Eine Alternative zum Elterntaxi: Bilden Sie einen so genannten Laufbus. Dabei werden die jungen Schulkinder abwechselnd von einem Elternteil zur Schule und nach Hause begleitet.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>


  )
}

export default OhneElterntaxi