import React from 'react'
import ImageToLoad from '../../../components/ImageToLoad'
import styles from './Overview.module.scss'
import { BsEmojiSmileFill, BsArrowRepeat, BsEmojiFrownFill, BsQuestionLg } from 'react-icons/bs'

export const Overview = (props) => {
    
    const handleClick = () => {
        setTimeout(() => {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});             
        }, 10);
    }

    // console.log(props.userData)

    return (
        <div className={styles.tileWrapper}>
            {/* <h2 className='d-flex w-100 my-3 justify-content-center'>Deine Antworten</h2> */}
            <div className={styles.tiles}>
                {props.userData.map((item, index) => {
                    let classVar = item.correctAnswer;
                    return(
                        <div 
                            key={index} 
                            className={(index === props.currentImg) ? styles.tile+" "+styles.active+" "+styles[classVar] : styles.tile+" "+styles[classVar]} 
                            onClick={(item.correctAnswer === "correct") ? handleClick : (item.correctAnswer === "false") ? ()=>props.goToImg(index) : null}>
                                <div className={styles.tileText}>
                                    {
                                        (item.correctAnswer === "correct") ? <BsEmojiSmileFill aria-hidden={true} /> : 
                                        (item.correctAnswer === "false") ? <BsEmojiFrownFill aria-hidden={true} /> : ""
                                    }
                                </div>
                                {
                                    (item.correctAnswer === "correct") && 
                                    <ImageToLoad src={require('../../../images/richtig-falsch/'+item.img.correct)} alt="" className={styles.img} />
                                }
                                {
                                    (item.correctAnswer === "false") && 
                                    <BsArrowRepeat aria-hidden={true} />
                                }
                                {
                                    (item.correctAnswer === "none") && 
                                    <BsQuestionLg aria-hidden={true} />
                                }
                        </div>
                    )
                })}
                
            </div>
        </div>
    )
}
