import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PageHeader from './PageHeader';
import styles from './FAQ.module.scss';
import { Link } from 'react-router-dom';

const FAQ = (props) => {
  useEffect(() => {
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();
    }, 100);
  }, []);

  function toggleNew(e) {
    let parentel = e.currentTarget.parentNode;
    let expanded = e.currentTarget.getAttribute("aria-expanded");
    e.currentTarget.parentNode.classList.toggle(styles.faqactive);
    parentel.scrollIntoView({behavior:"smooth", block: "nearest", inline:"nearest"});

    if (expanded === "false") {
      e.currentTarget.setAttribute("aria-expanded", "true");
    } else {
      e.currentTarget.setAttribute("aria-expanded", "false");
    }
  }

  function toggleFAQByKey(e) {
    let tastenCode;
    if (e.keyCode) {
      tastenCode = e.keyCode; /* IE */
    }
    else if (e.which) {
      tastenCode = e.which; /* Moz, Saf, Op */
    }
    if (tastenCode !== 13 && tastenCode !== 32) {
      return true;
    } else {
      let parentel = e.currentTarget.parentNode;
      e.currentTarget.parentNode.classList.toggle(styles.faqactive);
      parentel.scrollIntoView({behavior:"smooth", block: "nearest", inline:"nearest"});     
    }
  }


  return (
    <div>
      <PageHeader path={props.path} />
      <Container>
        <h1 className='magenta'>Sicherheit auf dem Schulweg</h1>
        <h2>Häufig gestellte Fragen</h2>

        <p>Die Kurzvideos des Schulwegtrainers vermitteln die wichtigsten Regeln für einen sicheren Schulweg. Erwachsene haben darüber hinaus oft noch weitere Fragen. Der Schulwegtrainer beantwortet die am häufigsten gestellten Fragen der Eltern für einen sicheren Schulweg.</p>

        <div id="faq1" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Wann sollten Eltern mit der Verkehrserziehung ihrer Kinder beginnen?</h3>

          <div>
            <p>
              Eltern und andere Erwachsene, die sich mit Kindern im Straßenverkehr bewegen, sollten so früh wie möglich mit der Verkehrserziehung beginnen. Selbst sehr junge Kinder können beispielsweise auf dem Fußweg in die Kita oder in den Kindergarten lernen, an jeder Gefahrenstelle und bei jeder ihnen unbekannten Situation stehen zu bleiben. Das ist eine der ersten Regeln im Straßenverkehr, die Kinder lernen können. Erklären Sie diese Regel auch anderen Erwachsenen, die Ihre Kinder begleiten.
            </p>
          </div>
        </div>

        <div id="faq2" className={styles.faqs} >
          <h3 className='magenta'  onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Warum können Kinder Gefahren im Straßenverkehr nicht gut erkennen?</h3>

          <div >
            <p>
              Kinder können aufgrund ihrer noch nicht abgeschlossenen körperlichen und geistigen Entwicklung viele gefährliche Situationen nicht oder nicht richtig einschätzen. Hinzu kommt, dass Kinder nicht jede gelernte Regel in jeder Verkehrssituation umsetzen können. Näheres über die kindliche Entwicklung und den Zusammenhang zum Verhalten im Straßenverkehr erfahren Sie im Menüpunkt <Link to="/eltern/gut-zu-wissen">„Gut zu wissen“</Link>.
            </p>
          </div>
        </div>

        <div id="faq3" className={styles.faqs}>
          <h3 className='magenta'  onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Was gilt als sicherer Schulweg?</h3>

          <div >
            <p>
              Einen idealtypischen Schulweg gibt es nicht. Viele Gemeinden und Städte zeigen in sogenannten Schulwegplänen optimale Wege zur Schule im jeweiligen Einzugsgebiet auf. Solche Pläne sind in der Regel über die Schule selbst oder über das Ordnungsamt verfügbar.
            </p>
            <p className="vorliste">
              Ist ein Schulwegplaner in Ihrer Gemeinde nicht verfügbar, müssen Sie als Elternteil selbst einen sicheren Schulweg festlegen. Folgende Aspekte sollten Sie dabei beachten:
            </p>
            <ul>
              <li>
                Wählen Sie möglichst einen Schulweg, bei dem Ihr Kind die gesamte Strecke auf einem Gehweg gehen kann. Ist dies nicht möglich, sollte das Kind auf dem rechten oder linken Fahrbahnrand gehen und besonders auf den Verkehr achten.
              </li>
              <li>
                Wenn Ihr Kind auf dem Schulweg eine Straße überqueren muss, sollte dies an der Ampel, an einem Zebrastreifen oder über eine Fußgängerinsel geschehen.
              </li>
              <li>
                Wenn es keine sichere Überquerungsmöglichkeit gibt: Zeigen Sie Ihrem Kind eine geeignete Stelle, an der es die Straße überqueren kann. 
              </li>
              <li>
                Wählen Sie möglichst eine Straße, die gut beleuchtet ist und auch von anderen Kindern auf dem Weg zur Schule genutzt wird. Wenn Kinder in der Gruppe laufen können, ist dies sicherer. 
              </li>
              <li>
                Ein sicherer Schulweg zeichnet sich auch dadurch aus, dass er an wenigen Garagen und anderen Ausfahrten vorbeiführt. Zeigen Sie Ihrem Kind für diese Situationen das richtige Verhalten (siehe Clip <Link to="/eltern/top-themen/ausfahrt">„Achtung, Ausfahrt!“</Link>)
              </li>
            </ul>
          </div>
        </div>

        <div id="faq4" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Ist mein Kind auf dem Schulweg versichert?</h3>

          <div >
            <p>
              Jedes Kind ist auf seinem Schulweg automatisch durch die Unfallkasse Baden-Württemberg versichert. Wenn das Kind allerdings einen Umweg nimmt, kann der Versicherungsschutz erlöschen. Halten Sie Ihr Kind daher immer an, auf dem vereinbarten Schulweg zu bleiben. Was einen sicheren Schulweg ausmacht, lesen Sie in Frage „Was gilt als sicherer Schulweg“.
            </p>
            <p>
              Für Sie als Eltern ist es beruhigend zu wissen, dass alle Schülerinnen und Schüler von der Einschulung bis zum Schulabschluss gesetzlich unfallversichert sind. Der umfassende Versicherungsschutz besteht automatisch und kostenfrei in der Schule, bei allen schulischen Veranstaltungen und auf dem Schulweg.
            </p>
            <p>
              Sie müssen dafür keine besondere Versicherung abschließen. Die Aufwendungen werden von den Kommunen und dem Land getragen.
            </p>
            <p>
              Mehr Informationen finden Sie unter <a href="https://www.ukbw.de/sicherheit-gesundheit/betriebsarten/schulen/" target="_blank" rel="noreferrer">https://www.ukbw.de/sicherheit-gesundheit/betriebsarten/schulen/</a> oder unter <Link to="/eltern/gut-zu-wissen/versicherungsschutz">Versicherungsschutz</Link> im Schulwegtrainer.
            </p>

          </div>
        </div>

        <div id="faq5" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Mein Kind muss mit dem Bus in die Schule - welche Tipps kann ich zusätzlich geben?</h3>
          <div >
            <p className="vorliste">
              Der Weg zur Bushaltestelle unterscheidet sich in der Regel nicht wesentlich vom gewöhnlichen Schulweg zu Fuß. Zusätzlich sollten Eltern ihren Kindern folgende Merksätze vermitteln:
            </p>
            <ul>
              <li>Auf dem Weg zur Bushaltestelle renne ich nicht über die Straße.</li>
              <li>Ich bleibe an der Bushaltestelle stehen, bis der Bus ganz angehalten hat.</li>
              <li>Wenn ich ausstiegen bin, warte ich, bis der Bus weitergefahren ist. Dann gehe über die Straße.</li>
            </ul>
            <p>
              Die Kampagne „Bus fahren - aber sicher!“ bietet für Schülerinnen und Schüler ab Klasse 5 eine spannende Präventionsveranstaltung an. Nähere Informationen finden Sie hier: <a href="https://bus-fahren.gib-acht-im-verkehr.de/" target="_blank" rel="noreferrer">https://bus-fahren.gib-acht-im-verkehr.de/</a>
            </p>
          </div>
        </div>


        <div id="faq6" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Wie lange muss ich den Schulweg mit meinem Kind üben?</h3>
          <div >
            <p>
              Hierbei gilt: Übung macht den Meister oder die Meisterin. Kinder lernen Regeln am besten durch Wiederholung. Trotzdem brauchen Kinder eine längere Zeit, bis sie die gelernten Regeln auf jede Situation auf ihrem Schulweg anwenden können. Die Dauer kann dabei von Kind zu Kind unterschiedlich sein. Als Eltern sollten Sie entsprechend Zeit einplanen und ihr Kind so lange begleiten, wie es nötig ist.
            </p>
          </div>
        </div>


        <div id="faq7" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Kinder können von Fremden auf der Straße angesprochen werden: Wie bereite ich mein Kind auf eine solche Situation vor?</h3>

          <div >
            <p>
              Meldungen darüber, dass Kinder auf ihrem Schulweg von fremden Erwachsenen angesprochen werden, verunsichern die meisten Eltern und erwachsenen Bezugspersonen von Kindern. Das Ansprechen von Kindern mit der Absicht, diese sexuell zu missbrauchen, ist allerdings verhältnismäßig selten. Sexualisierte Gewalt an Kindern findet zu einem Großteil in der Familie, im Bekanntenkreis oder in Einrichtungen statt.
            </p>
            <p>
              Trotzdem sollten Eltern ihre Kinder auf eine solche Situation auf dem Schulweg vorbereiten. Die bundesweite Kampagne der Polizei <a href="https://www.missbrauch-verhindern.de" target="_blank" rel="noreferrer">www.missbrauch-verhindern.de</a> gibt Kindern einfach umsetzbare Tipps für solche Situationen.
            </p>
            <p className="vorliste">
              Stärken Sie Ihr Kind:
            </p>
            <ul>
              <li>Kinder dürfen „Nein“ sagen auch Erwachsenen gegenüber. </li>
              <li>Kinder sind nicht verpflichtet mit Erwachsenen oder Jugendlichen zu sprechen. </li>
              <li>Kinder sollten immer von für sie belastbaren Situationen auf dem Schulweg berichten.</li>
            </ul>
          </div>
        </div>

        <div id="faq8" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Wir wollen Schulwegsicherheit im Elternkreis an unserer Einrichtung thematisieren: Gibt es Informationen für Schulen?</h3>

          <div >
            <p>
              In Baden-Württemberg bündelt das Landesprogramm MOVERS - Aktiv zur Schule verschiedene Bausteine, die direkt von Kommunen umgesetzt oder initiiert werden können. Nähere Informationen gibt es unter MOVERS für Kommunen - MOVERS (<a href="https://movers-bw.de" target="_blank" rel="noreferrer">movers-bw.de</a>)
            </p>
            <p>
              Darüber hinaus stehen die Verkehrswachten in Baden-Württemberg für Eltern und schulische Einrichtungen als Ansprechpartner zur Verkehrserziehung (<a href="https://www.verkehrswacht-bw.de/index.php/verkehrswachten-vor-ort" target="_blank" rel="noreferrer">https://www.verkehrswacht-bw.de/index.php/verkehrswachten-vor-ort</a> ) von Kindern zur Verfügung.
            </p>
            <p>
              Deutschlandweit gibt es Moderierende, die Eltern über das Thema „Kind und Verkehr“ in Veranstaltungen und Vorträgen informieren. Hier finden Sie Moderierende in Ihrer Nähe: Moderatoren finden (<a href="https://dvr.de" target="_blank" rel="noreferrer">dvr.de</a>)
            </p>
          </div>
        </div>

        <div id="faq9" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Gibt es weitere Materialien zum Üben des Schulwegs?</h3>
          <div >
            <p>
              Das Buch "Alles über den Straßenverkehr“ aus der Ravensburger-Reihe „Wieso, Weshalb, Warum“ ist eine gute Hilfe beim Üben der wichtigsten Regeln im Straßenverkehr. Es vermittelt spielerisch spannende Informationen und wichtige Regeln. Mit detailreichen Bildern, informativen Texten und Klappen zum Mitmachen geht es auf Entdeckungsreise durch den Straßendschungel. Kinder erfahren, wie sie sich an der Ampel richtig verhalten, warum ein Fahrradhelm wichtig ist, was sie beim Busfahren beachten müssen oder auf welchen Straßen auch Spielen erlaubt ist. Das Buch ist im Buchhandel erhältlich.
            </p>
            <p className="vorliste">
              Interessante Links zum Thema haben wir hier zusammengestellt:
            </p>
            <ul>
              <li><a href="https://www.bfu.ch/de/ratgeber/erste-schritte-im-strassenverkehr" rel="noreferrer" target="_blank">https://www.bfu.ch/de/ratgeber/erste-schritte-im-strassenverkehr</a></li>
              <li>Sicherer Schulweg - Infos zur Verkehrserziehung 2022 / 2023 (<a href="https://bussgeld-info.de" rel="noreferrer" target="_blank">bussgeld-info.de</a>)</li>
              <li>Wimmelbild zum Üben von Verhaltensweisen im Straßenverkehr: <a href="https://www.dvr.de/kind-und-verkehr-infobild/#" rel="noreferrer" target="_blank">https://www.dvr.de/kind-und-verkehr-infobild/#</a> </li>

            </ul>
          </div>
        </div>

        <div id="faq10" className={styles.faqs} >
          <h3 className='magenta' onClick={toggleNew} onKeyUp={toggleFAQByKey} tabIndex="0" role="button" aria-expanded="false">Mehr Infos für Eltern?</h3>
          <div >
            <p>
              Landesverkehrswacht Baden-Württemberg:  <a href="https://www.verkehrswacht-bw.de" rel="noreferrer" target="_blank">www.verkehrswacht-bw.de</a>
            </p>
            <p>
              Deutsche Verkehrswacht: <a href="https://www.deutsche-verkehrswacht.de" rel="noreferrer" target="_blank">www.deutsche-verkehrswacht.de</a>
            </p>
            <p>
              Elternportal der Bundeszentrale für gesundheitliche Aufklärung:  <a href="https://www.kindergesundheit-info.de" rel="noreferrer" target="_blank">www.kindergesundheit-info.de</a>
            </p>
            <p>
              Unfallforschung der Versicherer:  <a href="https://www.udv.de/udv/verkehrsteilnahme/82186/kinder" rel="noreferrer" target="_blank">https://www.udv.de/udv/verkehrsteilnahme/82186/kinder</a>
            </p>
            <p>
              Wissen rund um den Versicherungsschutz auf dem Schulweg: <a href="https://www.ukbw.de/sicherheit-gesundheit/betriebsarten/schulen/" target="_blank" rel="noreferrer">https://www.ukbw.de/sicherheit-gesundheit/betriebsarten/schulen/</a>            
            </p>          
          
          </div>   
        </div>     

      </Container>
    </div>
  );
}



export default FAQ;