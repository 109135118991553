import React from 'react';
import Button from 'react-bootstrap/Button';
import { BsXLg, BsList } from 'react-icons/bs'

const BurgerButton = (props) => { 
  return (
    <Button variant={(props.farbe === "blau") ? "transparent" : (props.section.includes("/spiele")) ? "secondary" : "primary"} onClick={props.onClick} className={"p-2 abstandoben "+ props.klasse+ " "+props.farbe} title={(props.status) ? "Menü schließen" : "Menü öffnen"}>
      {props.status ? <BsXLg size={16} color='#ffffff' /> : <BsList size={24} color='#ffffff'/>}
    </Button>
  );
}

export default BurgerButton;