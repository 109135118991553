import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Figure } from 'react-bootstrap';
import styles from './Videos.module.scss';
import { VideoData } from '../../data/data.js';
import VideoKarussell from './VideoKarussell';
import SchulwegPlanen from './videos/SchulwegPlanen';
import Verhalten from './videos/Verhalten';
import StrasseUeberqueren from './videos/StrasseUeberqueren';
import ParkendeAutos from './videos/ParkendeAutos';
import Einsatzfahrzeuge from './videos/Einsatzfahrzeuge';
import Ausfahrt from './videos/Ausfahrt';
import Gehweginnenseite from './videos/Gehweginnenseite';
import Radfahrer from './videos/Radfahrer';
import Kleidung from './videos/Kleidung';
import Elterntaxi from './videos/Elterntaxi';
import OhneElterntaxi from './videos/OhneElterntaxi';
import { useSwipeable } from 'react-swipeable';
import Video from '../../components/Video';


const Videos = (props) => {
  const currentVid = VideoData.filter(i => i.id === props.videoid);
  


  const componentNames = {
    SchulwegPlanen: SchulwegPlanen,
    Verhalten: Verhalten,
    StrasseUeberqueren: StrasseUeberqueren,
    ParkendeAutos: ParkendeAutos,
    Einsatzfahrzeuge: Einsatzfahrzeuge,
    Ausfahrt: Ausfahrt,
    Gehweginnenseite: Gehweginnenseite,
    Radfahrer: Radfahrer,
    Kleidung: Kleidung,
    Elterntaxi: Elterntaxi,
    OhneElterntaxi: OhneElterntaxi
  };

  const [videosrc, setVideosrc] = useState('/video/01_schulweg-planen.mp4');

  useEffect(() => {
    if (props.videopath) {
      setTimeout(() => {
        let navelement = document.activeElement;
        navelement.blur();
      }, 100);
      setVideosrc('/video/' + currentVid[0].video);
      
      let video = document.getElementById("videoPlayer");
      video.load();
    }
  }, [props.videopath, currentVid, videosrc]);

  const poster = require('../../images/poster/' + currentVid[0].poster);

  var SomeComponent = componentNames[currentVid[0].component];

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => swipeleft(),
    onSwipedRight: (eventData) => swiperight(),
    trackMouse: true
  });


  return (
    <div>
      <Container fluid className={styles.videohead} {...handlers} >
        <Row>
          <Col lg="7" className={styles.videocol}>
            <Figure className='embed-responsive embed-responsive-16by9'>
              <Video src={videosrc} className='video-player embed-responsive-item' id="videoPlayer" poster={poster} datei={videosrc} trackSrc={"/video/"+currentVid[0].cc} titel={currentVid[0].title} />
              <Figure.Caption></Figure.Caption>
            </Figure>
          </Col>
          <Col lg="5" className={styles.infocol}>
            <p className={styles.subhead}>{currentVid[0].subtitle}</p>
            <h1>{currentVid[0].title}</h1>
            <VideoKarussell videoid={currentVid[0].id} />

          </Col>
        </Row>
      </Container>
      <SomeComponent titel={currentVid[0].title} foto={currentVid[0].foto} />
      <Container>
        <Row>
        <Col lg="2"></Col>
          <Col lg="7">
            <div>
              <button className={styles.weiterlink} onClick={() => {swipeleft()}} >Zum nächsten Thema</button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

function swiperight() {
  let linklinks = document.getElementById("nachlinks");
  linklinks.click();

}

function swipeleft() {
  let linkrechts = document.getElementById("nachrechts");
  linkrechts.click();
}

export default Videos