export const RichtigFalschData = [
    {
        "id": "01",
        "name": "Auf dem Gehweg gehe ich auf der Kinderseite",
        "uri": "verhalten-gehweg",
        "img": {
            "correct": "Schulwegtrainer_RF_01_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_01_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Abstand zur Straße halten!",
            "text": "Gehe immer auf der Innenseite der Straße. Auf der Kinderseite ist es sicherer."
        },
        "audio": "rf_1.mp3"
    },
    {
        "id": "02",
        "name": "Laufen auf der Bordsteinkante",
        "uri": "gehweg-zirkus",
        "img": {
            "correct": "Schulwegtrainer_RF_02_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_02_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Kein Zirkus auf dem Gehweg!",
            "text": "Halte immer Abstand vom Bordstein. Denn das ist kein sicherer Ort zum Spielen."
        },
        "audio": "rf_2.mp3"
    },
    {
        "id": "03",
        "name": "Grundstückausfahrten beachten",
        "uri": "einfahrten-beachten",
        "img": {
            "correct": "Schulwegtrainer_RF_04_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_04_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Bei Einfahrten auf Autos achten.",
            "text": "Auf dem Gehweg können manchmal auch Autos fahren. Schaue bei Garagen und Einfahrten immer, ob Autos herausfahren wollen."
        },
        "audio": "rf_3.mp3"
    },
    {
        "id": "04",
        "name": "Auf dem Gehweg achte ich auf Fahrradfahrer und andere Fußgänger",
        "uri": "radweg",
        "img": {
            "correct": "Schulwegtrainer_RF_05_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_05_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Vorsicht und Rücksicht.",
            "text": "Bleib immer auf der Gehwegseite für Fußgänger. Dann sind Fahrradfahrende keine Gefahr für dich."
        },
        "audio": "rf_4.mp3"
    },
    {
        "id": "05",
        "name": "Bevor ich über die Straße gehe schaue ich nach links und rechts",
        "uri": "strasse-ueberqueren",
        "img": {
            "correct": "Schulwegtrainer_RF_06_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_06_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Alles frei? Dann los.",
            "text": "Schaue vor dem Zebrastreifen immer nach links, rechts, links. Gehe erst, wenn alle Autos stehen."
        },
        "audio": "rf_5.mp3"
    },
    {
        "id": "06",
        "name": "Bis zur Sichtlinie gehen",
        "uri": "zwischen-autos",
        "img": {
            "correct": "Schulwegtrainer_RF_07_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_07_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Zur Sichtlinie gehen.",
            "text": "Zwischen parkenden Autos musst du langsam an die Straße vorgehen, bis du den Verkehr siehst."
        },
        "audio": "rf_6.mp3"
    },
    {
        "id": "07",
        "name": "Für den Weg zur Schule kleide ich mich auffällig",
        "uri": "erkennbar-sein",
        "img": {
            "correct": "Schulwegtrainer_RF_08_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_08_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Reflektoren nutzen, dunkle Kleidung vermeiden.",
            "text": "Sei erkennbar für andere Verkehrsteilnehmer - Reflektoren helfen, dunkle Kleidung nicht."
        },
        "audio": "rf_7.mp3"
    },
    {
        "id": "08",
        "name": "Nicht einfach über die Straße gehen",
        "uri": "sicher-vor-schnell",
        "img": {
            "correct": "Schulwegtrainer_RF_09_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_09_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Sicher sein.",
            "text": "Gehe lieber über eine Ampel oder über einen Zebrastreifen über die Straße."
        },
        "audio": "rf_8.mp3"
    },
    {
        "id": "09",
        "name": "Nicht toben, schubsen und rücksichtslos rennen",
        "uri": "richtiges-verhalten",
        "img": {
            "correct": "Schulwegtrainer_RF_10_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_10_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Nicht zu wild!",
            "text": "Toben, Rennen, Schubsen auf dem Gehweg: das kann gefährlich werden. Bleibe lieber ruhig und aufmerksam auf deinem Schulweg."
        },
        "audio": "rf_9.mp3"
    },
    {
        "id": "10",
        "name": "Konzentration beim Überqueren von einer Ampel / Zebrastreifen",
        "uri": "einsatzfahrzeuge",
        "img": {
            "correct": "Schulwegtrainer_RF_11_R_221216_1E.svg",
            "false": "Schulwegtrainer_RF_11_F_221216_1E.svg"
        },
        "explanation": {
            "label": "Blaulicht hat Vorfahrt.",
            "text": "Einsatzfahrzeuge mit Blaulicht und Sirene haben immer Vorfahrt. Auch wenn deine Ampel grün ist, musst du warten, bis die Fahrzeuge vorbeigefahren sind."
        },
        "audio": "rf_10.mp3"
    }
];

export const WimmelData = [
    {
        "id": "000",
        "label": "Katzen",
        "labelSingular": "Katze",
        "resPathID": "katzen",
        "solutions": [
            "wimmelbild_katzen-1.png",
            "wimmelbild_katzen-2.png",
            "wimmelbild_katzen-3.png",
            "wimmelbild_katzen-4.png"
        ] 
    },
    {
        "id": "001",
        "label": "Fahrradfahrer",
        "labelSingular": "Fahrradfahrer",
        "resPathID": "fahrrad",
        "solutions": [
            "wimmelbild_fahrrad-1.png",
            "wimmelbild_fahrrad-2.png",
            "wimmelbild_fahrrad-3.png",
            "wimmelbild_fahrrad-4.png"
        ] 
    },
    {
        "id": "002",
        "label": "Roller",
        "labelSingular": "Roller",
        "resPathID": "roller",
        "solutions": [
            "wimmelbild_roller-1.png",
            "wimmelbild_roller-2.png",
            "wimmelbild_roller-3.png",
            "wimmelbild_roller-4.png"
        ] 
    },
    {
        "id": "003",
        "label": "Mülleimer",
        "labelSingular": "Mülleimer",
        "resPathID": "muell",
        "solutions": [
            "wimmelbild_muell-1.png",
            "wimmelbild_muell-2.png",
            "wimmelbild_muell-3.png",
            "wimmelbild_muell-4.png"
        ] 
    },
    {
        "id": "004",
        "label": "Vögel",
        "labelSingular": "Vogel",
        "resPathID": "voegel",
        "solutions": [
            "wimmelbild_voegel-1.png",
            "wimmelbild_voegel-2.png",
            "wimmelbild_voegel-3.png",
            "wimmelbild_voegel-4.png"
        ] 
    },
    {
        "id": "005",
        "label": "gelben Blumen",
        "labelSingular": "Blume",
        "resPathID": "blumen",
        "solutions": [
            "wimmelbild_blumen-1.png",
            "wimmelbild_blumen-2.png",
            "wimmelbild_blumen-3.png",
            "wimmelbild_blumen-4.png"
        ] 
    }
];

export const AvatarData = [
    {
        "name": "Avatar",
        "icon": "body0.png",
        "options": [
            { 
                "id": "body0", 
                "name": "Avatar 1", 
                "img": "body0.png",
                "colors": [
                    {"css": "#D79E75", "title": "Hautfarbe 3"},
                    {"css": "#5B3222", "title": "Hautfarbe 1"},
                    {"css": "#96634D", "title": "Hautfarbe 2"},
                    {"css": "#CFA289", "title": "Hautfarbe 4"},
                    {"css": "#E5B594", "title": "Hautfarbe 5"}
                ] 
            },
            { 
                "id": "body1", 
                "name": "Avatar 2", 
                "img": "body1.png",
                "colors": [
                    {"css": "#D79E75", "title": "Hautfarbe 3"},
                    {"css": "#5B3222", "title": "Hautfarbe 1"},
                    {"css": "#96634D", "title": "Hautfarbe 2"},
                    {"css": "#CFA289", "title": "Hautfarbe 4"},
                    {"css": "#E5B594", "title": "Hautfarbe 5"}
                ]
            },
            { 
                "id": "body2", 
                "name": "Avatar 3", 
                "img": "body2.png",
                "colors": [
                    {"css": "#D79E75", "title": "Hautfarbe 3"},
                    {"css": "#5B3222", "title": "Hautfarbe 1"},
                    {"css": "#96634D", "title": "Hautfarbe 2"},
                    {"css": "#CFA289", "title": "Hautfarbe 4"},
                    {"css": "#E5B594", "title": "Hautfarbe 5"}
                ]
            }
        ]
    },
    {
        "name": "Frisuren",
        "icon": "hair0.png",
        "options": [
            { 
                "id": "hair0", 
                "name": "Kurzes Haar", 
                "img": "hair0.png",
                "colors": [
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            },
            { 
                "id": "hair1", 
                "name": "Wuschelkopf", 
                "img": "hair1.png",
                "colors": [
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            },
            { 
                "id": "hair2", 
                "name": "Langes Haar", 
                "img": "hair2.png",
                "colors": [
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            },
            { 
                "id": "hair3", 
                "name": "Langes Haar, lockig", 
                "img": "hair3.png",
                "colors": [
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            },
            { 
                "id": "hair4", 
                "name": "Braids", 
                "img": "hair4.png",
                "colors": [
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            },
            { 
                "id": "hair5", 
                "name": "Langes Haar, schulterlang", 
                "img": "hair5.png",
                "colors": [
                    {"css": "#675E59", "title": "Grau"},
                    {"css": "#110C08", "title": "Schwarz"},
                    {"css": "#5E3F25", "title": "Brunette"},
                    {"css": "#A28552", "title": "Blond"},
                    {"css": "#f4d5a4", "title": "Hellblond"},
                    {"css": "#CC563B", "title": "Rot"}
                ] 
            }
            
        ]
    },
    {
        "name": "Kopf / Kopfbedeckung", 
        "icon": "hat0.png",
        "options": [
            { 
                "id": "hat0", 
                "name": "Mütze", 
                "img": "hat0.png",
                "colors": [
                    {"css": "#9AC51B", "title": "Grün"},
                    {"css": "#DDA737", "title": "Gelb"},
                    {"css": "#961493", "title": "Lila"},
                    {"css": "#3A60CC", "title": "Blau"}
                ]
            },
            { 
                "id": "hat1", 
                "name": "Stirnband", 
                "img": "hat1.png",
                "colors": [
                    {"css": "#6795C9", "title": "Blau"},
                    {"css": "#B21161", "title": "Magenta"},
                    {"css": "#5DA819", "title": "Grün"},
                    {"css": "#6DC6B5", "title": "Türkis"}
                ]
            },
            { 
                "id": "hat2", 
                "name": "Cappy", 
                "img": "hat2.png",
                "colors": [
                    {"css": "#AA3318", "title": "Rotbraun"},
                    {"css": "#FCB332", "title": "Gelb"},
                    {"css": "#5DA819", "title": "Grün"},
                    {"css": "#FB667E", "title": "Rosa"}
                ]
            },
            { 
                "id": "hat3", 
                "name": "Helm", 
                "img": "hat3.png",
                "colors": [
                    {"css": "#70114A", "title": "Lila"},
                    {"css": "#3788C3", "title": "Blau"},
                    {"css": "#FB667E", "title": "Rosa"},
                    {"css": "#5DA819", "title": "Grün"}
                ]
            }
        ]
    },
    {
        "name": "Oberkörper", 
        "icon": "torso0.png",
        "options": [
            { 
                "id": "torso0", 
                "name": "Winterpulli", 
                "img": "torso0.png",
                "colors": [
                    {"css": "#7A1DA0", "title": "Lila"},
                    {"css": "#70A9E0", "title": "Hellblau"},
                    {"css": "#589D92", "title": "Türkis"},
                    {"css": "#EA6B53", "title": "Rot"}
                ]
            },
            { 
                "id": "torso1", 
                "name": "Regenjacke", 
                "img": "torso1.png",
                "colors": [
                    {"css": "#E5D039", "title": "Gelb"},
                    {"css": "#B13026", "title": "Rot"},
                    {"css": "#385FC1", "title": "Blau"},
                    {"css": "#AF1270", "title": "Magenta"}
                ]
            },
            { 
                "id": "torso2", 
                "name": "Winterjacke", 
                "img": "torso2.png",
                "colors": [
                    {"css": "#589D92", "title": "Türkis"},
                    {"css": "#405CA5", "title": "Blau"},
                    {"css": "#CC811B", "title": "Ocker"},
                    {"css": "#70B22B", "title": "Grün"}
                ]
            },
            { 
                "id": "torso3", 
                "name": "Hoodie", 
                "img": "torso3.png",
                "colors": [
                    {"css": "#A9006F", "title": "Magenta"},
                    {"css": "#9AC51B", "title": "Grün"},
                    {"css": "#589D92", "title": "Gelb"},
                    {"css": "#F2BB33", "title": "Navi"}
                ]
            },
            { 
                "id": "torso4", 
                "name": "Tunika", 
                "img": "torso4.png",
                "colors": [
                    {"css": "#AA5ACC", "title": "Helllila"},
                    {"css": "#6DC6B5", "title": "Helltürkis"},
                    {"css": "#841A1D", "title": "Dunkelrot"},
                    {"css": "#C0D85B", "title": "Hellgrün"}
                ]
            },
            { 
                "id": "torso5", 
                "name": "T-Shirt", 
                "img": "torso5.png",
                "colors": [
                    {"css": "#CC563B", "title": "Rot"},
                    {"css": "#EADD2A", "title": "Gelb"},
                    {"css": "#4A99CE", "title": "Blau"},
                    {"css": "#7C79B4", "title": "Blaugrau"}
                ]
            }
        ]
    },
    {
        "name": "Unterkörper", 
        "icon": "legs0.png",
        "options": [
            { 
                "id": "legs0", 
                "name": "Leggins", 
                "img": "legs0.png",
                "colors": [
                    {"css": "#CC811B", "title": "Ocker"},
                    {"css": "#69B65F", "title": "Hellgrün"},
                    {"css": "#D4DB1F", "title": "Gelb"},
                    {"css": "#630D9A", "title": "Lila"}
                ]
            },
            { 
                "id": "legs1", 
                "name": "Kurze Hose", 
                "img": "legs1.png",
                "colors": [
                    {"css": "#5DA819", "title": "Grün"},
                    {"css": "#293C8C", "title": "Blau"},
                    {"css": "#CC563B", "title": "Hellrot"},
                    {"css": "#826238", "title": "Braun"}
                ]
            },
            { 
                "id": "legs2", 
                "name": "Rock", 
                "img": "legs2.png",
                "colors": [
                    {"css": "#B13026", "title": "Rot"},
                    {"css": "#CE4DA6", "title": "Rosa"},
                    {"css": "#8FC9F9", "title": "Hellblau"},
                    {"css": "#5DA819", "title": "Grün"}
                ]
            },
            { 
                "id": "legs3", 
                "name": "Lange Hose", 
                "img": "legs3.png",
                "colors": [
                    {"css": "#14247F", "title": "Navi"},
                    {"css": "#7D6D5F", "title": "Grau"},
                    {"css": "#70114A", "title": "Lila"},
                    {"css": "#5DA819", "title": "Grün"}
                ]
            }
        ]
    },
    {
        "name": "Füße", 
        "icon": "feet0.png",
        "options": [
            { 
                "id": "feet0", 
                "name": "Halbschuhe", 
                "img": "feet0.png",
                "colors": [
                    {"css": "#B73F1B", "title": "Rot"},
                    {"css": "#589D92", "title": "Türkis"},
                    {"css": "#70114A", "title": "Lila"},
                    {"css": "#14247F", "title": "Navi"}
                ]
            },
            { 
                "id": "feet1", 
                "name": "Gummistiefel", 
                "img": "feet1.png",
                "colors": [
                    {"css": "#E5D039", "title": "Gelb"},
                    {"css": "#B73F1B", "title": "Rot"},
                    {"css": "#408424", "title": "Grün"},
                    {"css": "#6795C9", "title": "Blau"}
                ]
            },
            { 
                "id": "feet2", 
                "name": "Boots", 
                "img": "feet2.png",
                "colors": [
                    {"css": "#644427", "title": "Braun"},
                    {"css": "#408424", "title": "Grün"},
                    {"css": "#841A1D", "title": "Dunkelrot"},
                    {"css": "#3788C3", "title": "Blau"}
                ]
            },
            { 
                "id": "feet3", 
                "name": "Sandalen", 
                "img": "feet3.png",
                "colors": [
                    {"css": "#405CA5", "title": "Blau"},
                    {"css": "#A9006F", "title": "Magenta"},
                    {"css": "#9AC51B", "title": "Grün"},
                    {"css": "#6DC6B5", "title": "Türkis"}
                ]
            }
        ]
    }
]

export const VideoData = [
    {
        "id": "01",
        "title": "Planen, üben, sicher sein",
        "subtitle": "Gemeinsam sicher in die Schule gehen",
        "uri": "schulweg-planen",
        "video": "01_schulweg-planen.mp4",
        "poster": "01_schulweg-planen.jpg",
        "cc": "film_1_cc.vtt",
        "component": "SchulwegPlanen",
        "foto": "schulweg-planen.jpg",
        "nummer_aus_script": "01",
        "sort": 0, 
        "themenid": 1        
    },
    {
        "id": "02",
        "title": "Nicht zu wild!",
        "subtitle": "Für mehr Aufmerksamkeit auf dem Schulweg",
        "uri": "verhalten",
        "video": "02_verhalten.mp4",
        "poster": "02_verhalten.jpg",
        "cc": "film_2_cc.vtt",
        "component": "Verhalten",
        "foto": "verhalten.jpg",
        "nummer_aus_script": "02",
        "sort": 8, 
        "themenid": 3         
    },    
    {
        "id": "03",
        "title": "Alles frei? Dann los.",
        "subtitle": "Wie jeder sicher über die Straße kommt",
        "uri": "strasse-ueberqueren",
        "video": "03_strasse-ueberqueren.mp4",
        "poster": "03_strasse-ueberqueren.jpg",
        "cc": "film_3_cc.vtt",
        "component": "StrasseUeberqueren",
        "foto": "strasse-ueberqueren.jpg",
        "nummer_aus_script": "04",
        "sort": 3,  
        "themenid": 2        
    }, 
    {
        "id": "04",
        "title": "Kinderaugen sehen anders",
        "subtitle": "Über die Straße zwischen parkenden Autos",
        "uri": "parkende-autos",
        "video": "04_parkende-autos.mp4",
        "poster": "04_parkende-autos.jpg",
        "cc": "film_4_cc.vtt",
        "component": "ParkendeAutos",
        "foto": "parkende-autos.jpg",
        "nummer_aus_script": "06",
        "sort": 4,
        "themenid": 2          
    }, 
    {
        "id": "05",
        "title": "Blaulicht hat Vorfahrt",
        "subtitle": "Das gilt, wenn Einsatzfahrzeuge kommen.",
        "uri": "einsatzfahrzeuge",
        "video": "05_einsatzfahrzeuge.mp4",
        "poster": "05_einsatzfahrzeuge.jpg",
        "cc": "film_5_cc.vtt",
        "component": "Einsatzfahrzeuge",
        "foto": "einsatzfahrzeuge.jpg",
        "nummer_aus_script": "08",
        "sort": 5,
        "themenid": 2          
    },   
    {
        "id": "06",
        "title": "Achtung, Ausfahrt!",
        "subtitle": "Richtiges Verhalten bei Garagen und Ausfahrten",
        "uri": "ausfahrt",
        "video": "06_ausfahrt.mp4",
        "poster": "06_ausfahrt.jpg",
        "cc": "film_6_cc.vtt",
        "component": "Ausfahrt",
        "foto": "ausfahrt.jpg",
        "nummer_aus_script": "05",
        "sort": 10,
        "themenid": 4          
    },       
    {
        "id": "07",
        "title": "Kein Zirkus auf dem Gehweg",
        "subtitle": "Warum die Kinderseite sicherer ist",
        "uri": "gehweginnenseite",
        "video": "07_gehweginnenseite.mp4",
        "poster": "07_gehweginnenseite.jpg",
        "cc": "film_7_cc.vtt",
        "component": "Gehweginnenseite",
        "foto": "gehweginnenseite.jpg",
        "nummer_aus_script": "03",
        "sort": 6, 
        "themenid": 3
    }, 
    {
        "id": "08",
        "title": "Vorsicht und Rücksicht",
        "subtitle": "Verhalten auf einem geteilten Gehweg",
        "uri": "radfahrer",
        "video": "08_radfahrer.mp4",
        "poster": "08_radfahrer.jpg",
        "cc": "film_8_cc.vtt",
        "component": "Radfahrer",
        "foto": "radfahrer.jpg",
        "nummer_aus_script": "07",
        "sort": 7,
        "themenid": 3         
    }, 
    {
        "id": "09",
        "title": "Stars im Straßenverkehr",
        "subtitle": "Tipps für den Schulweg im Dunkeln",
        "uri": "kleidung",
        "video": "09_kleidung.mp4",
        "poster": "09_kleidung.jpg",
        "cc": "film_9_cc.vtt",
        "component": "Kleidung",
        "foto": "kleidung.jpg",
        "nummer_aus_script": "09",
        "sort": 9,
        "themenid": 4         
    }, 
    {
        "id": "10",
        "title": "Elterntaxi ohne Risiko",
        "subtitle": "Sicherheit auf dem Schulweg mit dem Auto",
        "uri": "elterntaxi",
        "video": "10_elterntaxi.mp4",
        "poster": "10_elterntaxi.jpg",
        "cc": "film_10_cc.vtt",
        "component": "Elterntaxi",
        "foto": "elterntaxi.jpg",
        "nummer_aus_script": "10",
        "sort": 2,
        "themenid": 1          
    },            
    
    {
        "id": "11",
        "title": "Geht doch – ohne Elterntaxi",
        "subtitle": "Den Schulweg ohne Auto sicher gestalten",
        "uri": "ohne-elterntaxi",
        "video": "11_ohne-elterntaxi.mp4",
        "poster": "11_ohne-elterntaxi.jpg",
        "cc": "film_11_cc.vtt",
        "component": "OhneElterntaxi",
        "foto": "ohne-elterntaxi.jpg",
        "nummer_aus_script": "11",
        "sort": 1,
        "themenid": 1         
    }    
];

export const HeaderData = [
    {
        "path": "eltern",
        "title": "Warum Schulwegtraining?",
        "bild": "menschen_eltern.png",
        "klasse": "eltern"      
    },
    {
        "path": "gut-zu-wissen",
        "title": "Gut zu wissen",
        "bild": "menschen_gut_zu_wissen.png",
        "klasse": "gutzuwissen"        
    },
    {
        "path": "faqs",
        "title": "Gut zu wissen",
        "bild": "menschen_faq.png",
        "klasse": "faq"         
    } ,
    {
        "path": "grundlagen",
        "title": "Gut zu wissen",
        "bild": "menschen_grundlagen.png",
        "klasse": "grundlagen"         
    },
    {
        "path": "versicherungsschutz",
        "title": "Gut zu wissen",
        "bild": "menschen_grundlagen.png",
        "klasse": "versicherungsschutz"        
    },
    {
        "path": "leichte-sprache",
        "title": "Leichte Sprache",
        "bild": "",
        "klasse": "rechtliches"        
    },
    {
        "path": "impressum",
        "title": "Impressum",
        "bild": "",
        "klasse": "rechtliches"        
    } ,
    {
        "path": "barrierefreiheit",
        "title": "Barrierefreiheit",
        "bild": "",
        "klasse": "rechtliches"        
    } ,
    {
        "path": "datenschutz",
        "title": "Datenschutz",
        "bild": "",
        "klasse": "rechtliches"        
    },
    {
        "path": "projektpartner",
        "title": "Projektpartner",
        "bild": "",
        "klasse": "rechtliches"        
    }                                                
];

export const Themenbereiche = [
    {
        "id": 1,
        "title": "Schulweg planen ohne Elterntaxi",
        "videos": [0,1,2]   
    },
    {
        "id": 2,
        "title": "Sicher über die Straße",
        "videos": [3,4,5]        
    },
    {
        "id": 3,
        "title": "Verhalten auf dem Gehweg",
        "videos": [6,7,8]       
    },
    {
        "id": 4,
        "title": "Sehen und gesehen werden",
        "videos": [9,10]      
    }        
];

export const SiteTitles = [
    {
        "path": "/",
        "title": "Schulwegtrainer"
    }, 
    {
        "path": "/home",
        "title": "Schulwegtrainer"
    }, 
    {
        "path": "/eltern",
        "title": "SWT | Für Eltern"
    },     
    {
        "path": "/eltern/top-themen",
        "title": "SWT | So geht's"
    },  
    {
        "path": "/eltern/top-themen/schulweg-planen",
        "title": "SWT | Planen, üben, sicher sein"
    }, 
    {
        "path": "/eltern/top-themen/ohne-elterntaxi",
        "title": "SWT | Geht doch - ohne Elterntaxi"
    },
    {
        "path": "/eltern/top-themen/elterntaxi",
        "title": "SWT | Elterntaxi ohne Risiko"
    },      
    {
        "path": "/eltern/top-themen/strasse-ueberqueren",
        "title": "SWT | Alles frei? Dann los."
    },     
    {
        "path": "/eltern/top-themen/parkende-autos",
        "title": "SWT | Kinderaugen sehen anders"
    },   
    {
        "path": "/eltern/top-themen/einsatzfahrzeuge",
        "title": "SWT | Blaulicht hat Vorfahrt"
    },  
    {
        "path": "/eltern/top-themen/gehweginnenseite",
        "title": "SWT | Kein Zirkus auf dem Gehweg"
    },  
    {
        "path": "/eltern/top-themen/radfahrer",
        "title": "SWT | Vorsicht und Rücksicht"
    },     
    {
        "path": "/eltern/top-themen/verhalten",
        "title": "SWT | Nicht zu wild!"
    },  
    {
        "path": "/eltern/top-themen/kleidung",
        "title": "SWT | Stars im Straßenverkehr"
    },         
    {
        "path": "/eltern/top-themen/ausfahrt",
        "title": "SWT | Achtung, Ausfahrt!"
    },                     
    {
        "path": "/eltern/gut-zu-wissen",
        "title": "SWT | Gut zu wissen"
    }, 
    {
        "path": "/eltern/gut-zu-wissen/grundlagen",
        "title": "SWT | Kind und Verkehr"
    },  
    {
        "path": "/eltern/gut-zu-wissen/versicherungsschutz",
        "title": "SWT | Versicherungsschutz"
    },         
    {
        "path": "/eltern/gut-zu-wissen/faqs",
        "title": "SWT | Häufig gestellte Fragen"
    },       
    {
        "path": "/spiele",
        "title": "SWT | Für Kinder"
    },   
    {
        "path": "/spiele/richtig-falsch",
        "title": "SWT | Was ist richtig?"
    },     
    {
        "path": "/spiele/wimmelbild",
        "title": "SWT | Wimmelbild"
    },       
    {
        "path": "/spiele/avatar",
        "title": "SWT | Wie ziehe ich mich an?"
    },            
    {
        "path": "/impressum",
        "title": "SWT | Impressum"
    },         
    {
        "path": "/datenschutz",
        "title": "SWT | Datenschutz"
    },     
    {
        "path": "/barrierefreiheit",
        "title": "SWT | Barrierefreiheit"
    },
    {
        "path": "/projektpartner",
        "title": "SWT | Projektpartner"
    },          
    {
        "path": "/leichte-sprache",
        "title": "SWT | Leichte Sprache"
    },  

];