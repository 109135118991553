import React, { useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import styles from './Home.module.scss';
import ButtonNew from '../components/ButtonNew';

const Home = () => {
  useEffect(()=>{
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();  
    }, 100);
  }, []);
  
  return (
    <div>
      <Container fluid id={styles.tafel}>   
          <div id={styles.links}></div>
          <div id={styles.rechts}></div>
      </Container>

      <Container fluid id={styles.mobile}>
        <Container>
          <p>
            <strong>Initiative der Landesverkehrswacht&nbsp;BW <br />und der Unfallkasse&nbsp;BW</strong>
          </p>
        </Container>

      </Container>

      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>Sicherheit ist kinderleicht </h1>
            <p className='intro'>
            Der Schulweg ist ein Erlebnisort. Für Kinder sind die täglichen Schritte zur Schule eine grundlegende Erfahrung und wirken sich positiv auf die persönliche Entwicklung aus. Und die meisten Kinder können auf ihrem Schulweg zum ersten Mal ihre Umwelt ohne Eltern entdecken. 
            </p>
          </Col>
            
        </Row>
        <Row className='reverseorder'>
          <Col md="6">
            <p >
              Damit der Schulweg ein sicherer Erlebnisort wird und bleibt, brauchen Kinder anfangs noch die Unterstützung ihrer Eltern. Nur gemeinsam können Schulanfängerinnen und Schulanfänger lernen, sich sicher im Straßenverkehr zu bewegen. Beim Schulwegtrainer finden Eltern und Kinder dafür viele praktische Tipps und hilfreiche Informationen. Denn Sicherheit ist kinderleicht.
            </p>
            <p >
              Der Schulwegtrainer ist eine Initiative der Landesverkehrswacht Baden-Württemberg in Zusammenarbeit mit der Unfallkasse Baden-Württemberg. Gefördert wird diese vom Ministerium des Inneren, für Digitalisierung und Kommunen Baden-Württemberg.
            </p>            

          </Col>
          <Col md="6">
            <Image fluid src={require('../images/startseite.jpg')} className="mobileabstandunten" alt=""/>
          </Col>
        </Row>
      </Container>
      <Container id={styles.karte} fluid>
        <Container>
          <Row className={styles.buttons}>
            <Col className={styles.zentriert}>
              <ButtonNew art="buttoneltern" haupttext="Für Eltern" subtext="Tipps und Infos" pfad="/eltern" />
            </Col>
            <Col className={styles.zentriert}>
              <ButtonNew art="buttonkinder" haupttext="Für Kinder" subtext="Spiele zum Schulweg" pfad="/spiele" />
            </Col>
           </Row>

        </Container>

      </Container>
    </div>
  )
}

export default Home