import React, { useEffect, useRef, useState } from 'react'

const Video = (props) => {
    const videoRef = useRef();
    const className = (props.className !== undefined) ? props.className : "video-player";
    const id = (props.id !== undefined) ? props.id : "";

    const loop = (props.loop !== undefined) ? props.loop : false;
    const autoPlay = (props.autoPlay !== undefined) ? props.autoPlay : false;
    const muted = (props.muted !== undefined) ? props.muted : false;
    const poster = (props.poster !== undefined) ? props.poster : "";
    const crossorigin = (props.crossorigin !== undefined) ? props.crossorigin : "anonymous";

    const width = (props.width !== undefined) ? props.width : "";
    const height = (props.height !== undefined) ? props.height : "";

    const webm = (props.webm !== undefined) ? props.webm : "";
    
    const trackSrc = (props.trackSrc !== undefined) ? props.trackSrc : "";
    const trackLabel = (props.trackLabel !== undefined) ? props.trackLabel : "Deutsch";
    const titel = (props.titel !== undefined) ? props.titel : "";

    const deviceWidth = useState(window.innerWidth * window.devicePixelRatio);
    const src = (props.srcSm !== undefined && deviceWidth <= 768) ? props.srcSm : props.src;

    useEffect(() => {
        if(videoRef.current){
            videoRef.current.load();
            if(videoRef.current.textTracks.length > 0) videoRef.current.textTracks[0].mode = "disabled"
            for (let i = 0; i < videoRef.current.children.length; i++) {
                let child = videoRef.current.children[i];
                if(child.tagName === "TRACK"){
                    child.remove()
                }
            }
            let track = document.createElement("track");
            track.kind = "captions";
            track.label = trackLabel;
            track.srclang = "de";
            track.src = trackSrc;
            videoRef.current.appendChild(track);
            videoRef.current.textTracks[0].mode = "showing"
        }
    }, [props.src]);

    function handlePlay(){
        var _paq = window._paq = window._paq || [];
        _paq.push(["disableCookies"]);
        _paq.push(['setCustomUrl', encodeURI(src)]);
        _paq.push(['setDocumentTitle', "Video | "+titel]);
        _paq.push(['trackPageView']);
    }

    return (
        <video 
            ref={videoRef} 
            className={className} 
            id={id} 
            controls
            loop={loop} 
            autoPlay={autoPlay} 
            muted={muted} 
            poster={poster}
            crossOrigin={crossorigin}
            width={width}
            height={height}
            onPlay={handlePlay}>
            {(webm !== "") && <source src={props.webm} type='video/webm' />}
            <source src={src} type='video/mp4' />
            Ihr Browser kann dieses Video nicht wiedergeben.<br /> Dieser Film  ist ein kurzer Clip zum Thema {titel} und kann unter <a href={"https://schulwegtrainer.de"+src} target="_blank" rel="noreferrer">{"https://schulwegtrainer.de"+src}</a> heruntergeladen werden.
        </video>
    )
}

export default Video