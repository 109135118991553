import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { BsFillVolumeUpFill, BsFillVolumeMuteFill } from 'react-icons/bs'
import styles from './PlayAudio.module.scss'

const PlayAudio = (props) => {
    const className = (props.className !== undefined) ? props.className : "";
    const style = (props.style !== undefined) ? props.style : {};
    const [myAudio, setmyAudio] = useState(null)
    const [enabled, setEnabled] = useState(false)
    const [audioPlaying, setAudioPlaying] = useState(false)
    const [noAudio, setNoAudio] = useState(false)

    useEffect(() => {
        setmyAudio(new Audio(props.src))
    }, [props.src])

    useEffect(() => {
        if(myAudio !== null){
            if(myAudio.canPlayType("audio/mpeg")){
                myAudio.addEventListener('ended', function(){
                    setAudioPlaying(false)
                    props.setActive(false)
                });
                return () => window.removeEventListener('resize', function(){
                    setAudioPlaying(false)
                    props.setActive(false)
                });
            }
            else{
                setNoAudio(true)
            }
        }
    }, [myAudio, props])

    const enable = () => {
        setEnabled(!enabled)
    }

    useEffect(()=>{
        if(props.active && !audioPlaying && enabled){
            myAudio.play()
            setAudioPlaying(true)
        }
        if((!props.active && audioPlaying) || (!enabled && audioPlaying)){
            myAudio.pause()
            myAudio.currentTime = 0
            setAudioPlaying(false)
        }
    }, [props.active, audioPlaying, myAudio, enabled])

    let classes = className+" "+styles.audioBtn
    if(enabled && audioPlaying) classes += " "+styles.audioPlaying
    if(!enabled) classes += " "+styles.muted

    if(noAudio){
        return null;
    }
    else{
        return (
            <Button variant="primary" title={(enabled) ? 'vorlesen ausschalten' : 'vorlesen lassen'} className={classes} onClick={enable} style={style}>
                {(enabled) ? <BsFillVolumeUpFill/> : <BsFillVolumeMuteFill />}
            </Button>
        )
    }
}

export default PlayAudio