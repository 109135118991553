import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import styles from './SubNaviParents.module.scss'
import BurgerPfeil from '../../components/BurgerPfeil';
import stylestwo from '../General/Burgermenu.module.scss';

const SubNaviParents = (props) => {
  function togglePfeil(e) {
    let expanded = e.currentTarget.getAttribute("aria-expanded");
    e.currentTarget.closest("li").classList.toggle(stylestwo.offen);

    if (expanded === "false") {
      e.currentTarget.setAttribute("aria-expanded", "true");
    } else {
      e.currentTarget.setAttribute("aria-expanded", "false");
    }

  }

  function togglePfeilByKey(e) {
    let tastenCode;
    if (e.keyCode) {
      tastenCode = e.keyCode; /* IE */
    }
    else if (e.which) {
      tastenCode = e.which; /* Moz, Saf, Op */
    }
    if (tastenCode !== 13 && tastenCode !== 32) {
      return true;
    } else {
      e.currentTarget.closest("li").classList.toggle(stylestwo.offen);      
    }
  }

  const buttonAttributes = {
    tabIndex: "0",
    role: "button", 
    "aria-expanded": false
  }
  
  return (
    <Navbar bg={(props.burgermenu) ? "" : "primary"} className="nopadding" aria-label="Unternavigation Bereich Eltern">
      <Container className={(props.burgermenu) ? styles.nopadding : ''}>
          <Nav
            className={(props.burgermenu) ? styles.ganze_breite : styles.subnavi } 
          >
            <ul id={(props.burgermenu) ? 'normal' : styles.ebene1}>
              <li>
                <div><Link className={(props.section.includes("/eltern/top-themen")) ? 'aktiv nav-link' : 'nav-link'} to="/eltern/top-themen" onClick={(props.burgermenu) ? props.onClick : null}>So geht's</Link></div>
                <ul className={(props.burgermenu) ? '' : styles.ebene2}>
                  <li>
                    <div onClick={(props.burgermenu) ? togglePfeil : null} onKeyUp={(props.burgermenu) ? togglePfeilByKey : null} className={(props.burgermenu) ? stylestwo.mitcursor : ""} {...(props.burgermenu) ? {...buttonAttributes} : null}><div className={(props.burgermenu) ? styles.zwischenueberschriftburger : styles.zwischenueberschrift}>Schulweg planen ohne Elterntaxi</div>{(props.burgermenu) && <BurgerPfeil klasse={stylestwo.ohneborder}/> }</div>
                    <ul className={(props.burgermenu) ? styles.playbutton : styles.ebene3}>
                      <li><Link className={(props.section.includes("/eltern/top-themen/schulweg-planen")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/schulweg-planen" onClick={(props.burgermenu) ? props.onClick : null}>Planen, üben, sicher sein</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/ohne-elterntxi")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/ohne-elterntaxi" onClick={(props.burgermenu) ? props.onClick : null}>Geht doch - ohne Elterntaxi</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/elterntaxi")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/elterntaxi" onClick={(props.burgermenu) ? props.onClick : null}>Elterntaxi ohne Risiko</Link></li>
                    </ul>
                  </li>
                  <li>
                    <div onClick={(props.burgermenu) ? togglePfeil : null}  className={(props.burgermenu) ? stylestwo.mitcursor : ""} onKeyUp={(props.burgermenu) ? togglePfeilByKey : null}  {...(props.burgermenu) ? {...buttonAttributes} : null}><div className={(props.burgermenu) ? styles.zwischenueberschriftburger : styles.zwischenueberschrift} >Sicher über die Straße</div>{(props.burgermenu) && <BurgerPfeil klasse={stylestwo.ohneborder}/> }</div>
                    <ul className={(props.burgermenu) ? styles.playbutton : styles.ebene3}>
                      <li><Link className={(props.section.includes("/eltern/top-themen/strasse-ueberqueren")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/strasse-ueberqueren" onClick={(props.burgermenu) ? props.onClick : null}>Alles frei? Dann los!</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/parkende-autos")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/parkende-autos" onClick={(props.burgermenu) ? props.onClick : null}>Kinderaugen sehen anders</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/einsatzfahrzeuge")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/einsatzfahrzeuge" onClick={(props.burgermenu) ? props.onClick : null}>Blaulicht hat Vorfahrt</Link></li>
                    </ul>
                  </li>                  
                  <li>
                    <div onClick={(props.burgermenu) ? togglePfeil : null} className={(props.burgermenu) ? stylestwo.mitcursor : ""} onKeyUp={(props.burgermenu) ? togglePfeilByKey : null}  {...(props.burgermenu) ? {...buttonAttributes} : null}><div className={(props.burgermenu) ? styles.zwischenueberschriftburger : styles.zwischenueberschrift} >Verhalten auf dem Gehweg</div>{(props.burgermenu) && <BurgerPfeil klasse={stylestwo.ohneborder}/> }</div>
                    <ul className={(props.burgermenu) ? styles.playbutton : styles.ebene3}>
                      <li><Link className={(props.section.includes("/eltern/top-themen/gehweginnenseite")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/gehweginnenseite" onClick={(props.burgermenu) ? props.onClick : null}>Kein Zirkus auf dem Gehweg</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/radfahrer")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/radfahrer" onClick={(props.burgermenu) ? props.onClick : null}>Vorsicht und Rücksicht</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/verhalten")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/verhalten" onClick={(props.burgermenu) ? props.onClick : null}>Nicht zu wild!</Link></li>                      
                    </ul>
                  </li> 
                  <li>
                    <div onClick={(props.burgermenu) ? togglePfeil : null}  className={(props.burgermenu) ? stylestwo.mitcursor : ""} onKeyUp={(props.burgermenu) ? togglePfeilByKey : null}  {...(props.burgermenu) ? {...buttonAttributes} : null}><div className={(props.burgermenu) ? styles.zwischenueberschriftburger : styles.zwischenueberschrift} >Sehen und gesehen werden</div>{(props.burgermenu) && <BurgerPfeil klasse={stylestwo.ohneborder}/> }</div>
                    <ul className={(props.burgermenu) ? styles.playbutton : styles.ebene3}>
                      <li><Link className={(props.section.includes("/eltern/top-themen/kleidung")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/kleidung" onClick={(props.burgermenu) ? props.onClick : null}>Stars im Straßenverkehr</Link></li>
                      <li><Link className={(props.section.includes("/eltern/top-themen/ausfahrt")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/top-themen/ausfahrt" onClick={(props.burgermenu) ? props.onClick : null}>Achtung, Ausfahrt!</Link></li>
                    </ul>
                  </li>                   

                </ul>
              </li>
              <li>
                <div><Link className={(props.section.includes("/eltern/gut-zu-wissen")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/gut-zu-wissen" onClick={(props.burgermenu) ? props.onClick : null}>Gut zu wissen</Link></div>
                <ul className={(props.burgermenu) ? '' : styles.ebene2 + ' ' + styles.ebene3}>
                  <li> 
                    <Link className={(props.section.includes("/eltern/gut-zu-wissen/grundlagen")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/gut-zu-wissen/grundlagen" onClick={(props.burgermenu) ? props.onClick : null}>Kind und Verkehr</Link>               
                  </li>
                  <li><Link className={(props.section.includes("/eltern/gut-zu-wissen/versicherungsschutz")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/gut-zu-wissen/versicherungsschutz" onClick={(props.burgermenu) ? props.onClick : null}>Versicherungs&shy;schutz</Link></li>
                  <li><Link className={(props.section.includes("/eltern/gut-zu-wissen/faqs")) ? 'aktiv nav-link' : 'nav-link'}  to="/eltern/gut-zu-wissen/faqs" onClick={(props.burgermenu) ? props.onClick : null}>FAQs</Link></li>
                </ul>

              </li>
            </ul>
          </Nav>
      </Container>
    </Navbar>
  );
}

export default SubNaviParents;