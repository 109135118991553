import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageHeader from './PageHeader';

const Wissen = (props) => {
  useEffect(() => {
    setTimeout(() => {
      let navelement = document.activeElement;
      navelement.blur();
    }, 100);
  }, []);

  return (
    <div>
      <PageHeader path={props.path} />
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>Kinder brauchen Vorbilder</h1>
            <p>
              Sicheres Verhalten auf dem Schulweg lernen Kinder am besten über das vorbildhafte Verhalten von Erwachsenen. Aber: Kinder sind keine kleinen Erwachsenen. Ihre körperlichen und geistigen Fähigkeiten sind noch nicht voll entwickelt - und das wirkt sich darauf aus, wie sie sich im Straßenverkehr verhalten.
            </p>
            <p>
              Erfahren Sie unter <Link to="grundlagen">"Kind und Verkehr"</Link> mehr über den Entwicklungsstand von jüngeren Schulkindern und die Folgen auf ihr Verhalten im Straßenverkehr. Weitere Informationen zur Schulwegsicherheit erhalten Eltern unter der Rubrik <Link to="versicherungsschutz">"Versicherungsschutz"</Link> und in den <Link to="faqs">"FAQ"</Link>.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Wissen;