import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ImageToLoad from '../../components/ImageToLoad'
import styles from './GamesPreview.module.scss'

const GamesPreview = (props) => {
    const [animate, setAnimate] = useState(false)
    const [counter, setCounter] = useState(0)

    const richtigfalsch = (props.richtigfalsch === false) ? false : true;
    const wimmelbild = (props.wimmelbild === false) ? false : true;
    const avatar = (props.avatar === false) ? false : true;

    useEffect(()=>{
        setCounter(1)
        setTimeout(() => {
            setAnimate((a) => !a)
        }, 2000);
    }, [])

    useEffect(()=>{
        setCounter((c) => (c === 1) ? 2 : (c === 2) ? 3 : 1)
        setTimeout(() => {
            setAnimate(!animate)
        }, 2000);
    }, [animate])

    return (
        <Row className={styles.games}>
            {(richtigfalsch) && 
            <Col className='my-3 d-flex justify-content-center' md={(wimmelbild && avatar) ? 4 : (wimmelbild || avatar) ? 6 : 12}>
                <Link className={(animate && (counter === 1)) ? styles.game+" "+styles.animate : styles.game} to="/spiele/richtig-falsch">
                    <ImageToLoad src={require('../../images/richtig-falsch.png')} />
                    <div className={styles.tileText}>
                        <h2 className='my-3'>Was ist richtig?</h2>
                        <p>Ein Bilderquiz zum richtigen Verhalten im Straßenverkehr</p>
                    </div>
                </Link>
            </Col>}
            {(wimmelbild) && 
            <Col className='my-3 d-flex justify-content-center' md={(richtigfalsch && avatar) ? 4 : (richtigfalsch || avatar) ? 6 : 12}>
                <Link className={(animate && (counter === 2)) ? styles.game+" "+styles.animate : styles.game} to="/spiele/wimmelbild">
                    <ImageToLoad src={require('../../images/wimmelbild.jpg')} />
                    <div className={styles.tileText}>
                        <h2 className='my-3'>Wo sind die ...?</h2>
                        <p>Finde alle versteckten Sachen in diesem Wimmelbild</p>
                    </div>
                </Link>
            </Col>}
            {(avatar) && 
            <Col className='my-3 d-flex justify-content-center' md={(wimmelbild && richtigfalsch) ? 4 : (wimmelbild || richtigfalsch) ? 6 : 12}>
                <Link className={(animate && (counter === 3)) ? styles.game+" "+styles.animate : styles.game} to="/spiele/avatar">
                    <ImageToLoad src={require('../../images/avatar.png')} />
                    <div className={styles.tileText}>
                        <h2 className='my-3'>Wie ziehe ich mich an?</h2>
                        <p>Erstelle dein Lieblings-Outfit</p>
                    </div>
                </Link>
            </Col>}
        </Row>
    )
}

export default GamesPreview