import React, { Suspense, useEffect, useState } from 'react';
import Header from './pages/General/Header'
import Footer from './pages/General/Footer'
import NetworkBar from './components/NetworkBar'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Loading from './components/Loading';
import AskForOfflineMode from './components/AskForOfflineMode';
import { SiteTitles } from './data/data.js';

import Home from './pages/Home';
import Eltern from './pages/Parents/Eltern';
import Themen from './pages/Parents/Themen';
import Videos from './pages/Parents/Videos';
import Wissen from './pages/Parents/Wissen';
import Grundlagen from './pages/Parents/Grundlagen';
import Versicherungsschutz from './pages/Parents/Versicherungsschutz';
import FAQ from './pages/Parents/FAQ';
import Games from './pages/Games';
import RichtigFalsch from './pages/Games/RichtigFalsch';
import Wimmelbild from './pages/Games/Wimmelbild';
import Avatar from './pages/Games/Avatar';
import NotFound from './pages/NotFound';
import LeichteSprache from './pages/General/LeichteSprache';
import Impressum from './pages/General/Impressum';
import Datenschutz from './pages/General/Datenschutz';
import Barrierefreiheit from './pages/General/Barrierefreiheit';
import Projektpartner from './pages/General/Projektpartner';

function App() {

  const path = useLocation().pathname;
  const videopath = path.substring(path.lastIndexOf("/")+1, path.length);

  const [online, setOnline] = useState(navigator.onLine);
  const [offlineMode, setOfflineMode] = useState(false)

  const handleOnlineChange = (e) => {
    setOnline(e.type === "online")
  }
  
  useEffect(() => {
    // set Eventlistener for online/offline detection
    window.addEventListener('online', handleOnlineChange);
    window.addEventListener('offline', handleOnlineChange);
    return () => {
        window.removeEventListener('online', handleOnlineChange);
        window.removeEventListener('offline', handleOnlineChange);
    }
  }, [])

  useEffect(() => {
    // scroll top
    setTimeout(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});             
    }, 10);
    // set games class if url contains "spiele"
    if(path.includes("/spiele")){
      setGamesActive(true)
    }
    else{
      setGamesActive(false)
    }
  }, [path]);

  useEffect(() => {
    const title = "Schulwegtrainer";
    if (path) {
      let currentTitle = SiteTitles.filter(i => i.path === path);
      if (currentTitle[0] !== undefined) {
        document.title = currentTitle[0].title;
      } else {
        document.title = title;
      }
      
    }    
    // init fetch to current location to trigger stats
    if(online){
      let url = window.location.href;
      implementStats(url);
    }
  }, [path, online]);
  
  const hash = useLocation().hash;

  useEffect(()=>{
      if(hash){
        setTimeout(() => {
          let targetElement = document.getElementById(hash.substring(1))
          targetElement?.scrollIntoView({behavior: 'smooth'})          
        }, 100);
      }
  }, [hash])

  const [gamesActive, setGamesActive] = useState(false);



var _paq = window._paq = window._paq || [];
(function() {
  var u="https://matomo.jedermann.de/";
  _paq.push(['setTrackerUrl', u+'matomo.php']);
  _paq.push(['setSiteId', '45']);
  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
})(_paq);


async function implementStats (url) {
  _paq.push(["disableCookies"]);
  _paq.push(['setCustomUrl', encodeURI(url)]);
  _paq.push(['setDocumentTitle', document.title]);
  _paq.push(['trackPageView']);
  return true;
};


  return (
    <div className={(gamesActive) ? "App games" : "App general"}>
        <Header games={gamesActive} section={path} />
        <main className={(path.includes("/eltern")) ? "abstand_oben" : ""}>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/home" element={<Home/>}/>
                <Route path="/qr" element={<Home/>}/>
                <Route path="/eltern">
                  <Route path="top-themen">
                    <Route path="schulweg-planen" element={<Videos videopath={videopath} videoid="01"/>} />
                    <Route path="verhalten" element={<Videos videopath={videopath} videoid="02"/>} />
                    <Route path="strasse-ueberqueren" element={<Videos videopath={videopath} videoid="03"/>} />
                    <Route path="parkende-autos" element={<Videos videopath={videopath} videoid="04"/>} />
                    <Route path="einsatzfahrzeuge" element={<Videos videopath={videopath} videoid="05"/>} />
                    <Route path="ausfahrt" element={<Videos videopath={videopath} videoid="06"/>} />
                    <Route path="gehweginnenseite" element={<Videos videopath={videopath} videoid="07"/>} />
                    <Route path="radfahrer" element={<Videos videopath={videopath} videoid="08"/>} />
                    <Route path="kleidung" element={<Videos videopath={videopath} videoid="09"/>} />
                    <Route path="elterntaxi" element={<Videos videopath={videopath} videoid="10"/>} />
                    <Route path="ohne-elterntaxi" element={<Videos videopath={videopath} videoid="11"/>} />
                    <Route index element={<Themen path={path}/>}></Route>
                  </Route>
                  <Route path="gut-zu-wissen">
                    <Route path="grundlagen" element={<Grundlagen path={path}/>}/>
                    <Route path="versicherungsschutz" element={<Versicherungsschutz path={path}/>}/>                    
                    <Route path="faqs" element={<FAQ path={path}/>}/>
                    <Route index element={<Wissen path={path} />}></Route>
                  </Route>
                  <Route index element={<Eltern path={path} />}></Route>
                </Route>
                <Route path="/spiele">
                  <Route path="richtig-falsch" element={<RichtigFalsch />}/>
                  <Route path="wimmelbild" element={<Wimmelbild/>}/>
                  <Route path="avatar" element={<Avatar/>}/>
                  <Route index element={<Games/>} />
                </Route>
                <Route path="leichte-sprache" element={<LeichteSprache path={path}/>}/>
                <Route path="impressum" element={<Impressum path={path}/>}/>
                <Route path="datenschutz" element={<Datenschutz path={path}/>}/>
                <Route path="barrierefreiheit" element={<Barrierefreiheit path={path}/>}/>
                <Route path="projektpartner" element={<Projektpartner path={path}/>}/>
                <Route path="offlineversion" element={<AskForOfflineMode offlineMode={offlineMode} setOfflineMode={setOfflineMode} toStats={implementStats} />} />
                <Route path="offline" element={<AskForOfflineMode offlineMode={offlineMode} setOfflineMode={setOfflineMode} toStats={implementStats} />} />
                <Route path="/offline-count" element={<Home/>}/>
                <Route path="/404" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
              </Routes>
            </Suspense>
            <NetworkBar online={online} offlineMode={offlineMode} />
        </main>
        {(!gamesActive) && <Footer section={path} />}
    </div>
  );
}

export default App;