import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Daumenhoch from '../../../components/Daumenhoch'

const SchulwegPlanen = (props) => {
  const bild = require('../../../images/' + props.foto);

  return (
    <div>
      <Container>
        <Row>
          <Col lg="9">
            <h1 className='magenta'>{props.titel}</h1>
          </Col>
        </Row>
        <Row>
          <Col lg="9">

            <p className='intro'>
              Der sichere Schulweg steht schon vor dem ersten Schultag auf dem Stundenplan. Und zwar für die Eltern. Die meisten werden den Weg zur Schule ihres Kindes aus ihrem Alltag kennen, doch vielen sind die Gefahrenstellen aus Kindersicht nicht bekannt. Deswegen sollten Erwachsene den Schulweg genauer unter die Lupe nehmen. Dabei gilt: Der kürzeste Weg muss nicht der sicherste sein.
            </p>
          </Col>
        </Row>
        <Row className="reverseorder">
          <Col md="6">
            <p>
              Achten Sie bei der Planung insbesondere auf sichere Überquerungsmöglichkeiten wie Ampel oder Zebrastreifen, auf eine gute Beleuchtung der Straße oder auf einen möglichst durchgehenden Fußweg. Weitere Tipps dazu finden Sie in den <Link to="/eltern/gut-zu-wissen/faqs" >FAQ</Link>.
            </p>

            <p>
              Kinder sollen und können bereits ab der ersten Klasse zu Fuß und allein zur Schule gehen. Voraussetzung ist, dass sie vorher geübt haben, wie sie sich auf ihrem Schulweg sicherheitsbewusst verhalten können. Die Aufgabe der Erwachsenen ist es, Kindern sichere Wege zu zeigen und mit ihnen den Schulweg mehrmals und über längere Zeit zu üben.
            </p>
          </Col>

          <Col md="6">
            <Image fluid src={bild} className="mobileabstandunten" alt=""/>

          </Col>
        </Row>
      </Container>

      <Container fluid className='bg_lightgray'>
        <Container>
          <Row>
            <Col md="2">
              <Daumenhoch />
            </Col>
            <Col md="7">

              <h2 className='blue'>
                So können Eltern den Schulweg planen und üben:
              </h2>
              <ul className="green_circle">
                <li>Planen Sie den Schulweg schon vor Schulstart. Nutzen Sie dazu, wenn möglich Schulwegpläne Ihrer Stadt oder Gemeinde.</li>
                <li>Üben Sie gemeinsam mit Ihrem Kind die ersten Verkehrsregeln, z. B. in einer verkehrsberuhigten Straße oder gleich auf dem späteren Schulweg.</li>
                <li>
                  Wiederholen Sie die Übungen bei jedem Weg und über längere Zeit. Dabei gilt: Je schwieriger der Schulweg, desto öfter sollte dieser gemeinsam geübt werden. 
                </li>
                <li>
                  Begleiten Sie Ihr Kind auf seinem Schulweg so lange wie nötig. Wenn es allein gehen will, unterstützen Sie es dabei. Begleiten Sie es in einem ersten Schritt beispielsweise bis zur ersten Gefahrenstelle und lassen Sie es danach allein gehen. 
                </li>
                <li>Lassen Sie Ihr Kind immer rechtzeitig loslaufen, damit es in Ruhe alle Verkehrssituationen einschätzen kann.</li>
                <li>Vereinbaren Sie mit Ihrem Kind, das es auf dem festgelegten Schulweg bleibt. </li>

              </ul>

            </Col>
          </Row>
        </Container>
      </Container>
    </div>

  )
}

export default SchulwegPlanen