import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styles from './Games.module.scss'
import GamesPreview from './Games/GamesPreview'

const Games = () => {

    return (
        <Container className={styles.gamesWrapper}>
            <Row className='mt-4 my-3'>
                <Col>
                    <div className={styles.flipBoxFront}>
                        <h1>Spielend lernen</h1>
                    </div>
                </Col>
            </Row>
            <GamesPreview />
            <Row className='my-4'>
                <Col className={styles.col}>
                    <div className={styles.textWrap}>
                        <h2 className='my-3'>Den Schulweg spielend lernen</h2>
                        <h3>Mit unseren Spielen können Kinder ihr Wissen testen</h3>
                        <p>Kennt Ihr Kind die wichtigsten Regeln für einen sicheren Schulweg? In unserem Spieleangebot lassen sich die ersten Regeln im Straßenverkehr spielerisch wiederholen und verfestigen. Spielen Sie am besten gemeinsam mit Ihrem Kind unser Richtig-Falsch-Spiel, Suchen Sie im Wimmelbild oder erschaffen Sie einen eigenen Avatar. Durch die praktische Vorlesefunktion können Kinder alle Spiele auch allein spielen. Auf die Spiele, fertig, los!</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
    }

export default Games