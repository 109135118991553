import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { AiOutlineLoading } from 'react-icons/ai';
import { BsFillXCircleFill, BsArrowCounterclockwise, BsEmojiSmileFill, BsCheckLg, BsPencil } from 'react-icons/bs'
import { IoDice } from 'react-icons/io5'
import styles from './Avatar.module.scss'
import { AvatarData } from '../../data/data.js';
import Loading from '../../components/Loading';
import SlideArea from './Avatar/SlideArea';
import SlideAreaY from './Avatar/SlideAreaY';
import html2canvas from 'html2canvas';
import party from "party-js";
import { ReactComponent as AvatarSvg } from '../../images/avatar/avatar.svg';
import InfoOverlay from './InfoOverlay';
import PlayAudio from '../../components/PlayAudio'
import audioIntroSrc from '../../audio/av_intro.mp3'
import audioHelmSrc from '../../audio/av_gutewahl-helm.mp3'
import audioSichtbarSrc from '../../audio/av_gutewahl-sichtbar.mp3'

const Avatar = () => {
    const [currentElement, setCurrentElement] = useState(0)
    const [activeButton, setActiveButton] = useState(-1)
    const [dress, setDress] = useState([0, -1, -1, -1, -1, -1])
    const [dressColor, setDressColor] = useState([0, 0, 0, 0, 0, 0])

    const [successMsg, setSuccessMsg] = useState("")
    const succesMsgHelm = "Mit Helm bist du auf dem Fahrrad oder dem Roller sicher unterwegs."
    const successMsgColors = "Mit auffälliger Kleidung bist du besser sichtbar."

    const [dressGalleryData, setDressGalleryData] = useState([])
    const [dressColorGalleryData, setDressColorGalleryData] = useState([])
    const [galleryImgs, setGalleryImgs] = useState([])
    const [creatingGalleryImg, setCreatingGalleryImg] = useState(false)

    const [slideReset, setSlideReset] = useState(false)

    const [triggerAudio, setTriggerAudio] = useState(false)
    const [audioSrc, setAudioSrc] = useState(audioIntroSrc)

    const avatarRef = useRef(null);
    const overlayH1Ref = useRef(null);

    const initAvatar = () => {
        // set states
        setDress([0, -1, -1, -1, -1, -1])
        // set all dress layers to opacity 0 (except basic body0 color/sw)
        for (let i = 0; i < AvatarData.length; i++) {
            let elem = AvatarData[i];
            for (let j = 0; j < elem.options.length; j++) {
                let option = elem.options[j];
                handleNewDressColor(i, j, 0)
                if(i === 0){
                    if(j === 0){
                        if(document.getElementById(option.id+"_sw") !== null) document.getElementById(option.id+"_sw").style.opacity = 1;
                    }
                    else{
                        if(document.getElementById(option.id+"_sw") !== null) document.getElementById(option.id+"_sw").style.opacity = 0;
                    }
                }
                else{
                    if(document.getElementById(option.id+"_color") !== null) document.getElementById(option.id+"_color").style.opacity = 0;
                    if(document.getElementById(option.id+"_sw") !== null) document.getElementById(option.id+"_sw").style.opacity = 0;
                }
            }
        }
    }

    // init on mount
    useEffect(() => {
        initAvatar()
        setTimeout(() => {
            setTriggerAudio(true)
        }, 1000);
    }, [])

    // set one dress
    const setNewDress = (index, value) => {
        let newDressValue = parseInt(value)
        handleNewDress(index, newDressValue)
        let newDressUp = dress.slice();
        newDressUp[index] = value;
        setDress(newDressUp)

        if(newDressValue !== -1){
            handleNewDressColor(index, newDressValue, 0)
            let newColors = dressColor.slice();
            newColors[index] = 0;
            setDressColor(newColors)
        }
        setCurrentElement(index);
        setActiveButton(value)
        if(index > 0){
            // move active button to the center of scrollrow
            let container = document.getElementById("optionBtn"+index+value)?.parentNode;
            let elementOffset = document.getElementById("optionBtn"+index+value)?.offsetLeft + document.getElementById("optionBtn"+index+value)?.offsetWidth / 2 - container.offsetWidth / 2;
            let scrollX = elementOffset;
            if(elementOffset < 25) scrollX = 0
            if(elementOffset > (container.scrollWidth - container.offsetWidth - 25)) scrollX = container.scrollWidth - container.offsetWidth
            if(container) container.scrollTo(scrollX, 0, 'smooth')
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
            // container.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
        }

        // sparkle effect
        if(avatarRef.current) {            
            party.sparkles(avatarRef.current.parentNode, {
                lifetime: party.variation.range(0.5,1),
                count: party.variation.range(10, 60),
                speed: party.variation.range(50, 300)
            }); 
        }
        animateSuccessMsg(index, value, 0)
    }

    // process dress change in svg
    const handleNewDress = (index, value) => {
        if(avatarRef.current) {
            if(index === 0){
                for (let i = 0; i < AvatarData[index].options.length; i++) {
                    let option = AvatarData[index].options[i];
                    if(document.getElementById(option.id+"_sw") !== null) document.getElementById(option.id+"_sw").style.opacity = 0;
                }
            }
            else{
                for (let i = 0; i < AvatarData[index].options.length; i++) {
                    let option = AvatarData[index].options[i];
                    if(document.getElementById(option.id+"_color") !== null) document.getElementById(option.id+"_color").style.opacity = 0;
                    if(document.getElementById(option.id+"_sw") !== null) document.getElementById(option.id+"_sw").style.opacity = 0;
                }
            }
            if(value !== -1){
                if(document.getElementById(AvatarData[index].options[value].id+"_color") !== null) document.getElementById(AvatarData[index].options[value].id+"_color").style.opacity = 1;
                if(document.getElementById(AvatarData[index].options[value].id+"_sw") !== null) document.getElementById(AvatarData[index].options[value].id+"_sw").style.opacity = 1;
            }
        }
    }

    // set dress color and process change in svg
    const setNewDressColor = (index, dressIndex, color) => {
        handleNewDressColor(index, dressIndex, color)
        let newColors = dressColor.slice();
        newColors[index] = color;
        setDressColor(newColors)
        if(!(index === 2 && dressIndex === 3)){
            animateSuccessMsg(index, dressIndex, color)
        }
    }

    // set dress color and process change in svg
    const handleNewDressColor = (index, dressIndex, color) => {
        if(dressIndex !== -1){
            // setDressColor([newColors[0], newColors[1], newColors[2], newColors[3], newColors[4], newColors[5]])
            let fillColor = AvatarData[index].options[dressIndex].colors[color].css;
            let container = (index === 0) ? document.getElementById(AvatarData[index].options[0].id+"_color") : document.getElementById(AvatarData[index].options[dressIndex].id+"_color")
            if(container !== null){
                container.style.fill = fillColor
                // for all paths in a path group
                if(container.children.length > 0){
                    for (let i = 0; i < container.children.length; i++) {
                        let child = container.children[i];
                        child.style.fill = fillColor
                    }
                }
            }
        }
    }

    // reset dress and dress color
    const resetDress = () => {
        initAvatar()
        setCurrentElement(0)
        setActiveButton(-1)
        setSlideReset(true)
    }

    const randDress = () => {
        let newDressUp = dress.slice();
        let newColors = dressColor.slice();
        for (let i = 0; i < dress.length; i++) {
            let possibleDresses = AvatarData[i].options.length
            let randDress = Math.round((Math.random() * possibleDresses) - 1)
            handleNewDress(i, (randDress === -0 || (randDress === -1 && i === 0)) ? 0 : randDress)
            newDressUp[i] = (randDress === -0 || (randDress === -1 && i === 0)) ? 0 : randDress;
            if(randDress !== -1){
                let possibleDressColors = AvatarData[i].options[randDress].colors.length
                let randColor = Math.round((Math.random() * possibleDressColors)-1)
                handleNewDressColor(i, randDress, (randColor === -1 || randColor === -0) ? 0 : randColor)
                newColors[i] = (randColor === -1 || randColor === -0) ? 0 : randColor;
            }
        }
        setDress(newDressUp)
        setDressColor(newColors)
        // sparkle effect
        if(avatarRef.current) {            
            party.sparkles(avatarRef.current.parentNode, {
                lifetime: party.variation.range(0.5,1),
                count: party.variation.range(10, 60),
                speed: party.variation.range(50, 300)
            }); 
        }
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    const handleAvatarClick = (e) => {
        let id = e.target.parentNode.id
        let container = document.getElementById("slidearea-y")
        if(container !== null){
            for (let i = 0; i < container.children.length; i++) {
                let child = container.children[i].children[0];
                if(id.includes(child.id)){
                    child.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
                }
            }
        }
    }

    const animateSuccessMsg = (index, value, color) => {
        let message = "";
        let msgHelm = false;
        if((index === 3 && value === 1 && color === 0) ||
            (index === 3 && value === 2 && color === 3) ||
            (index === 2 && value === 0 && color === 0) ||
            (index === 2 && value === 3 && color === 2)){
            message = successMsgColors;
        }
        if(index === 2 && value === 3){
            message = succesMsgHelm;
            msgHelm = true;
        }
        // let time = (successMsgColorsShown || successMsgHelmShown) ? 2000 : 4000;
        
        if(message !== ""){
            if(msgHelm){
                setAudioSrc(audioHelmSrc)
                setTimeout(() => {
                    setTriggerAudio(true)
                },50)
            }
            else{
                setAudioSrc(audioSichtbarSrc)
                setTimeout(() => {
                    setTriggerAudio(true)
                },50)
            }
            setTimeout(() => {
                setAudioSrc(audioIntroSrc)
            }, 8000)
            setSuccessMsg(message)
            setTimeout(() => {
                setTimeout(() => {              
                    setSuccessMsg("")
                }, 1000);
            }, 4000);
        }
    }

    const saveDress = (dress, dressColor) => {
        setCreatingGalleryImg(true)
        
        // update saved outfits
        let newDressGalleryData = dressGalleryData.slice()
        let newDressColorGalleryData = dressColorGalleryData.slice()
        newDressGalleryData.unshift(dress)
        newDressColorGalleryData.unshift(dressColor)
        setDressGalleryData(newDressGalleryData)
        setDressColorGalleryData(newDressColorGalleryData)

        setTimeout(() => {
            if(avatarRef.current && !creatingGalleryImg){
                window.scrollTo(0, document.body.scrollHeight, "smooth");
                let container = avatarRef.current.parentNode
                html2canvas(container, {"backgroundColor": null})
                .then(function(canvas) {
                    var myImage = canvas.toDataURL("image/png");
                    setGalleryImgs(i => {
                        let newImgArr = i
                        if(!newImgArr.includes(myImage)){
                            newImgArr.unshift(myImage)
                        }
                        return newImgArr
                    })
                    setCreatingGalleryImg(false)
                })
            }
        }, 500);
    }

    const editAvatar = (index) => {
        // setDress
        let newDressUp = dress.slice();
        let newColors = dressColor.slice();
        for (let i = 0; i < dressGalleryData[index].length; i++) {
            let dress = dressGalleryData[index][i];
            let color = dressColorGalleryData[index][i]
            handleNewDress(i, dress)
            newDressUp[i] = dress;
            handleNewDressColor(i, dress, color)
            newColors[i] = color;
        }
        setDress(newDressUp)
        setDressColor(newColors)
         // sparkle effect
         if(avatarRef.current) {            
            party.sparkles(avatarRef.current.parentNode, {
                lifetime: party.variation.range(0.5,1),
                count: party.variation.range(10, 60),
                speed: party.variation.range(50, 300)
            }); 
        }
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    useEffect(()=> {
        if(dressGalleryData.length > 0) window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, [dressGalleryData.length])
    
    return(
        <Container className={styles.container}>
            <Row className={styles.wrapRow}>
                <Col className={styles.avatarCol} md={6}>
                    <div className={styles.avatarWrap}>
                        <h1>Erstelle dein Lieblings-Outfit</h1>
                            <div>
                                <Suspense fallback={<Loading />}>
                                    <AvatarSvg ref={avatarRef} onClick={handleAvatarClick} id="avatar-svg" />
                                </Suspense>
                            </div>
                    </div>
                </Col>
                <Col className={styles.dressCol} md={6}>
                    <Row className={styles.optionsRow}>
                        <Col>
                            <SlideAreaY reset={slideReset} setReset={setSlideReset}>
                                {AvatarData.map((item, index) => {
                                    return(
                                        <div key={index}>
                                            <SlideArea className={styles.options} id={item.options[0].id.substring(0, item.options[0].id.length-1)} reset={slideReset} setReset={setSlideReset}>
                                                    {(index !== 0) &&
                                                        <Button id={"optionBtn"+index+"-1"} className={(dress[index] === -1) ? styles.optionsBtn+" "+styles.times+" "+styles.chosen : (currentElement === index && -1 === activeButton) ? styles.optionsBtn+" "+styles.times+" "+styles.active : styles.optionsBtn+" "+styles.times} title="Keine Auswahl" variant="light" onClick={()=>setNewDress(index, -1)}>
                                                            <BsFillXCircleFill width={48} height={48} aira-label="X" />
                                                        </Button>
                                                    }
                                                    {item.options.map((subItem, subIndex) => {
                                                        return(
                                                            <Button key={subIndex} id={"optionBtn"+index+subIndex} className={(currentElement === index && dress[currentElement] === subIndex) ? styles.optionsBtn+" "+styles.chosen : (currentElement === index &&subIndex === activeButton) ? styles.optionsBtn+" "+styles.active :  styles.optionsBtn} title={subItem.name} variant="light" onClick={()=>setNewDress(index, subIndex)}>
                                                                <img src={require("../../images/avatar/"+subItem.img)} width={48} height={48} alt={subItem.name} />
                                                            </Button>
                                                        )
                                                    })}
                                            </SlideArea>
                                            {(activeButton !== -1 && index === currentElement) && 
                                            <div className={styles.colors}>
                                                {AvatarData[currentElement].options[activeButton].colors.map((color, colorIndex)=>{
                                                    return(
                                                        <Button key={colorIndex} className={(colorIndex === dressColor[currentElement]) ? styles.colorBtn+" "+styles.active : styles.colorBtn} title={color.title} variant="light" onClick={()=>{setNewDressColor(currentElement, dress[currentElement], colorIndex)}}>
                                                            <span className={styles.color} style={{background: color.css}}></span>
                                                        </Button>
                                                    )
                                                })}
                                            </div>}
                                        </div>
                                    )
                                })}
                                <InfoOverlay active={successMsg !== ""} true={true} false={false} fullSize={false}>
                                    <Row className={styles.msgBox+" "+styles.small}>
                                        <Col className={styles.msgBoxtext}>
                                            <h1 ref={overlayH1Ref}><BsEmojiSmileFill className={(successMsg !== "") ? styles.smiley+" "+styles.rotate : styles.smiley}/>Eine gute Wahl!</h1>
                                            <p>{successMsg}</p>
                                        </Col>
                                    </Row>
                                </InfoOverlay>
                            </SlideAreaY>
                        </Col>
                    </Row>
                </Col>
                <PlayAudio src={audioSrc} active={triggerAudio} setActive={setTriggerAudio} className={styles.audioBtn} />
            </Row>
            <Row className={styles.btnRow}>
                <Button className={styles.randBtn} variant='primary' onClick={randDress} title="Zufällige Auswahl"><span className='sr-only'>Zufälliger Avatar</span> <IoDice /></Button>
                <Button className={styles.resetBtn} variant={(dress.toString() !== [0, -1, -1, -1, -1, -1].toString()) ? 'primary' : 'muted'} onClick={resetDress} title="Zurücksetzen"><span className='sr-only'>Zurücksetzen</span> <BsArrowCounterclockwise /></Button>
                <Button className={styles.checkBtn} variant={(dress.toString() !== [0, -1, -1, -1, -1, -1].toString()) ? 'success' : 'muted'} onClick={()=>saveDress(dress, dressColor)} title="Fertig und speichern"><span className='sr-only'>Fertig und speichern</span> {(creatingGalleryImg) ? <AiOutlineLoading className='spinner-icon'/> : <BsCheckLg />}</Button>
            </Row>
            {(dressGalleryData.length > 0) && <Row className={styles.galleryRow}>
                <Col>
                    <Row>
                        <Col>
                            <h1 className={styles.galleryHeading}>Deine Outfits</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className={styles.avatarGallery}>
                            {galleryImgs.map((item,index)=>{
                                return(
                                    <div key={index} className={styles.avatarGalleryCol}>
                                        {galleryImgs.length > 0 && galleryImgs[index] !== null && 
                                            creatingGalleryImg ? <Loading /> : <img src={galleryImgs[index]} alt="" width="200px" height="333px" className={styles.avatarGalleryImg} onClick={()=>editAvatar(index)} />
                                        }
                                        <BsPencil className={styles.edit} />
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>}
        </Container>
    )
}

export default Avatar